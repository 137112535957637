<!-- <div class="img-overlapped" style="padding-top: 40px;">
    <div class=" logo-wrapper d-none d-sm-block">
        <img class="img-fluid logo mx-auto" src="../../assets/site/blog/blog-logo.png" alt="">
        <p class="texto-logo text-secondary">Novedades para tus finanzas personales</p>
    </div>
    <div class="logo-wrapper-mobile mx-auto d-block d-sm-none">
        <img class="img-fluid logo mx-auto" src="../../assets/site/blog/blog-logo.png" alt="">
    </div>
</div> -->
<div class="px-0 mx-auto container">
    <app-header [items]='headerItems' [iconUrl]="'../assets/home/icono_blog.png'">
    </app-header>
</div>
<div class="px-0 mx-auto container" style="padding-top: 40px;">
    <div class="mx-auto img-container">
        <div class="img-overlapped w-100">
            <!-- WEB -->
            <div class=" logo-wrapper d-none d-sm-block mb-4">
                <img class="img-fluid logo mx-auto" src="../../assets/site/blog/blog-logo.png" alt="">
            </div>
            <h4 class="texto-logo text-secondary d-none d-sm-block d-md-none">Novedades para tus finanzas personales</h4>
            <h3 class="texto-logo text-secondary d-none d-md-block">Novedades para tus finanzas personales</h3>
            <!-- MOBILE -->
            <div class="logo-wrapper-mobile h-100 d-flex d-sm-none ml-4">
                <img class="img-fluid logo mx-auto mt-auto" src="../../assets/site/blog/blog-logo.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="row" id="row_content">
    <section class="mx-auto p-sm-4 mb-0" id="section_content" *ngIf='data.length>0'>
        <div></div>
        <div class="row-items row mx-auto" [ngClass]="{'one-column': data.length == 1, 'two-column': data.length == 2}">
            <div class="d-flex flex-column col-md-4 my-2 my-sm-4 px-0" *ngFor='let entradaBlog of data'>
                <div class="col-12">
                    <!-- ITEM ANTIGUO -->
                    <!-- <div class="item m-0 p-0">
                        <a routerLink="/blog/{{entradaBlog.c_id_public}}" class="mt-2 portada-wrapper">
                            <img class="img-fluid mx-auto portada" src="{{entradaBlog.c_portada}}" alt="">
                        </a>
                        <div class="card-block text-center">
                            <h4 class="card-category text-truncate">{{entradaBlog.c_categoria}}</h4>
                            <h2>
                                <a class="card-title line-clamp-3" routerLink="/blog/{{entradaBlog.c_id_public}}">{{entradaBlog.c_titulo}}
                                </a>
                            </h2>
                            <div class="footer-card">
                                <div class="row m-0 p-0">
                                    <div class="col-3">
                                        <div class='author-thumb'>
                                            <img src="{{entradaBlog.c_autor.c_foto}}">
                                        </div>
                                    </div>
                                    <div class="col-9 d-flex">
                                        <div class="author-content my-auto">
                                            <span class="post-name mr-1">{{entradaBlog.c_autor.c_nombre}}</span>
                                            <span class="post-date mr-1">/ {{entradaBlog.c_datetime_creacion | date: 'd \'de\' MMM \'de\'
                                                yyyy'}}</span>
                                        </div>
                                    </div>
                                </div>
                                

                                
                            </div>
                        </div>
                    </div> -->
                    <!-- ITEM NUEVO -->
                    <div class="item m-0 p-0">
                        
                        <div class="card-block text-center">
                            <h4 class="card-category text-truncate">{{entradaBlog.c_categoria}}</h4>
                            <h2>
                                <a class="card-title line-clamp-3" routerLink="/blog/{{entradaBlog.c_id_public}}">{{entradaBlog.c_titulo}}
                                </a>
                            </h2>
                            <a routerLink="/blog/{{entradaBlog.c_id_public}}" class="mt-2 portada-wrapper">
                                <img class="img-fluid mx-auto portada mb-4" src="{{entradaBlog.c_portada}}" alt="">
                            </a>
                            <div class="footer-card">
                                <div class="row m-0 p-0">
                                    <div class="col-4">
                                        <div class='author-thumb'>
                                            <img src="{{entradaBlog.c_autor.c_foto}}">
                                        </div>
                                    </div>
                                    <div class="col-8 d-flex">
                                        <div class="author-content my-auto">
                                            <span class="post-name mr-1">{{entradaBlog.c_autor.c_nombre}}</span>
                                            <span class="post-date mr-1">/ {{entradaBlog.c_datetime_publicacion | date: 'd \'de\' MMM \'de\'
                                                yyyy'}}</span>
                                        </div>
                                    </div>
                                </div>
                                

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-container mt-2 mb-4" *ngIf='!paginacion.siguiente?.disabled'>
            <a size="m" class='d-block mx-auto text-primary text-center link-primary' (click)='loadNextPage()' style="font-size: 20px; font-weight: 600;">
                Cargar más<br>
                <img class='ml-2 icono-triangulo text-secondary' src='./../assets/home/icono_triangulo.png'>
            </a>
        </div>
    </section>
</div>
<ng-container *ngIf="data.length == 0 && !cargando">
    <div class="p-sm-5 p-2 empty-list">
        <p class='mx-auto mt-5 text-center empty-list-p'>Aún no hay contenido disponible. Por favor, intentalo más tarde
        </p>
    </div>
    <div></div>
</ng-container>
<app-footer></app-footer>