<div class="layout-local">
    
    <div class="container row flex-column py-4 px-0 px-sm-2 ">
        <div class="col-12 col-sm-10 mx-auto">
            <div class="m-0 p-0">
                <div class="col-12 mx-auto d-flex flex-column">
                    <div class="col-10 col-sm-10 p-2 titulo m-0 ml-4 d-flex">
                        <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate">
                            Subir Documentación
                        </span>
                        <!-- <img class="ml-auto mr-2 img-fluid" src="../../assets/icono_pago_movil.png" alt=""> -->
                        <!-- <i class="fas fa-hand-holding-usd"></i> -->
                    </div>
                </div>



                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="msgError!=null">
                    <div class="row align-items-center m-0 ">
                        <div class="col-12 text-center mb-1">
                            <h4 class="text-center mx-auto mt-3 mb-4">{{msgError}}
                            </h4>
                            <button type="button" class=" my-auto" mdbTooltip="Actualizar" mdbBtn color="secondary" size="sm" outline="true" mdbWavesEffect (click)="actualizar()">
                                <i class="fas fa-redo mr-2"></i> Actualizar
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="idSolicitud!=null">
                    <div class="row align-items-center m-0 ">
                        <div class="col-12">
                            <h4 class="text-center mx-auto mt-3 mb-4">Documentos Requeridos
                                <!-- <button type="button" class="float-right my-auto" mdbTooltip="Actualizar" mdbBtn color="secondary" size="sm" outline="true" mdbWavesEffect (click)="actualizar()">
                                    <i class="fas fa-redo"></i>
                                </button> -->
                            </h4>
                        </div>
                
                        <div class="col-lg-12">
                            <mdb-accordion [multiple]="true">
                                <mdb-accordion-item [collapsed]="false" *ngFor="let doc of documentacion">
                                    <mdb-accordion-item-head [customClass]="'bg-color-secondary lighten-3 white-text'">
                                        {{itemsDocumentacion[doc].label}} - {{(jsonDocumentosSubidos[doc]!=null)?
                                        jsonDocumentosSubidos[doc].length : '0'}} Documento/s subido/s</mdb-accordion-item-head>
                                    <mdb-accordion-item-body [customClass]="">
                                        <span>
                
                                        </span>
                                        <div class="row my-1"
                                            *ngIf="jsonDocumentosSubidos[doc]==null || jsonDocumentosSubidos[doc].length==0">
                                            <div class="col-12">
                                                <h6 class="text-left"><b>No se ha adjuntado ningún documento</b></h6>
                                            </div>
                                        </div>
                                        <div class="row my-1"
                                            *ngIf="jsonDocumentosSubidos[doc]!=null && jsonDocumentosSubidos[doc].length>0">
                                            <div class="col-12 col-sm-4 text-center"
                                                *ngFor="let archivo of jsonDocumentosSubidos[doc]; index as i">
                                                <img class='mx-auto img-archivo' src='{{archivo.c_url}}'
                                                    (click)='showModalPreview(archivo.c_url)'>
                                                <h5 (click)="eliminarArchivo(doc,i)" *ngIf="archivo.c_id_estado==1">
                                                    <mdb-badge class="badge-eliminar" color="red" pill="true" mdbWavesEffect><i class="fas fa-trash mr-1"></i> Eliminar
                                                    </mdb-badge>
                                                </h5>
                                                <h5 *ngIf="archivo.c_id_estado==2">
                                                    <mdb-badge class="badge-eliminar" mdbWavesEffect mdb color="green" pill="true"><i class="fas fa-check-circle mr-1"></i> Aprobado
                                                    </mdb-badge>
                                                </h5>
                                                <h5 *ngIf="archivo.c_id_estado==3">
                                                    <mdb-badge class="badge-eliminar" mdbWavesEffect mdb color="red" pill="true"><i class="fas fa-exclamation-triangle mr-1"></i> Rechazado
                                                    </mdb-badge>
                                                    <mdb-badge class="badge-eliminar" mdbWavesEffect mdb color="yellow" pill="true" mdbTooltip="Comentario del operador: {{archivo.c_motivo_rechazo}}"><i class="fas fa-info text-dark"></i>
                                                    </mdb-badge>
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="row my-1">
                                            <div class="col-12 text-center">
                                                <input type="file" name="" id="input_{{doc}}" class="d-none"
                                                    (change)="onFileSelected($event, doc)">
                                                <button type="button" mdbBtn color="secondary" mdbWavesEffect (click)="clickInput(doc)">
                                                    <i class="fas fa-upload mr-1"></i> Subir Archivo
                                                </button>
                                            </div>
                                        </div>
                
                
                                    </mdb-accordion-item-body>
                                </mdb-accordion-item>
                
                            </mdb-accordion>
                        </div>

                        <div class="col-12">
                            <h4 class="text-center mx-auto mt-3 mb-4">Para finalizar, regrese al sistema BackOffice
                            </h4>
                        </div>
                
                    </div>
                </div>


            </div>
        </div>

    </div>
    
</div>

<!-- Modal Vista previa img -->
<div mdbModal #modalPreview="mdbModal" id="modalPreview" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mymodalFotoLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg2" role="document" style="text-align:center;">
        <div class="modal-content rounded" style="background-color: transparent;display:inline-block;width: auto;">
            <img src="{{this.imgPreview}}" class="d-flex rounded mx-auto img-fluid z-depth-1">
            <button type="button" class="m-3 close pull-right" aria-label="Close" (click)="modalPreview.hide()">
                <div class="text-white  p-1 mt-1" aria-hidden="true"
                    style="background-color: #7e106a !important; border-radius: 2px;">X</div>
            </button>
        </div>
    </div>
</div>