import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { PrestamosConsultasService } from '../../services/prestamos-consultas.service';

@Component({
    selector: 'app-prestamo-detalle',
    templateUrl: './prestamo-detalle.component.html',
    styleUrls: ['./prestamo-detalle.component.scss']
})
export class PrestamoDetalleComponent implements OnInit {

    @Input() prestamo: any;
    @Input() i: any;
    hideCards: any = {}

    constructor(public ds: GlobalService, private pc: PrestamosConsultasService) { }

    ngOnInit(): void {
    }


    showHideCard(i) {
        if (this.hideCards[i] == true) this.hideCards[i] = false
        else this.hideCards[i] = true
    }

    abrirModalPagoParcial() {

        if (this.prestamo.pagoParcial == null || this.prestamo.pagoParcial == 0) {
            return this.ds.generateErrorAlert("Ingresá el monto que querés pagar")
        }
        else if (isNaN(parseFloat(this.prestamo.pagoParcial)) || parseFloat(this.prestamo.pagoParcial)<0 || parseFloat(this.prestamo.pagoParcial) != parseFloat(this.prestamo.pagoParcial.toFixed(2))) {
            return this.ds.generateErrorAlert("El monto ingresado no es válido")
        }


        let cuota: any = {}
        for (let i = 0; i < this.prestamo.cuotas.length; i++) {
            if (this.prestamo.cuotas[i].numeroCuota == this.prestamo.numeroCuotaVencimiento) {
                cuota = this.prestamo.cuotas[i]; break;
            }
        }

        let importePagar = parseFloat(this.prestamo.pagoParcial)
        if (cuota.importeRestante < importePagar) {
            return this.ds.generateErrorAlert(`El monto no puede superar el valor de la cuota ($${cuota.importeRestante.toFixed(2)})`)
        }

        let detalleModalCuota = {
            title: `Pago parcial de cuota n° ${this.prestamo.numeroCuotaVencimiento} del crédito ${this.prestamo.numeroPrestamo}`,
            hab1: (this.prestamo.medios[1] != null) ? true : false,
            hab2: (this.prestamo.medios[2] != null) ? true : false,
            hab3: (this.prestamo.medios[3] != null) ? true : false,
            importe1: (this.prestamo.medios[1] != null) ? this.calcularImportePagoParcial(importePagar,this.prestamo.medios[1].recargo) : null,
            importe2: (this.prestamo.medios[2] != null) ? this.calcularImportePagoParcial(importePagar,this.prestamo.medios[2].recargo) : null,
            importe3: (this.prestamo.medios[3] != null) ? this.calcularImportePagoParcial(importePagar,this.prestamo.medios[3].recargo) : null,
            recargo1: (this.prestamo.medios[1] != null) ? this.prestamo.medios[1].recargo : null,
            recargo2: (this.prestamo.medios[2] != null) ? this.prestamo.medios[2].recargo : null,
            recargo3: (this.prestamo.medios[3] != null) ? this.prestamo.medios[3].recargo : null,
            medio: null,
            recargo: null,
            importe: null,
            prestamo: this.prestamo,
            parcial: true,
            importeIngresado: importePagar
        }

        this.pc.abrirModal(detalleModalCuota);
    }

    calcularImportePagoParcial(importe,recargo)
    {
        return parseFloat(((100*importe)/(100-recargo)).toFixed(2))
    }
}
