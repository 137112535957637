import { Component, Input, OnInit } from '@angular/core';
import { items } from './items';

@Component({
  selector: 'app-functionalities',
  templateUrl: './functionalities.component.html',
  styleUrls: ['./functionalities.component.scss']
})
export class FunctionalitiesComponent implements OnInit {

  items:Object[] = items

  @Input() btns: object = {
    1:{
      id: 1,
      active: "false",
    },
    2:{
      id: 2,
      active: "false",
    },
    3:{
      id: 3,
      active: "false",
    }
  }
 
  constructor() {}

  ngOnInit(): void {
    this.changeStatus({id: '1', active:true})
    this.clickButton({id: '1', active:true})
  }

  onMensajeHijo(data:any) {
   this.changeStatus(data)
   this.clickButton(data)
   }

   changeStatus(data:{id:string, active:Boolean}){
      this.btns[data.id].active = data.active
   }

   clickButton(data:{id:string, active:Boolean}){
      if (data.active) {
        for (let i = 1; i < Object.values(this.btns).length + 1; i++) {   
          this.btns[i].active = false
          document.getElementById(this.btns[i].id).classList.remove("active")
        }
        this.btns[data.id].active = data.active
        document.getElementById(data.id).classList.toggle("active")
      }
   }

   clearActiveButtons(){
     for (let i = 1; i < Object.values(this.btns).length + 1; i++) {   
       this.btns[i].active = false
      }
   }

}
