import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public router: Router, public ds: GlobalService, private location: Location) {
  }

  ngOnInit() {
    
    // CARGAR SCRIPT DE CHAT
    if(this.ds.build=='prod') {

      const angularRoute = this.location.path();
      const url = window.location.href;
      let domainAndApp = url.replace(angularRoute, '');
      domainAndApp = domainAndApp.replace('https://', '');
      domainAndApp = domainAndApp.replace('http://', '');
      domainAndApp = domainAndApp.replace('/', '');

      let dominioBestcard = 'bestcard.com.ar';
      let dominioBestcardConWWW = 'www.bestcard.com.ar';

      let rutaRegistro = 'reg' 
      let path = []
      
      try {
        path = window.location.pathname.split('/')
      } catch (error) {
      }

      if(!path.includes(rutaRegistro) && (domainAndApp == dominioBestcard || domainAndApp == dominioBestcardConWWW)) {
        
        let js = document.createElement('script');
        js.innerHTML = '';
        js.type = 'text/javascript';
        js.src = 'https://go.botmaker.com/rest/webchat/p/6U3J48Z4N3/init.js';
        js.async = true;
        js.defer = true;
        document.body.appendChild(js);

        // let whatsappEnlace = document.createElement('a')
        // let whatsapp = document.createElement('img')
        // whatsappEnlace.href = '/consulta'
        // whatsappEnlace.href  = `https://api.whatsapp.com/send/?phone=+5492645800950`
        // whatsappEnlace.target = "_blank"
        // whatsapp.className = "whatsapp"
        let whatsappEnlace = document.createElement('a')
        let whatsapp = document.createElement('img')
        // whatsappEnlace.href = 'https://wa.me/5492645800950'
        whatsappEnlace.href  = `https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quería%20hacerles%20la%20siguiente%20consulta:%20`
        whatsappEnlace.target = '_blank'
        whatsappEnlace.className = "whatsapp"
        // whatsapp.title = "whatsapp"
        // whatsapp.src = "/assets/home/iconos/icon-whatsapp.png"
        // whatsappEnlace.appendChild(whatsapp)
        // document.body.appendChild(whatsappEnlace);
        
      }
    }

    this.disableConsole()
  }

  disableConsole()
  {
    // DESHABILITAMOS LOS CONSOLE LOGS EN PRODUCCIÓN
    if(this.ds.build=='prod') console.log = function(){};
  }

}
