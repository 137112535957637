<header class="h-100">
  <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark navbar-blog scrolling-navbar fixed-top intro-fixed-nav py-2"
    [containerInside]="true">
    <mdb-navbar-brand>
      <a class="logo navbar-brand" routerLink="/blog">
        <img src="{{iconUrl}}" alt="Logo de BestCard" height="44" />
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav ml-auto">
        <ng-container class='' *ngFor='let item of items'>
          <li class="d-flex align-items-center nav-item waves-light mx-2" mdbWavesEffect *ngIf="item.title == 'Obtener Bestcard' && isHome()">
            <a class="nav-link" mdbPageScroll href="#obtener">Obtener BestCard</a>
          </li>
          <li class="nav-item waves-light mx-2" mdbWavesEffect *ngIf="item.title == 'Obtener Bestcard' && !isHome()">
            <a class="nav-link" mdbPageScroll (click)="goMenu('obtener');">Obtener BestCard</a>
          </li>
          <li class="nav-item waves-light mx-2" mdbWavesEffect
            *ngIf="item.title != 'Obtener Bestcard' && item.location[0] == '#'">
            <a class="nav-link" mdbPageScroll href="{{item.location}}">{{item.title}}</a>
          </li>
          <li class="d-flex align-items-center nav-item waves-light mx-2" mdbWavesEffect
            *ngIf="item.title != 'Obtener Bestcard' && item.location[0] == '/'">
            <a class="nav-link" mdbPageScroll routerLink="{{item.location}}">{{item.title}}</a>
          </li>
        </ng-container>

        <li class="nav-item waves-light mx-2 d-flex align-items-center" mdbWavesEffect>
          <a target="_blank" class="nav-link" href='/consulta'>
            <mdb-icon fab icon="whatsapp" size="lg" class="white-text"></mdb-icon>
          </a>
          <a class="nav-link" target="_blank"
            href="https://facebook.com/Best-Card-397560351089246/?business_id=1874250536156711" mdbWavesEffect>
            <mdb-icon fab icon="facebook" size="lg" class="white-text"></mdb-icon>
          </a>
          <a class="nav-link" target="_blank"
            href="https://www.instagram.com/bestcard.arg" mdbWavesEffect>
            <mdb-icon fab icon="instagram" size="lg" class="white-text"></mdb-icon>
          </a>
          <a class="nav-link" target="_blank"
            href="https://www.twitter.com/bestcard_arg" mdbWavesEffect>
            <mdb-icon fab icon="twitter" size="lg" class="white-text"></mdb-icon>
          </a>
        </li>
      </ul>
    </links>
  </mdb-navbar>
</header>
