import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';

import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-solicitudprestamo',
  templateUrl: './solicitudprestamo.component.html',
  styleUrls: ['./solicitudprestamo.component.scss']
})
export class SolicitudprestamoComponent implements OnInit {
  formPrestamo: any;
  ref: any;
  recaptcha_KEY = environment.recaptcha_KEY;
  
  constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.ref = params.hash || null;

      ds.loader = true;
        ds.setReferidoPrestamo(this.ref).then(res => {
          ds.loader = false;
          // this.ds.loader = false;
          let resInfo: any = res;
          console.log(resInfo);
          if(resInfo.r) {
            if (resInfo.r.c == 0) {
              // this.router.navigateByUrl('/reg/paso2');
              // this.ds.emailRegistroP = this.formPaso1.value.email;
              // this.ds.storage.set('email', this.formPaso1.value.email);
              // this.ds.storage.set('paso', 2);
              // this.ds.storage.set('tipo', 1);
              this.jsonImportes = res['json']

            } else {
              this.ds.generateErrorAlert(resInfo.r.m);
            }
          } else {
            
            this.ds.generateErrorAlert("Ocurrió un error inesperado");
          }
    
        });

    });


   }

  ngOnInit(): void {
    this.formPrestamo = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      nombre: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      dni: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{7,9}$/) ]),
      numCel: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{5,15}$/) ]),
      poseeIngresos: new FormControl(null),
      fotoIngresos: new FormControl(null),
      fotoIngresosSource: new FormControl(null, [],[this.validateFoto.bind(this)]),
      monto: new FormControl(null, [Validators.required],[this.validateMonto.bind(this)]),
      cantCuotas: new FormControl(null, [Validators.required]),
      terminos: new FormControl(null, [Validators.required],[this.validateTerminos.bind(this)]),
      recaptchaReactive:  new  FormControl ( null ,  Validators.required ),
    }, { updateOn: 'change' }); 
  }

  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formPrestamo.patchValue({
        fotoIngresosSource: file
      });
    }
  }

  get form_nombre() { return this.formPrestamo.get('nombre'); }
  get form_email() { return this.formPrestamo.get('email'); }
  get form_monto() { return this.formPrestamo.get('monto'); }
  get form_dni() { return this.formPrestamo.get('dni'); }
  get form_numCel() { return this.formPrestamo.get('numCel'); }
  get form_poseeIngresos() { return this.formPrestamo.get('poseeIngresos'); }
  get form_fotoIngresos() { return this.formPrestamo.get('fotoIngresos'); }
  get form_fotoIngresosSource() { return this.formPrestamo.get('fotoIngresosSource'); }
  get form_cantCuotas() { return this.formPrestamo.get('cantCuotas'); }
  get form_terminos() { return this.formPrestamo.get('terminos'); }

  verifyForm() {

    // if(this.formPrestamo.)


    if(this.formPrestamo.status != "VALID")
    {
      this.ds.generateErrorAlert('Debe completar todos los campos del formulario')
    }
    else
    {

      this.ds.loader = true;
      this.ds.solicitarPrestamo(this.formPrestamo.value,this.ref).then(res => {
        this.ds.loader = false;
        // console.log('res');
        // console.log(res);

        if(res['r'] && res['r'].c==0)
        {
          this.router.navigateByUrl('/');
          this.ds.generateSuccessAlert("Tu solicitud de préstamos se verificará entre las próximas 72 horas y te notificaremos al respecto");
        }
        else if(res['r'] && res['r'].c==1)
        {
          this.ds.generateErrorAlert(res['r'].m)
           //reset recaptcha
          this.formPrestamo.get('recaptchaReactive').setValue('');
        }
        else
        {
          this.ds.generateErrorAlert('Ocurrió un error inesperado. Por favor, inténtelo nuevamente')
          //reset recaptcha
          this.formPrestamo.get('recaptchaReactive').setValue('');
        }
      })


    }


  }


  puede12: any = false;
  puede6 : any = true;
  
  @ViewChild('inputMonto') inputMonto: any;
  checkCheckBoxvalue(ele){
    // console.log(ele.checked)

    
    if(ele.checked)
    { 
      this.puede12 = true
      this.puede6 = false
      
      if(this.form_cantCuotas.value>6)
      {
        this.formPrestamo.get('cantCuotas').setValue("");
      }
      this.formPrestamo.get('monto').setValue(this.form_monto.value);
      this.calcularCostoTotal()
      this.formPrestamo.controls['fotoIngresosSource'].updateValueAndValidity();
      
    }
    else
    {
      this.puede12 = false
      this.puede6 = true

      if(this.form_cantCuotas.value>6)
      {
        this.formPrestamo.get('cantCuotas').setValue("");
      }

      this.formPrestamo.get('monto').setValue(this.form_monto.value);
      this.calcularCostoTotal()
      this.formPrestamo.controls['fotoIngresosSource'].updateValueAndValidity();
    }
  }

  
  opcionesCuotas6: any = [
    // {label: "Seleccione la cantidad de cuotas", value: ""},
    {label: 1, value: 1},
    {label: 2, value: 2},
    {label: 3, value: 3},
    {label: 4, value: 4},
    {label: 5, value: 5},
    {label: 6, value: 6},
  ]

  opcionesCuotas = [];
  
  opcionesCuotas12 = [
    // {label: "Seleccione la cantidad de cuotas", value: ""},
    {label: 1, value: 1},
    {label: 2, value: 2},
    {label: 3, value: 3},
    {label: 4, value: 4},
    {label: 5, value: 5},
    {label: 6, value: 6},
    {label: 7, value: 7},
    {label: 8, value: 8},
    {label: 9, value: 9},
    {label: 10, value: 10},
    {label: 11, value: 11},
    {label: 12, value: 12},
  ]

  montoTotal: any = 0;
  totalCuota: any = 0;

  calcularCosto()
  {
    let monto = this.form_monto.value;

    if(this.puede6)
    {
      let arreCuotas = [
        // {value: 1, label: "1 Cuota ( $" + this.calcularCostoCuotas(monto,1) + ")"},
        {value: 2, label: "2 Cuotas ( $" + this.calcularCostoCuotas(monto,2) + " c/u)"},
        {value: 3, label: "3 Cuotas ( $" + this.calcularCostoCuotas(monto,3) + " c/u)"},
        {value: 4, label: "4 Cuotas ( $" + this.calcularCostoCuotas(monto,4) + " c/u)"},
        {value: 5, label: "5 Cuotas ( $" + this.calcularCostoCuotas(monto,5) + " c/u)"},
        {value: 6, label: "6 Cuotas ( $" + this.calcularCostoCuotas(monto,6) + " c/u)"},
      ]

      this.opcionesCuotas = arreCuotas;
      this.calcularCostoTotal()
    }
    else if(this.puede12)
    {
      let arreCuotas = [
        // {value: 1, label: "1 Cuota ( $" + this.calcularCostoCuotas(monto,1) + ")"},
        {value: 2, label: "2 Cuotas ( $" + this.calcularCostoCuotas(monto,2) + " c/u)"},
        {value: 3, label: "3 Cuotas ( $" + this.calcularCostoCuotas(monto,3) + " c/u)"},
        {value: 4, label: "4 Cuotas ( $" + this.calcularCostoCuotas(monto,4) + " c/u)"},
        {value: 5, label: "5 Cuotas ( $" + this.calcularCostoCuotas(monto,5) + " c/u)"},
        {value: 6, label: "6 Cuotas ( $" + this.calcularCostoCuotas(monto,6) + " c/u)"},
        {value: 7, label: "7 Cuotas ( $" + this.calcularCostoCuotas(monto,7) + " c/u)"},
        {value: 8, label: "8 Cuotas ( $" + this.calcularCostoCuotas(monto,8) + " c/u)"},
        {value: 9, label: "9 Cuotas ( $" + this.calcularCostoCuotas(monto,9) + " c/u)"},
        {value: 10, label: "10 Cuotas ( $" + this.calcularCostoCuotas(monto,10) + " c/u)"},
        {value: 11, label: "11 Cuotas ( $" + this.calcularCostoCuotas(monto,11) + " c/u)"},
        {value: 12, label: "12 Cuotas ( $" + this.calcularCostoCuotas(monto,12) + " c/u)"},
      ]

      this.opcionesCuotas = arreCuotas;
      this.calcularCostoTotal()
    }
    else
    {

    }

  }

  jsonImportes: any = {
      // impuestoMinimoSellos: 168, // $168
      // adicionalEnCuota: 5, // $5
  
      // porcGasto: 330, // 3.30%
      // porcSeguro: 50, // 0.50%
      // porcIva: 2100, // 21.00%
      // porcSellos: 84,  // 0.84%
      // porcTna: 10000,  // 100.00%
  }

  calcularCostoCuotas(monto,cuotas)
  {
    
    

    // let monto = this.form_monto.value;
    // let cuotas = this.form_cantCuotas.value;
    
    if(!monto || monto<=0 || !cuotas || cuotas<=0)
    {

      return 0;
      // this.totalCuota = 0;
      // this.montoTotal = 0;
    }
    else
    {

      let gasto = ((monto)*(this.jsonImportes.porcGasto)/100)/100;
      let seguro = ((monto)*(this.jsonImportes.porcSeguro)/100)/100;
      let otros = (cuotas)*(this.jsonImportes.adicionalEnCuota);
  
      let auxGtos1 = (this.sumarNumDecimales(gasto,seguro));
      let auxGtos2 = (this.sumarNumDecimales(auxGtos1,otros));
      let ivaGtos = ((auxGtos2)*(this.jsonImportes.porcIva)/100)/100;
  
      let sellos = ((monto)*(this.jsonImportes.porcSellos)/100)/100;
      if(sellos<this.jsonImportes.impuestoMinimoSellos)sellos = this.jsonImportes.impuestoMinimoSellos

      let auxTna1 = (this.sumarNumDecimales(monto,gasto));
      let auxTna2 = (this.sumarNumDecimales(auxTna1,seguro));
      let auxTna3 = (this.sumarNumDecimales(auxTna2,sellos));
      let auxTna4 = (auxTna3*(this.jsonImportes.porcTna)/100)/100;
      let auxTna5 = (auxTna4)*(cuotas*30);
      let auxTna6 = (auxTna5)/(360);
      let tna = auxTna6;
  
      let ivaInt = ((auxTna6)*(this.jsonImportes.porcIva)/100)/100;
  
      let auxMontoTotal1 = (this.sumarNumDecimales(monto,gasto));
      let auxMontoTotal2 = (this.sumarNumDecimales(auxMontoTotal1,seguro));
      let auxMontoTotal3 = (this.sumarNumDecimales(auxMontoTotal2,otros));
      let auxMontoTotal4 = (this.sumarNumDecimales(auxMontoTotal3,ivaGtos));
      let auxMontoTotal5 = (this.sumarNumDecimales(auxMontoTotal4,sellos));
      let auxMontoTotal6 = (this.sumarNumDecimales(auxMontoTotal5,tna));
      let auxMontoTotal7 = (this.sumarNumDecimales(auxMontoTotal6,ivaInt));
  
      let montoTotal = auxMontoTotal7;
      let totalCuota = montoTotal/cuotas;

      return totalCuota.toFixed(2)
    }

  }


  calcularCostoTotal()
  {
    
    let monto = this.form_monto.value;
    let cuotas = this.form_cantCuotas.value;
    
    if(!monto || monto<=0 || !cuotas || cuotas<=0)
    {
      this.totalCuota = 0;
      this.montoTotal = 0;
    }
    else
    {
      this.totalCuota = 0;
      this.montoTotal = 0;

      let gasto = ((monto)*(this.jsonImportes.porcGasto)/100)/100;
      let seguro = ((monto)*(this.jsonImportes.porcSeguro)/100)/100;
      let otros = (cuotas)*(this.jsonImportes.adicionalEnCuota);
  
      let auxGtos1 = (this.sumarNumDecimales(gasto,seguro));
      let auxGtos2 = (this.sumarNumDecimales(auxGtos1,otros));
      let ivaGtos = ((auxGtos2)*(this.jsonImportes.porcIva)/100)/100;
  
      let sellos = ((monto)*(this.jsonImportes.porcSellos)/100)/100;
      if(sellos<this.jsonImportes.impuestoMinimoSellos)sellos = this.jsonImportes.impuestoMinimoSellos

      let auxTna1 = (this.sumarNumDecimales(monto,gasto));
      let auxTna2 = (this.sumarNumDecimales(auxTna1,seguro));
      let auxTna3 = (this.sumarNumDecimales(auxTna2,sellos));
      let auxTna4 = (auxTna3*(this.jsonImportes.porcTna)/100)/100;
      let auxTna5 = (auxTna4)*(cuotas*30);
      let auxTna6 = (auxTna5)/(360);
      let tna = auxTna6;
  
      let ivaInt = ((auxTna6)*(this.jsonImportes.porcIva)/100)/100;
  
      let auxMontoTotal1 = (this.sumarNumDecimales(monto,gasto));
      let auxMontoTotal2 = (this.sumarNumDecimales(auxMontoTotal1,seguro));
      let auxMontoTotal3 = (this.sumarNumDecimales(auxMontoTotal2,otros));
      let auxMontoTotal4 = (this.sumarNumDecimales(auxMontoTotal3,ivaGtos));
      let auxMontoTotal5 = (this.sumarNumDecimales(auxMontoTotal4,sellos));
      let auxMontoTotal6 = (this.sumarNumDecimales(auxMontoTotal5,tna));
      let auxMontoTotal7 = (this.sumarNumDecimales(auxMontoTotal6,ivaInt));
  
      this.montoTotal = auxMontoTotal7;
      let auxTotalCuota = this.montoTotal/cuotas
      this.totalCuota = auxTotalCuota.toFixed(2);
    }

  }

  sumarNumDecimales(num_a, num_b) {
    num_a = parseFloat(num_a);
    num_b = parseFloat(num_b);
    if ( (num_a || num_a == 0 ) && (num_b || num_b == 0 )  ) {
        //console.log('no esta vacio');
        // if (this.IsNumerDecimMaxDos(num_a) && this.IsNumerDecimMaxDos(num_b)) {
            //console.log('es un numero valido');
            
            //hasta 0.001
            let multiplo = 1000;
            return (num_a * multiplo + num_b * multiplo) / multiplo;
        // }
    }
}

IsNumerDecimMaxDos(number){
  var RE = /^\d*\.?\d{0,2}$/g;
  return (RE.test(number));
}

validateMonto(control: AbstractControl) {

  return new Promise((resolve,reject) => {
    resolve(true);
  }).then(() => {

    let monto = control.value

    // console.log('control');
    // console.log(control);

    // console.log('monto');
    // console.log(monto);

    if(monto<1000) { return { 'montoMenor0': true }; }
    else if(this.puede12== true && monto>20000) { return { 'montoMayor20000': true }; }
    else if(this.puede6== true && monto>10000) { return { 'montoMayor10000': true }; }
    else
    {
      return null;

    }


  })
}

validateTerminos(control: AbstractControl) {

  return new Promise((resolve,reject) => {
    resolve(true);
  }).then(() => {

    let estado = control.value

    if(estado!=true) { return { 'errorAceptar': true }; }
    else
    {
      return null;

    }


  })
}

validateFoto(control: AbstractControl) {

  return new Promise((resolve,reject) => {
    resolve(true);
  }).then(() => {

    // console.log('this.puede12');
    // console.log(this.puede12);
    // console.log('control');
    // console.log(control);

    // console.log(this.formPrestamo.get('fotoIngresosSource').value=='');

    if(this.puede12==true)
    {
      if(this.formPrestamo.get('fotoIngresosSource').value===null || this.formPrestamo.get('fotoIngresosSource').value==="")
      { console.log('error true'); return { 'errorFile': true}}
      else
      {
        
        control.setErrors({});
        console.log('error false');
        return null
      }
    }
    else
    {
      control.setErrors(null);
      return null

    }

    


  })
}

inputComprobanteClick() {
  document.getElementById('inputComprobante').click();
}

log(text)
{
  console.log(text);
}

}
