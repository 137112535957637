const baseUrl = "../../../../assets/marca-blanca/iconos/"

export const items = [
    {
        id:0,
        title: "Color",
        img:`${baseUrl}palette.svg`
    },
    {
        id:1,
        title: "Material",
        img:`${baseUrl}layers.svg`
    },
    {
        id:2,
        title: "Logo",
        img:`${baseUrl}letter-case.svg`
    },
    {
        id:3,
        title: "Diseño",
        img:`${baseUrl}paint-brush.svg`
    }
]