import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-confirmacionprestamo',
  templateUrl: './confirmacionprestamo.component.html',
  styleUrls: ['./confirmacionprestamo.component.scss']
})
export class ConfirmacionprestamoComponent implements OnInit {

  code1: any; code2: any;

  ok: any = null;

  constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute) { 

    this.activatedRoute.params.subscribe(params => {
      this.code1 = params.code1 || null;
      this.code2 = params.code2 || null;
        this.ds.loader = true;
        this.ds.consultarPrestamo(this.code1,this.code2).then(res => {


          this.ds.loader = false;
            if (res && res['r'] && res['r'].c == 0)
            {
              this.ok = true;
            }
            else if (res && res['r'] && res['r'].c == 1)
            {
              this.ok = false;
              this.ds.generateErrorAlert(res['r'].m)
            }
            else
            {
              this.ok = false;
              this.ds.generateErrorAlert('Ocurrió un error inesperado')
            }
        });

    });
  }


  ngOnInit(): void {
  }

  confirmarPrestamo()
  {
    this.ds.procesarPrestamo(this.code1,this.code2,'confirmar').then(res => {


      this.ds.loader = false;
        if (res && res['r'] && res['r'].c == 0)
        {
          this.router.navigateByUrl('/');
          this.ds.generateSuccessAlert('Felicidades!. El saldo aparecerá pronto en tu cuenta')
        }
        else if (res && res['r'] && res['r'].c == 1)
        {
          this.ds.generateErrorAlert(res['r'].m)
        }
        else
        {
          this.ds.generateErrorAlert('Ocurrió un error inesperado')
        }
    });
  }

  rechazarPrestamo()
  {
    this.ds.procesarPrestamo(this.code1,this.code2,'rechazar').then(res => {


      this.ds.loader = false;
        if (res && res['r'] && res['r'].c == 0)
        {
          this.router.navigateByUrl('/');
          this.ds.generateSuccessAlert('El prestamo fue rechazado correctamente.')
        }
        else if (res && res['r'] && res['r'].c == 1)
        {
          this.ds.generateErrorAlert(res['r'].m)
        }
        else
        {
          this.ds.generateErrorAlert('Ocurrió un error inesperado')
        }
    });
  }

}
