import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-referidos',
  templateUrl: './referidos.component.html',
  styleUrls: ['./referidos.component.scss']
})
export class ReferidosComponent implements OnInit {

  constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute,) { 
    this.activatedRoute.params.subscribe(params => {
      this.ds.hash_md5 = params.hash;

      if(this.ds.hash_md5)
      {
        ds.setPrimerComando().then(res => {
          this.ds.loader = false;
          let resInfo: any = res;
          // console.log(resInfo);
          if(resInfo.r) {
            if (resInfo.r.c == 0) {
              // this.router.navigateByUrl('/reg/paso2');
              // this.ds.emailRegistroP = this.formPaso1.value.email;
              // this.ds.storage.set('email', this.formPaso1.value.email);
              // this.ds.storage.set('paso', 2);
              // this.ds.storage.set('tipo', 1);
            } else {
              this.ds.generateErrorAlert(resInfo.r.m);
            }
          } else {
            // console.log();
            // this.ds.generateErrorAlert("Ocurrió un error inesperado");
          }
    
        });

      }
    });

  }

  ngOnInit(): void {
  }

}
