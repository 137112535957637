import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { FooterGeneralComponent } from './components/footer/footer.component';
import { NavbarFranquiciaComponent } from './components/navbarFraquicia/navbar.component';
import { CardsComponent } from './components/cards/cards.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [NavbarComponent, FooterGeneralComponent, NavbarFranquiciaComponent, CardsComponent, CarouselComponent],
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
    RouterModule
  ],
  exports: [NavbarComponent, FooterGeneralComponent, NavbarFranquiciaComponent, CardsComponent, CarouselComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
