
<app-navbar></app-navbar>
<div class="layout-1">
    <div class="container row">
        <div class="col-sm-7 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
          <p class="h5-responsive mb-3 text-primary text-center">Completá los datos para activar tu tarjeta VISA Prepaga</p>
          <div class="container-iframe mb-5 col-12">
            <mdb-spinner id="spinnerActivate" *ngIf="loading==true" spinnerColor="yellow"></mdb-spinner>
            <iframe (load)="uploadDone()" 
            src=""
            frameborder="0"
            class="iframe w-100 h-100"
            id="iframe_activar_tarjeta"
            ></iframe>
        </div>
        </div>
    </div>
</div>
<app-footer-general></app-footer-general>
