import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Options } from '@angular-slider/ngx-slider';
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-prestamos-simulador',
    templateUrl: './prestamos-simulador.component.html',
    styleUrls: ['./prestamos-simulador.component.scss']
})
export class PrestamosSimuladorComponent implements OnInit {
    ref: any = null;
    showForm: any = false
    showFormPrestamo: any = false
    showFormContacto: any = false
    formSelect = new FormGroup({
        provincia: new FormControl(null, [Validators.required]),
    }, { updateOn: 'change' });


    recaptcha: any = null

    paso: any = 0;
    valid: any = false;
    cuotasMinimas: any = null
    cuotasMaximas: any = null
    cuotasArray: any = null
    importeMinimo: any = null;
    importeMaximo: any = null;
    cft: any = null;
    tem: any = null;
    provinciasDisponible: any = [];


    test: any = false;
    testSlider: any = true
    testPass: any = null;
    testError: any = false;

    opcionesProvincia = [
        { value: 'buenosaires', label: 'Buenos Aires' },
        { value: 'catamarca', label: 'Catamarca' },
        { value: 'chaco', label: 'Chaco' },
        { value: 'chubut', label: 'Chubut' },
        { value: 'caba', label: 'Ciudad Autónoma de Buenos Aires' },
        { value: 'cordoba', label: 'Córdoba' },
        { value: 'corrientes', label: 'Corrientes' },
        { value: 'entrerios', label: 'Entre Ríos' },
        { value: 'formosa', label: 'Formosa' },
        { value: 'jujuy', label: 'Jujuy' },
        { value: 'lapampa', label: 'La Pampa' },
        { value: 'larioja', label: 'La Rioja' },
        { value: 'mendoza', label: 'Mendoza' },
        { value: 'misiones', label: 'Misiones' },
        { value: 'neuquen', label: 'Neuquén' },
        { value: 'rionegro', label: 'Río Negro' },
        { value: 'salta', label: 'Salta' },
        { value: 'sanjuan', label: 'San Juan' },
        { value: 'sanluis', label: 'San Luis' },
        { value: 'santacruz', label: 'Santa Cruz' },
        { value: 'santafe', label: 'Santa Fe' },
        { value: 'stgodelestero', label: 'Santiago del Estero' },
        { value: 'tierradelfuego', label: 'Tierra del Fuego' },
        { value: 'tucuman', label: 'Tucumán' }
    ];

    opcionesSexo = [
        { value: 2, label: "Femenino" },
        { value: 1, label: "Masculino" }
    ]

    constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute, private titleService:Title) {
        
        this.titleService.setTitle("BestCard Préstamos");
        
        this.activatedRoute.params.subscribe(params => {
            this.ref = params.hash || null;

            // ds.loader = true;
            // ds.setReferidoPrestamo(this.ref).then(res => {
            //   ds.loader = false;
            //   // this.ds.loader = false;
            //   let resInfo: any = res;
            //   // console.log(resInfo);
            //   if(resInfo.r) {
            //     if (resInfo.r.c == 0) {
            //       // this.jsonImportes = res['json']
            //     } else {
            //       this.ds.generateErrorAlert(resInfo.r.m);
            //     }
            //   } else {
            //     // this.ds.generateErrorAlert("Ocurrió un error inesperado");
            //   }
            // });

        });

        if (this.test == false) {
            this.traerInfoBasica();
        }
        else {
            this.testPass = window.prompt("Introduzca la contraseña")
            this.traerInfoBasica();
        }
    }

    ngOnInit(): void {
        this.paso = 0
        // this.paso=3
        // this.documentacion = [
        //   'item 1dasd ada sda da sdasd ads ad',
        //   'item 2dsadasdadsadasd',
        //   'item 3d aewq eq ewq eqw eqw eq',
        // ]



    }

    traerInfoBasica() {
        let data: any = {}
        if (this.test == true) {
            if (this.testPass == null || this.testPass == "") { this.testError = true; this.ds.loader = false; return }
            else data.password = this.testPass;
        }

        this.ds.generateWaitingAlert()
        this.ds.httpPostNoToken_V2(3,"prestamos/ld/v1/simular/base",data).subscribe((res:any) => {
            this.ds.closeWaitingAlert()
            this.ds.loader = false;
            if(res && res['r'] && res['r'].c==0)
            {
                this.importeMaximo = res['importeMaximo']
                this.importeMinimo = res['importeMinimo']
                this.cuotasMinimas = res['cuotasMinimas']
                this.cuotasMaximas = res['cuotasMaximas']
                this.cuotasArray = res['cuotasArray'].map(x => {return {value: x}})
                // this.cft = res['cft']
                // this.tem = res['tem']

                this.provinciasDisponible = res['prov']
      
                this.formSelect.patchValue({
                  provincia: "sanjuan",
                })
                this.selectPronvicia("sanjuan")
                this.setOptionRange()
                this.paso = 1
            }
            else if (res && res['r'] && res['r'].c==1)
            {
              if(res['r'].m=="Acceso Denegado") this.testError = true
              this.ds.generateErrorAlert(res['r'].m)
              // this.router.navigateByUrl("/");
            }
            else
            {
              this.ds.generateErrorAlert('Ocurrió un error inesperado al obtener la información')
              // this.router.navigateByUrl("/");
            }
          });

    }

    selectPronvicia(e) {
        if (e == null) {
            this.showForm = false;
            this.showFormPrestamo = false;
            this.showFormContacto = false;
        }
        else {
            if (this.provinciasDisponible.includes(e)) {
                this.showForm = true;
                this.showFormPrestamo = true;
                this.showFormContacto = false;
            }
            else {
                this.showForm = true;
                this.showFormContacto = true;
                this.showFormPrestamo = false;
            }
        }
    }

    @ViewChild('captchaRef') captchaRef; //toma el modal de la plantilla
    recaptcha_KEY = environment.recaptcha_KEY;
    recaptcha_execute = false;


    importeElegido: any = null;
    simulacionPlan: any = null;
    simulacionConfig: any = null;
    opcionesCuotas: any = [];
    cantCuotas: any = null
    simulacionHash: any = null
    simulacionEmail: any = null
    simulacionCuil: any = null
    documentacion: any = [];

    simularPrestamo() {
        let data = {
            cuotas: this.rangeCuotas,
            importe: this.rangeImporte
        };

        if(false)
        {
            this.simulando = false
            this.enableRange()
            this.valorCuota = "647.46"
            this.mostrarResultado = true
        }
        else
        {
            this.ds.httpPostNoToken(3, "prestamos/ld/v1/simular", data).then((res:any) => {
                // this.ds.loader = false;
                // console.log('res');
                // console.log(res);
                this.simulando = false
                this.enableRange()
                if (res && res['r'] && res['r'].c == 0) {
                    this.valorCuota = res.result
                    this.mostrarResultado = true
    
                }
                else if (res && res['r'] && res['r'].c == 1) {
                    this.ds.generateErrorAlert(res['r'].m)
                }
                else {
                    this.ds.generateErrorAlert("Ocurrió un error inesperado al procesar la solicitud.")
                }
            });
        }


    }

    validate_importe(control: AbstractControl) {

        if (control.value) {
            if (control.value < this.importeMinimo) return { 'min': true };
            else if (control.value > this.importeMaximo) return { 'max': true };
            else return null;
        }
        return { 'errorValidacion': true };
    }




    solicitarPrestamo() {

        // let link = `https://api.whatsapp.com/send/?phone=+5492644042921&text=Hola,%20quiero%20solicitar%20un%20préstamo%20por%20$${this.rangeImporte}%20en%20${this.rangeCuotas} cuota/s,%20¿Cuáles%20son%20los%20requisitos%3f`
        let link = `https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20quiero%20solicitar%20un%20préstamo%20por%20$${this.rangeImporte}%20en%20${this.rangeCuotas}%20cuota/s,%20¿Cuáles%20son%20los%20requisitos%3f`

        // link = this.replaceString(link)
        window.open(link, "_blank");

    }

    replaceString(str) {
        str = str.replaceAll(" ", "%20").replaceAll("#", "%23").replaceAll("&", "%26").replaceAll("?", "%3f").replaceAll("<", "%3c").replaceAll("=", "%3d").replaceAll(">", "%3e").replaceAll("+", "%2b")

        // .replaceAll("ñ","%f1").replaceAll("Ñ","%d1")

        for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) == 10) str = str.substr(0, i) + "%0A" + str.substr(i + 1, str.length);
        }

        return str;
    }

    rangeCuotas: number = this.cuotasMinimas;
    optionsRangeCuotas: Options = {
        floor: 0,
        ceil: 0,
        step: 3,
        showTicks: true,
        showTicksValues: true
    };
    rangeImporte: number = 0;
    optionsRangeImporte: Options = {
        floor: 0,
        ceil: 0,
        step: 1000,
        showTicks: true,
        // showTicksValues: true
    };
    valorCuota: any = 0

    setOptionRange()
    {
        this.optionsRangeImporte = Object.assign({}, this.optionsRangeImporte, {
            floor: 0,
            ceil: this.importeMaximo,
            // getLegend: (value: number): string => {
            //     if(value==0 || value==this.importeMaximo)
            //     return `$${value}`
            //   },
            ticksArray: [0, this.importeMaximo],
            translate: (value: number): string => {
                if(value==0 || value==this.importeMaximo)
                return '$' + value;
                else return ""
                // return ""
              }
                
        });

        this.optionsRangeCuotas = Object.assign({}, this.optionsRangeCuotas, {
            floor: this.cuotasMinimas,
            ceil: this.cuotasMaximas,
            // getLegend: (value: number): string => {
            //     if(value==0 || value==this.importeMaximo)
            //     return `$${value}`
            //   },
            stepsArray: this.cuotasArray,
            // ticksArray: [0, this.importeMaximo],
            // translate: (value: number): string => {
            //     return `${value}`
            //   }
                
        });
    }

    timeoutSimular: any = null
    timeoutPeticion: any = null
    simulando: any = false
    mostrarResultado: any = false
    error: any = null;

    rangeChange() {
        if(this.paso==1)
        {
            if(this.timeoutSimular!=null) clearTimeout(this.timeoutSimular);
            if(this.timeoutPeticion!=null) clearTimeout(this.timeoutPeticion);
            this.simulando = false
            
            this.timeoutSimular = setTimeout(() => {
                this.empezarSimulacion();
            }, 500);
        }
    }

    empezarSimulacion()
    {
        if(this.rangeImporte<this.importeMinimo)
        {
            this.error = `Elegí un monto mayor a $${this.importeMinimo.toFixed(2)}`
            this.mostrarResultado = false
        }
        else
        {
            this.error = null
            this.disableRange();
            this.simulando = true;
            this.mostrarResultado = false
            this.timeoutPeticion = setTimeout(() => {
                this.simularPrestamo()
            }, 1500);
        }

    }

    disableRange()
    {
        this.optionsRangeImporte = Object.assign({}, this.optionsRangeImporte, {disabled: true});
        this.optionsRangeCuotas = Object.assign({}, this.optionsRangeCuotas, {disabled: true});
    }

    enableRange()
    {
        this.optionsRangeImporte = Object.assign({}, this.optionsRangeImporte, {disabled: false});
        this.optionsRangeCuotas = Object.assign({}, this.optionsRangeCuotas, {disabled: false});
    }


}
