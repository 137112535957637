import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-icon-title',
  templateUrl: './card-icon-title.component.html',
  styleUrls: ['./card-icon-title.component.scss']
})
export class CardIconTitleComponent implements OnInit {
  
  @Input() title: string
  @Input() img:string

  constructor() { }

  ngOnInit(): void {
  }

}
