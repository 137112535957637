import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import KRGlue from "@lyracom/embedded-form-glue";

@Injectable({
  providedIn: 'root'
})
export class CobroinmediatoService {

  tokenFormulario: any;

  KR: any = null;
  generandoKR: any = false;

  endpoint = "https://api.cobroinmediato.tech";
  // publicKey: any = "80464095:testpublickey_gTOEWJGD0nIqGNJRDm4iuZiOheutFyp3F8iT6wmvtf6mg";
  publicKey = "80464095:publickey_0m9JtoX7O6oivZTp1KMRo62QUV7GTxISuU2R3BecDZhWG";
  // publicKey = "98247056:publickey_a1nNCM2KUzyzGRwJlZgRADzK8C7DuSys498ii4SMKYXrN";
  // publicKey = "98247056:testpublickey_p1vjoav2YqLqX2L2FIeLFi9TqW4iWvMj0SThf3orAXgYe";
  

  constructor(private ds: GlobalService) {
    const that = this;
    // if(ds.build == "prod") this.publicKey = "80464095:publickey_0m9JtoX7O6oivZTp1KMRo62QUV7GTxISuU2R3BecDZhWG"
    // console.log('generando kr');
  }

  generateKR() {
    const that = this;
    that.KR = null;
    // console.log('generando kr');
    return new Promise((resolve,reject) => {
      that.generandoKR = true;
      KRGlue.loadLibrary(that.endpoint, that.publicKey).then(({KR}) => {
        that.KR = KR;
        // console.log('kr generado');
        that.generandoKR = false;
        resolve(true);
        // resolve(KR);
      });
    });
  }

  getKR() {
    const that = this;
    return new Promise(async (resolve,reject) => {

      console.log('solicita kr');
      if(that.KR == null) {
      //  console.log("KR es null");
        if(that.generandoKR==false) {
          let generar = await that.generateKR();

          if(that.KR == null) {
            // console.log("KR sigue siendo null");
            resolve(null)
          } else {
            // let a = await that.KR.removeForms();

            // console.log('a');
            // console.log(a);
            resolve(that.KR);
          }
        } else {
          // console.log("KR esta siendo generado en este momento");
          resolve(null)
        }
      } else {
        that.KR.removeForms();
        resolve(that.KR);
      }
    })
  }

  // getLDP() {
  //   const that = this;
  //   try {
  //     return new Promise((resolve,reject) => {
  //       // if (that.ds.plataforma == 'local') {
          
  //       that.ds.storage.get('token').then(token => { 
  //         that.ds.http.post(that.ds.dominio3 + "firenze/recarga/tarjeta/get/ldp",{}, that.ds.getHttpOptionsBearerToken(token))
  //         .subscribe(resData => {

  //           console.log('resData');
  //           console.log(resData);

  //           let res: any = resData;
  //           resolve(res);
  //         }, error => {
  //           resolve(false);
  //         });
  //       });
  //     });
  //   } catch(error) {

  //   }
  // }
}
