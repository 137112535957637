import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrestamosConsultasService {

  constructor() { }

  $emitter = new EventEmitter();

  abrirModal(detalleModalCuota) {
      this.$emitter.emit(detalleModalCuota);
  }   
}
