<div class="layout-1">
    <div class="container row" *ngIf="ok===true">
        <div class="col-sm-5 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white pt-3 pb-2">
            <i class="fas fa-hand-holding-usd fa-5x mb-4 text-center"></i>
            <p class="font-size-085 text-center">Has solicitado un préstamo y requerimos que lo confirmes ¿Deseas confirmar el préstamo?</p>

            <div class="row">
                <div class="col-sm-6 my-2">
                    <button mdbBtn type="submit" (click)="rechazarPrestamo()" color="secondary" block="true" outline="true" class="d-block mb-0" mdbWavesEffect>
                        Rechazar
                    </button>
                </div>
                <div class="col-sm-6 my-2">
                    <button mdbBtn (click)="confirmarPrestamo()" type="submit" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container row" *ngIf="ok===false">
        <div class="col-sm-5 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white pt-3 pb-2">
            
            <div class="row">
                <div class="col-12 d-flex flex-column">
                    <h2 class="text-center">ERROR</h2>
                    <i class="fas fa-frown-open fa-5x mb-4 text-center"></i>
                    <p class="font-size-085 text-center">Ocurrió un error al intentar mostrar la confirmación del préstamo. Prueba nuevamente o puede que el link ya haya expirado.</p>
                </div>
            </div>
        </div>
    </div>
</div>