<!-- <nav class="navbar justify-content-between">
    <div class="container">
        <a href="/"><img src="../../../../assets/marca-blanca/logo-bestcard.svg"></a>
        <div class="container-buttons" >
           <a mdbPageScroll href="#functionalities"><app-btn-white-border border={{false}} text='Funcionalidades'></app-btn-white-border></a>
           <a mdbPageScroll href="#contact"><app-btn-white-border text="Contáctanos"></app-btn-white-border></a>
        </div>
    </div>
</nav> -->


<header class="h-100">
    <mdb-navbar style="background-color: #291733"  SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav py-2" [containerInside]="true">
        <mdb-navbar-brand>
            <a class="logo navbar-brand" href="#">
                <img src="../../../../assets/marca-blanca/logo-bestcard.svg" alt="Logo de BestCard" height="44"/>
            </a>
        </mdb-navbar-brand>
        <!-- *ngIf="!router.url.includes('consultas')" -->
        <links >
            <ul class="navbar-nav ml-auto">
                <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link d-none d-lg-block" [pageScrollOffset]="100" mdbPageScroll href="#functionalities"><app-btn-white-border border={{false}} text='Funcionalidades'></app-btn-white-border></a>
                    <a class="nav-link d-lg-none" [pageScrollOffset]="100" mdbPageScroll href="#functionalities">Funcionalidades</a>
                </li>
                <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link d-none d-lg-block" [pageScrollOffset]="80" mdbPageScroll href="#contact"><app-btn-white-border text="Contáctanos"></app-btn-white-border></a>
                    <a class="nav-link d-lg-none" [pageScrollOffset]="80" mdbPageScroll href="#contact">Contáctanos</a>
                </li>
            </ul>
        </links> 
    </mdb-navbar>
</header>   


