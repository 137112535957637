<app-navbar></app-navbar>
<div class="layout-1">
    <div class="container row">
        <div class="col-11 col-sm-9 text-justify mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
            <p class="tyc-title">
                TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN MÓVIL BestCard
            </p>
            <p class="tyc-text">

            </p>
            <p class="tyc-text">
                Los presentes términos y condiciones (en adelante, el “Acuerdo” o “Términos y Condiciones”
                o “Términos”) constituyen un contrato entre usted (en adelante, el “Usuario”) y ARGENTINA
                ESSENTIAL SERVICES SRL., CUIT 30-70880991-4. (en adelante, “BestCard” y, junto con
                el Usuario, las “Partes”), respecto del uso del servicio de la aplicación BestCard (en adelante,
                la “App”). Excepto en caso de que resulten expresamente aplicables otros términos y
                condiciones especiales, los Términos y Condiciones regirán los derechos y obligaciones de
                las Partes respecto de cualquier producto y/o servicio ofrecido a través de la App (en
                adelante, los “Servicios”). El Usuario se compromete a leer, comprender y aceptar todas las
                condiciones establecidas en este Acuerdo, previo a su registro como Usuario de la App. En
                caso de no encontrarse de acuerdo con los Términos y Condiciones, por favor, abstenerse
                de utilizar los Servicios. Los presentes Términos y Condiciones se considerarán aceptados
                desde el momento en que el Usuario cree una Cuenta de Usuario o utilice los Servicios, lo
                que ocurra primero.
            </p>
            <p class="tyc-subtitle">
                1. OBJETO
            </p>
            <p class="tyc-text">
                La App permite al Usuario administrar y monitorear los Servicios de BestCard, entre los
                cuales se encuentran, a título enunciativo:
                <br>● Solicitar la emisión de las tarjetas prepaga y de crédito BestCard (en adelante, las
                “Tarjetas BestCard”);
                <br>● Realizar recargas, pagos, retiros, solicitud de adelantos y demás transacciones que
                impliquen movimientos de fondos con relación al uso de las Tarjetas BestCard;
                monitorear los pagos realizados a través de la App y con las Tarjetas BestCard de
                titularidad del Usuario;
                <br>● Suspender transitoriamente, solicitar nuevos plásticos o dar de baja las Tarjetas
                BestCard del Usuario o el registro como Usuario; efectuar transferencias de dinero a
                otros usuarios mediante el uso de dispositivos de titularidad del Usuario;
                <br>● Realizar el pago de facturas o recargas de los servicios habilitados en la App;
                <br>● Solicitar y obtener préstamos personales y realizar el repago de los mismos;
                <br>● Mantener conversaciones a través de mensajería instantánea con el servicio de
                atención a usuarios y con otros usuarios;
                <br>● Y todos los demás servicios que oportunamente ofrezca BestCard a través de la
                App.
            </p>
            <p class="tyc-subtitle">
                2. MEDIOS DE UTILIZACIÓN
            </p>
            <p class="tyc-text">
                Los Servicios son prestados a través de Internet por medio de la App, mediante el uso de
                dispositivos de titularidad del Usuario, y con la participación de terceros prestadores de
                servicios complementarios como, a título enunciativo, procesadores de pago, redes de
                tarjetas de crédito y proveedores de servicios de procesamiento automatizado de datos.
            </p>
            <p class="tyc-subtitle">
                3. REQUISITOS PARA LA UTILIZACIÓN DE LA APP
            </p>
            <p class="tyc-text">
                El Usuario deberá contar con un dispositivo móvil con acceso a Internet y haber solicitado la
                emisión de una Tarjeta BestCard. El Usuario podrá operar desde cualquier dispositivo móvil
                en el cual se haya descargado la App luego de haber registrado dicho dispositivo en el
                sistema de BestCard por los medios previstos a tal fin. El Usuario deberá descargar la App
                desde las tiendas oficiales habilitadas para tal fin. El Usuario deberá generar un perfil con
                una contraseña para acceder a la App (en adelante, la “Cuenta de Usuario”) Algunos
                Servicios disponibles a través de la App requerirán de una segunda clave privada de 6
                dígitos numéricos para la confirmación de determinadas operaciones (en adelante, la “Clave
                de Confirmación” y, junto con la contraseña de acceso a la App, las “Claves”). El Usuario
                deberá generar la Clave de Confirmación siguiendo los pasos establecidos por BestCard a
                través de la App, y será el único y exclusivo responsable de la confidencialidad de sus
                Claves, así como también de todas las operaciones y/o actividades llevadas a cabo con las
                Claves y la Cuenta de Usuario.
            </p>
            <p class="tyc-subtitle">
                4. TRANSFERENCIAS DE DINERO
            </p>
            <p class="tyc-text">
                El Usuario autoriza a BestCard a realizar las transferencias de dinero ordenadas por el
                Usuario, tanto entre usuarios como respecto de la recarga de las Tarjetas BestCard o
                extracciones de dinero de las cuentas asociadas a sus Tarjetas BestCard, desde la App y a
                efectuar los movimientos correspondientes a los Servicios, siempre de acuerdo con los
                topes y restricciones fijados por BestCard. BestCard no estará obligada a efectuar las
                operaciones solicitadas por el Usuario en caso de que las cuentas asociadas a sus Tarjetas
                BestCard no cuenten con los fondos suficientes para ello.
            </p>
            <p class="tyc-subtitle">
                5. VALIDEZ
            </p>
            <p class="tyc-text">
                Las transacciones ordenadas por el Usuario únicamente tendrán validez en aquellos casos
                en los que el Usuario utilice los medios específicamente determinados por BestCard al
                efecto. No tendrán validez alguna, las instrucciones formuladas a través de cualquier otro
                medio distinto de los que BestCard determine específicamente a través de la App. Toda
                transacción que se efectúe desde la App mediante la Cuenta de Usuario se entenderá
                realizada por el Usuario, considerándose las mismas como válidas, legítimas y auténticas
                sin necesidad de realizar otro tipo de confirmación. BestCard podrá utilizar sistemas aptos
                para acreditar la pertenencia de las transacciones cursadas, y asimismo declara que
                aplicará sus mejores esfuerzos y las mejores prácticas exigidas por la industria a fin de
                garantizar la seguridad informática de los sistemas y registros empleados.
            </p>
            <p class="tyc-subtitle">
                6. FECHA DE LAS OPERACIONES
            </p>
            <p class="tyc-text">
                Todas las operaciones efectuadas por el Usuario se registrarán en la fecha y horario en que
                efectivamente se cursen. En ciertos casos, la fecha u horario de registro de la operación
                podrá ser posterior al momento en que se haya solicitado su ejecución, dependiendo del tipo
                de operación. Asimismo, el registro de la fecha y horario de las operaciones de recarga y
                extracción de dinero pueden encontrarse sujetas a condiciones adicionales por parte de
                cada tercero prestador de servicios.
            </p>
            <p class="tyc-subtitle">
                7. IRREVOCABILIDAD
            </p>
            <p class="tyc-text">
                Una vez efectuadas, las operaciones llevadas a cabo por el Usuario no podrán revocarse. La
                confirmación por parte de BestCard se realizará a través de un mensaje de confirmación
                para cada operación en particular, el cual se visualizará en la App al concluir cada
                transacción. El Usuario libera a BestCard de toda responsabilidad por las operaciones
                llevadas a cabo a través de su Cuenta de Usuario, y garantiza mantener indemne a BestCard
                por cualquier reclamo, daño y/o perjuicio derivado directa o indirectamente de la utilización
                de su Cuenta de Usuario.
            </p>
            <p class="tyc-subtitle">
                8. USOS PROHIBIDOS
            </p>
            <p class="tyc-text">            
                La App, los Servicios y los Contenidos sólo podrán ser utilizados con fines lícitos. BestCard
                prohíbe específicamente cualquier utilización de la App, los Servicios o los Contenidos para:
                <br>● Anunciar datos biográficos incompletos, falsos o inexactos.
                <br>● Usar cualquier mecanismo para impedir o intentar impedir el adecuado
                funcionamiento de la App, los Servicios o los Contenidos.
                <br>● Revelar o compartir las Claves con terceras personas, o usar las Claves para
                propósitos no autorizados.
                <br>● El uso o intento de uso de cualquier máquina, software, herramienta, agente u otro
                mecanismo para navegar o buscar en la App, los Servicios o los Contenidos que
                sean distintos a las herramientas de búsqueda provistos por BestCard en la App.
                <br>● Intentar descifrar, descompilar u obtener el código fuente de cualquier programa de
                software de la App.
                <br>● Cometer delitos, infracciones, contravenciones y/o daños de cualquier tipo y a
                cualquier persona.
                El usuario sólo podrá utilizar su Cuenta de Usuario y las Claves para sí y no podrá emplearla
                para que terceros efectúen transacciones y/o reciban información, ni tampoco para realizar
                transacciones por cuenta y orden de terceros. BestCard se reserva el derecho de suspender
                o dar de baja a cualquier Cuenta de Usuario que, a exclusivo criterio de BestCard , no cumpla
                con los estándares definidos en este Acuerdo, sin que ello genere derecho a resarcimiento
                alguno.
            </p>
            <p class="tyc-subtitle">
                9. CONFIDENCIALIDAD
            </p>
            <p class="tyc-text">            
                Los contenidos de la App, tales como texto, información, gráficos, imágenes, logos, marcas,
                programas de computación, bases de datos, diseños, APIs, arquitectura funcional y
                cualquier otro material (en adelante, "el Contenido"), están protegidos por las leyes vigentes
                en Argentina, incluyendo, pero sin limitación, las leyes sobre derechos de autor, patentes,
                marcas, modelos de utilidad, diseños industriales y nombres de dominio. Todo el Contenido
                es propiedad de BestCard y/o de cualquier otra sociedad vinculada y/o de sus proveedores
                de contenido. La compilación, interconexión, operatividad y disposición de los contenidos de
                la App es de propiedad exclusiva de BestCard y/o de sus empresas vinculadas. El uso,
                adaptación, reproducción y/o comercialización no autorizada del Contenido puede
                encontrarse penado por la legislación vigente en la República Argentina. El Usuario no
                copiará ni adaptará el código de programación desarrollado por, o por cuenta de, BestCard
                para generar y operar la App o los Servicios, el cual se encuentra protegido por la legislación
                aplicable y vigente en la República Argentina.
            </p>
            <p class="tyc-subtitle">
                10. PROPIEDAD INTELECTUAL
            </p>
            <p class="tyc-text">            
                Los contenidos de la App, tales como texto, información, gráficos, imágenes, logos, marcas,
                programas de computación, bases de datos, diseños, APIs, arquitectura funcional y
                cualquier otro material (en adelante, "el Contenido"), están protegidos por las leyes vigentes
                en Argentina, incluyendo, pero sin limitación, las leyes sobre derechos de autor, patentes,
                marcas, modelos de utilidad, diseños industriales y nombres de dominio. Todo el Contenido
                es propiedad de BestCard y/o de cualquier otra sociedad vinculada y/o de sus proveedores
                de contenido. La compilación, interconexión, operatividad y disposición de los contenidos de
                la App es de propiedad exclusiva de BestCard y/o de sus empresas vinculadas. El uso,
                adaptación, reproducción y/o comercialización no autorizada del Contenido puede
                encontrarse penado por la legislación vigente en la República Argentina. El Usuario no
                copiará ni adaptará el código de programación desarrollado por, o por cuenta de, BestCard
                para generar y operar la App o los Servicios, el cual se encuentra protegido por la legislación
                aplicable y vigente en la República Argentina.
            </p>
            <p class="tyc-subtitle">
                11. RESPONSABILIDADES
            </p>
            <p class="tyc-text">            
                El Usuario declara y acepta que el uso de la App, de los Servicios y de los Contenidos se
                efectúa bajo su única y exclusiva responsabilidad. BestCard se reserva el derecho de
                suspender y/o interrumpir el servicio a su exclusivo criterio. BestCard NO ASUME NINGUNA
                RESPONSABILIDAD POR EL FUNCIONAMIENTO DEL DISPOSITIVO Y/O EL SOFTWARE
                UTILIZADOS POR EL USUARIO PARA ACCEDER A LA APP, LOS CONTENIDOS Y/O LOS
                SERVICIOS, COMO ASÍ TAMPOCO RESPECTO DE AQUELLOS RELACIONADOS Y/U
                OCASIONADOS POR TERCEROS PRESTADORES DE SERVICIOS DE ACCESO A INTERNET,
                TELEFONÍA CELULAR Y/U CUALQUIER OTRO SERVICIO OTORGADO POR PERSONAS
                DISTINTAS A BestCard. EN NINGÚN CASO BestCard SERÁ RESPONSABLE DE CUALQUIER
                DAÑO INCLUYENDO, PERO SIN LIMITACIÓN, DAÑOS DIRECTOS Y/O INDIRECTOS, LUCRO
                CESANTE Y/O PÉRDIDA DE CHANCE QUE RESULTEN DEL USO Y/O DE LA IMPOSIBILIDAD
                DE USO DE LA APP, DE LOS SERVICIOS O DE LOS CONTENIDOS, SIN PERJUICIO DE QUE
                BestCard HAYA SIDO ADVERTIDO SOBRE LA POSIBILIDAD DE TALES DAÑOS. BestCard
                EXCLUYE TODA RESPONSABILIDAD POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA
                QUE PUDIERAN DEBERSE AL ACCIONAR DE TERCEROS NO AUTORIZADOS RESPECTO DE
                LOS DATOS PERSONALES DE LOS USUARIOS, ASÍ COMO DE LOS CONTENIDOS Y SERVICIOS
                OFRECIDOS A TRAVÉS DE LA APP.
            </p>
            <p class="tyc-subtitle">
                12. PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
            </p>
            <p class="tyc-text">            
                Cuando se registre en la App, se le pedirá al Usuario que aporte a BestCard cierta
                información que incluirá, a fines identificatorios y de conocimiento del cliente, entre otras,
                nombre y apellido, DNI, fecha de nacimiento, nacionalidad, sexo, CUIL o CUIT, domicilio real y
                de entrega de la tarjeta, número de teléfono celular del dispositivo donde se instala la
                aplicación, una dirección válida de correo electrónico, entre otros datos personales, además
                de declaraciones juradas de persona políticamente expuesta y licitud de fondos.
                Opcionalmente, el usuario podrá proveer CBU de cuenta bancaria de su titularidad a los
                efectos de realizar recargas o pagos de las Tarjetas BestCard, y otros datos que asistan a
                BestCard en la elaboración de un perfil crediticio del Usuario. Usted reconoce y acepta que
                BestCard puede revelar a terceras partes, de forma anónima, cierto conjunto de los datos
                suministrados por el Usuario para la utilización de los Servicios. BestCard no revelará a
                terceras partes sus datos personales sin su consentimiento previo, excepto en la medida en
                que sea necesario para el cumplimiento de las leyes y/o procedimientos legales vigentes,
                donde tal información sea relevante. BestCard se reserva el derecho de ofrecer servicios y
                productos de terceros basados en las preferencias que el Usuario haya indicado al momento
                de registrarse y/o en cualquier momento posterior; tales ofertas pueden efectuarse por
                BestCard o por terceros. Por favor consulte la Política de Privacidad de la App para conocer
                los detalles respecto del tratamiento de sus datos personales. El Usuario será responsable
                de todos los usos de su cuenta, tanto si están autorizados o no por el Usuario. El Usuario
                deberá notificar inmediatamente a BestCard sobre cualquier uso sin autorización de su
                Cuenta de Usuario y/o las Claves. Los Usuarios que utilicen los Servicios de BestCard
                garantizan la veracidad, exactitud, vigencia y autenticidad de la información facilitada, y se
                comprometen a mantenerlos debidamente actualizados, informando a BestCard sobre
                cualquier modificación a través de la actualización de la información correspondiente en su
                Cuenta de Usuario, o poniéndose en contacto a ayuda@bestcard.com.ar.
            </p>
            <p class="tyc-subtitle">
                13. VÍNCULOS A SITIOS DE TERCEROS
            </p>
            <p class="tyc-text">
                La App puede contener vínculos a sitios web y/o aplicaciones de terceros (en adelante, los
                “Sitios de Terceros”). BestCard no respalda ni garantiza los contenidos de estos Sitios de
                Terceros. BestCard no es responsable del contenido de los Sitios Terceros y no hace
                ninguna afirmación relativa al contenido y/o exactitud en estos Sitios de Terceros. Si Usted
                decide acceder a Sitios de Terceros, lo hace a su propio riesgo.
            </p>
            <p class="tyc-subtitle">
                14. MODIFICACIÓN DE LOS SERVICIOS
            </p>
            <p class="tyc-text">            
                BestCard se reserva el derecho de modificar, restringir y/o suprimir todos o cualquiera de los
                Servicios brindados a través de la App, en forma temporal o definitiva, sin que estas
                medidas puedan ser objeto de requerimiento alguno, ni de derecho a reclamar daños o
                perjuicios por parte del Usuario.
            </p>
            <p class="tyc-subtitle">
                15. PRUEBA DE LAS OPERACIONES
            </p>
            <p class="tyc-text">
                El Usuario acepta que las órdenes impartidas por éste a través de los medios especialmente
                habilitados al efecto para los Servicios, serán tenidas como prueba de su voluntad de haber
                realizado las operaciones. El Usuario acepta expresamente la información sobre su
                utilización de los Servicios que surja de los soportes magnéticos y/o electrónicos provistos
                por BestCard a través de la App y/o de los medios especiales que BestCard disponga a tal
                efecto.
            </p>
            <p class="tyc-subtitle">
                16. COSTO DE LOS SERVICIOS
            </p>
            <p class="tyc-text">
                BestCard podrá cobrar comisiones por el mantenimiento y/o uso de los Servicios, previa
                notificación al Usuario dentro del plazo legal correspondiente, entendiéndose expresamente
                facultado para efectuar los correspondientes débitos en las cuentas asociadas a las
                Tarjetas BestCard del Usuario. Las comisiones mencionadas precedentemente no incluyen
                los costos que aplican las empresas de telefonía celular por los servicios de transmisión de
                datos.
            </p>
            <p class="tyc-subtitle">
                17. RESCISIÓN DEL SERVICIO
            </p>
            <p class="tyc-text">
                La prestación de los Servicios tiene una duración indeterminada. Sin perjuicio de lo anterior,
                BestCard está autorizada para terminar o suspender la prestación de los Servicios y/o de
                cualquiera de los Contenidos en cualquier momento y sin expresión de causa.
            </p>
            <p class="tyc-subtitle">
                18. CESIÓN O USO COMERCIAL NO AUTORIZADO
            </p>
            <p class="tyc-text">
                El Usuario acepta no ceder, bajo ningún título, sus derechos u obligaciones bajo el presente
                Acuerdo. El Usuario también acepta no realizar ningún uso comercial no autorizado de la
                App, de los Servicios o de los Contenidos. Asimismo, el Usuario se compromete a utilizar la
                App, el Contenido y los Servicios diligentemente y de conformidad con la ley aplicable y
                vigente de la República Argentina y con este Acuerdo. BestCard se reserva el derecho, a su
                exclusivo criterio, de emplear todos los medios legales a su alcance en caso que el Usuario
                infrinja cualquiera de estos Términos y Condiciones.
                19. TOTALIDAD DEL ACUERDO
                Los presentes Términos y Condiciones, junto con la Política de Privacidad, los términos
                específicos correspondientes a las Tarjetas BestCard y aquellos que eventualmente se
                especifiquen para cada Servicio constituyen la totalidad del acuerdo entre el Usuario y
                BestCard.
            </p>
            <p class="tyc-subtitle">
                20. DOMICILIO. LEY APLICABLE. COMPETENCIA
            </p>
            <p class="tyc-text">
                Estos Términos y Condiciones se rigen por las leyes de la República Argentina. Toda acción
                derivada de estos Términos y Condiciones o del uso de la App deberá someterse
                exclusivamente a la jurisdicción de los juzgados y tribunales con competencia en materia
                Comercial de la Ciudad de San Juan de la República Argentina. En caso de declararse la
                nulidad de alguna de las cláusulas de este Acuerdo, tal nulidad no afectará a la validez de
                las restantes, las cuales mantendrán su plena vigencia y efecto. A todos los efectos
                emergentes de estos Términos, BestCard constituye domicilio en Mitre 425 Sur, Ciudad de
                San Juan, República Argentina.
            </p>
            <p class="tyc-subtitle">
                21. App Movil BestCard.
            </p>
            <p class="tyc-text">
                <br>1. Cualquier persona (en adelante "Usuario" o en plural "Usuarios") que desee acceder
                y/o usar la app podrá hacerlo sujetándose a los Términos y Condiciones que a
                continuación se detallan. Cualquier Usuario que no acepte estos términos y
                condiciones (los cuales tienen un carácter obligatorio y vinculante), deberá
                abstenerse de utilizar la app. El Usuario debe leer, entender y aceptar todos los items
                establecidos en los Términos y Condiciones, previo a su registración como Usuario
                de BestCard.
                <br>2. BestCard se reserva el derecho, a su sola discreción, de modificar, alterar o
                actualizar, estos Términos y condiciones en cualquier momento. Las modificaciones
                entrarán en vigencia desde el momento que se indique, en su defecto, se entenderá
                que su aplicación es inmediata. Usando esta app después de dichas modificaciones,
                usted acepta estar sujeto a dichas modificaciones, alteraciones o actualizaciones de
                los Términos y Condiciones, sin derecho a efectuar reclamo alguno con relación a
                ello.
                <br>3. Condiciones de acceso y utilización de la app: La utilización de cualquier Servicio de
                la app atribuye la condición de Usuario del mismo. El acceso y utilización de la app
                requiere el previo registro de Usuario. A fin de registrarse en la app el Usuario deberá
                indicar un email y una contraseña. El Usuario se compromete a seleccionar, usar y
                conservar su contraseña de forma diligente y a mantener las mismas en secreto y en
                protección respecto de terceros. BestCard se reserva el derecho de bloquear y/o
                eliminar publicaciones y/o Usuarios que considere necesario sin previo aviso ni
                justificación correspondiente.
                <br>4. Utilización de los servicios que ofrece la app: El Usuario reconoce y acepta que el
                uso de los Contenidos y/o Servicios ofrecidos por la app será bajo su exclusiva
                responsabilidad haciendo un uso adecuado de los mismos y no emplearlos para
                realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos
                de terceros y/o que infrinjan la regulación sobre propiedad intelectual e industrial, o
                cualquier otra norma del ordenamiento jurídico aplicable. En particular, el Usuario se
                compromete a: No trasmitir, introducir, difundir y poner a disposición de terceros,
                cualquier tipo de material e información (datos, contenidos, mensajes, imágenes,
                fotografías, software, etc.) que sean contrarios a la ley, la moral, el orden público, los
                presentes Términos y condiciones y, en su caso, a las Condiciones Particulares que
                le sean de aplicación. No ingresar o difundir contenidos o propaganda de carácter
                racista, xenófobo, pornográfico, de apología del terrorismo o que atenten contra los
                derechos humanos. No ingresar o difundir virus y software nocivo susceptibles de
                provocar daños a los dispositivos o software de otros Usuarios de la app. No
                ingresar, difundir, transmitir o poner a disposición de terceros cualquier tipo de
                información, elemento o contenido que constituya publicidad ilícita o desleal o no
                autorizada por los Usuarios administradores de la app. No ingresar o difundir
                cualquier información y contenidos falsos, ambiguos, extemporáneos o inexactos de
                forma que induzca a error a los receptores de la información. No introducir o difundir
                contenidos que sean contrarios al honor, a la intimidad personal y familiar o a la
                propia imagen de las personas. No ingresar, difundir, transmitir o poner a disposición
                de terceros cualquier tipo de información, elemento o contenido que suponga una
                violación de los derechos de propiedad intelectual e industrial, patentes, marcas o
                copyright que correspondan a terceros. No ingresar, difundir, transmitir o poner a
                disposición de terceros cualquier tipo de información, elemento o contenido que
                suponga una violación del secreto de las comunicaciones y la legislación de datos
                de carácter personal. No dirigirse a los oferentes mediante el envío de Spam, o
                directamente ofrecer, publicar o crear, una base de datos personales de terceros. No
                revelar o compartir su contraseña con terceras personas o usar su contraseña para
                cualquier propósito no autorizado. No publicar contenido no apto para menores. Se
                incluyen el contenido fetichista y/o publicaciones que promocionen o vendan
                accesorios sexuales. No insultar, agredir, burlarse, ironizar o introducir cualquier
                forma de falta de respeto en una conversación en tiempo real con otro Usuario.
                <br>5. Al registrarse y/o navegar la app, el Usuario brinda información personal, prestando
                su consentimiento para que la misma sea almacenada directamente en nuestra base
                de datos, encontrándose protegida electrónicamente, utilizando los mecanismos de
                seguridad informática de protección de la información más completos y eficaces
                para mantenerla en total confidencialidad.
                <br>6. BestCard no es responsable por la publicación, veracidad y exactitud de la
                información que la app provea, ni de los cambios que sobre la misma pudieran
                acontecer.
                <br>7. BestCard tendrá el derecho de ampliar o mejorar los servicios prestados sin
                necesidad de comunicación previa, así como de alterar los medios o rutas utilizados
                para la prestación del servicio.
                <br>8. Los productos, bienes y/o servicios ofrecidos y/o publicados en la app no se
                encuentran garantizados por BestCard, asumiendo quienes los proveen toda la
                responsabilidad respecto a la calidad, los vicios redhibitorios, evicción y/o cualquier
                pérdida o daño que pudiera sufrirse con relación a los pedidos formalizados sobre
                los mismos por los Usuarios de BestCard.
                <br>9. Los Usuarios que utilicen la app BestCard, por su sola utilización aceptarán de pleno
                derecho todas y cada una de las disposiciones previstas en los presentes Términos y
                condiciones.
                <br>10. La utilización de la cámara móvil, sera únicamente para la validación de las
                imágenes de identidad y lectura de códigos de barra o QR.
            </p>
            <p class="tyc-subtitle">
                22. Adelanto de Pago.
            </p>
            <p class="tyc-text">
                <br>1. Adelanto de Pago.
                BestCard podrá ofrecer al Usuario el producto de adelantos sobre la
                acreditación de los pagos que tenga pendientes de liquidación(el Adelanto de
                Pagos ”). El Usuario podrá en cada oportunidad realizar una solicitud de
                Adelanto de Pago de acuerdo a lo previsto en la cláusula 2. BestCard a su
                exclusivo criterio y en cualquier momento podrá aceptar o denegar, total o
                parcialmente, cada solicitud de Adelanto de Pago. La aceptación de una o
                más solicitudes de Adelanto de Pago por BestCard no implica la aceptación
                de futuras solicitudes de Adelanto de Pago por parte de BestCard. BestCard
                definirá, en base a sus políticas de riesgo, comerciales, y programas de
                protección a usuarios, los montos que el Usuario puede requerir mediante una
                solicitud de Adelanto de Pago.
                <br>2. Solicitud de Adelanto de Pago.
                En caso de que BestCard ofrezca al Usuario en su Cuenta BestCard un
                monto sobre el que éste pueda realizar una solicitud de Adelanto de Pago, el
                Usuario podrá solicitarlo siendo facultad exclusiva y excluyente de BestCard
                aprobar o rechazar esa solicitud, en cualquier momento y a su exclusiva
                discreción, sin necesidad de justificación alguna. BestCard informará al
                Usuario por alguno de los distintos canales online disponibles: (i) el monto
                sobre el cual el Usuario puede solicitar un Adelanto de Pago (los “Pagos a
                Adelantar”); y (ii) el costo de dicho Adelanto de Pago.
                <br>3. Costo.
                El costo del Adelanto de Pago será el monto equivalente a un
                porcentaje fijo sobre los Fondos que se adelantan, más los correspondientes
                impuestos. Dichos costos serán informados por alguno de los distintos
                canales online disponibles previamente a concretar el Adelanto de Pago, y se
                descontarán del monto de los Pagos a Adelantar, acreditando el neto
                correspondiente en la Cuenta BestCard del Usuario.
            </p>
            <p class="tyc-subtitle">
                Términos y condiciones de Préstamos Personales
            </p>
            <p class="tyc-text">            
                Condiciones generales
                <br>1. 1- Desembolso del Préstamo. Una vez aceptado el Préstamo por el “Deudor”
                BestCard desembolsará el Importe del Préstamo solicitado en moneda de
                curso legal en Argentina, hasta dentro de las 24 hs. hábiles de efectuada la
                aceptación. El desembolso se realizará mediante la acreditación del total del
                Importe del Préstamo en la cuenta BestCard del Deudor.
                <br>2. -El total del préstamo será devuelto por el “Deudor” en la cantidad de cuotas
                de capital e interés, mensuales y consecutivas del importe especificado en
                datos del préstamo conforme esta “Solicitud de Préstamo” aceptada por el
                “Deudor”, venciendo la primera de ellas el día expuesto en la primera cuota y
                las restantes en igual fecha de los meses subsiguientes hasta terminar.
                <br>3. La deuda devengará un interés compensatorio vencido sobre saldos de
                acuerdo al sistema francés de amortización, habiéndo establecido la Tasa
                Efectiva Mensual expuesta en los datos del préstamo.
                <br>4. El “Deudor” autoriza a BestCard a debitar cada cuota de capital e interés, de
                su cuenta BestCard . El préstamo podrá ser cancelado anticipadamente por el
                “Deudor”. En tal caso se descontarán los intereses compensatorios
                correspondientes al período de tiempo anticipado.
                <br>5. Vigencia: El Contrato estará vigente hasta el cumplimiento total de las
                obligaciones a cargo del “Deudor” y se entenderá cumplido cuando el
                “Deudor” haya efectuado el reembolso total del Préstamo, y haya pagado la
                totalidad de los Intereses Compensatorios, Punitorios, Cargos y otras sumas
                adeudadas a BestCard bajo el presente MUTUO.
                <br>6. El incumplimiento del “Deudor” a cualquiera de las obligaciones asumidas en
                el presente, como la falta de pago en tiempo y forma según lo indicado en
                estas condiciones, hará incurrir en mora de pleno derecho al “Deudor” en
                forma automática por el solo vencimiento de los plazos fijados, sin necesidad
                de interpelación extrajudicial o judicial previa alguna, produciéndose la
                caducidad de todos los plazos no vencidos haciéndose exigible la totalidad
                del capital contenido en las cuotas adeudadas. Los saldos exigibles
                devengarán a partir del vencimiento y hasta su cancelación total, además del
                interés compensatorio pactado, un interés punitorio del 50% de dicho interés
                compensatorio. Además de los intereses punitorios, el “Deudor” abonará a
                BestCard: en caso de mora, un cargo por reembolso de gastos para la
                recuperación del crédito y el costo del pleito judicial, en caso de corresponder.
                <br>7. BestCard se reserva el derecho de ceder o transmitir fiduciariamente, a
                terceros, en los términos de los artículos 70 a 72 de la ley 24.441, la
                titularidad de los derechos y/o créditos generados o a generarse en razón del
                presente préstamo personal, acordándose, en virtud de los dispuesto por el
                artículo 72 y concordantes de la ley 24.441, que no será necesario efectuar
                notificación a Ud. en su carácter de “Deudor” cedido de la cesión de créditos
                y/o derechos por existir esta previsión contractual en este sentido. 7-
                Revocación: el “Deudor” tendrá derecho a revocar su aceptación del Préstamo
                en un plazo de 10 (diez) días corridos desde la fecha de su aceptación o de su
                acreditación en la “Cuenta”, lo último que ocurra, sin necesidad de indicar las
                razones y sin penalidad alguna. Si ejerciere este derecho el “Deudor” deberá
                notificar su decisión en tal sentido, en forma fehaciente a BestCard y dentro
                del mismo plazo de diez (10) días, y conjuntamente con su manifestación
                restituir la totalidad del dinero prestado en cualquier sucursal de BestCard,
                caso contrario dicho importe será incluido en el próximo Resumen de Cuenta
                de la Tarjeta de Crédito de que es Titular el Deudor.
                <br>8. Jurisdicción: A todos los efectos del presente, serán competentes los
                tribunales ordinarios correspondientes al domicilio consignado en la solicitud
                precedente, siendo obligatorio notificar fehacientemente a BestCard cualquier
                cambio del mismo.
            </p>
            <p class="tyc-subtitle">
                23. Devoluciones.
            </p>
            <p class="tyc-text">
                El cliente deberá solicitar por e-mail, o en forma escrita a Bestcard la devolución de
                una o varias transacciones, las mismas serán analizadas por el área de seguridad y
                Fraudes en un término no mayor a 30 días hábiles, el resultado del análisis será notificado
                al cliente por medio de e-mail y la App Bestcard.
                En caso de ser favorable para el cliente el monto reclamado será acreditado en su
                cuenta Bestcard. Sin embargo los plazos de devolución pueden estar sujetos a las normas y
                tiempos de las entidades emisoras de los medios de pago.
            </p>
            <p class="tyc-subtitle">
                24. Operador Cuenta de Pago.
            </p>
            <p class="tyc-text">
                La cuenta de pago es operada por Pomelo Fintech Services S.A.S. (“Pomelo”).
                Pomelo se limita a ofrecer servicios de pago y no está autorizado por el Banco Central a
                operar como entidad financiera. Los fondos depositados en cuentas de pago no constituyen
                depósitos en una entidad financiera, ni cuentan con ninguna de las garantías que tales
                depósitos puedan gozar de acuerdo con la legislación y reglamentación aplicables en
                materia de depósitos en entidades financieras.
            </p>
            <br>
            <p class="tyc-text">
                Última actualización: 30 de Noviembre del 2022.
                Ver 3.01
            </p>
            







            


        </div>
    </div>
</div>
<app-footer-general></app-footer-general>