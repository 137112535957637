<app-navbar></app-navbar>
<div class="container-registro">
    <div class="layout-1" id="referidos">
        <div class="container py-4">
            <div class="row">
                <div class="col-lg-8">
                    <h1 class="white-text text-display-1 mb-4 title-registro">UNITE A BESTCARD</h1>
                    <p class="white-text text-display-description text-subtitulo-registro">Descubrí cómo BestCard puede simplificarte tu vida</p>
                    <p class="white-text text-display-description">
                        <img src="/assets/home/iconos/icon-check.svg">
                        Realiza compras en cualquier comercio o página web de forma rápida y segura.
                    </p>
                    <p class="white-text text-display-description">
                        <img src="/assets/home/iconos/icon-check.svg">
                        Paga tus facturas y recarga tus servicios prepagos de manera sencilla y cómoda.
                    </p>
                    <p class="white-text text-display-description">
                        <img src="/assets/home/iconos/icon-check.svg">
                        Retira efectivo en cualquier cajero de Red Link o Banelco.
                    </p>
                    <p class="white-text text-display-description">
                        <img src="/assets/home/iconos/icon-check.svg">
                        Transfierí dinero de forma instantánea a cualquier cuenta.
                    </p>
                    <p class="white-text text-display-description">
                        <img src="/assets/home/iconos/icon-check.svg">
                        Usala en cualquier parte del mundo.</p>
                    <!-- <p class="white-text text-display-description font-weight-bold">¿Te interesa, no?</p> -->
    
                    <div class="d-flex align-items-center justify-content-center mt-5">
                        <div class="d-flex align-items-center">
                            <!-- <button mdbBtn type="submit" color="primary" routerLink="/reg/unirse" size="lg" class="d-block waves-light my-0 mr-3" mdbWavesEffect>
                                Registrarme Ahora
                            </button> -->
                            <button  type="submit" routerLink="/reg/unirse" class="d-block waves-light mb-2 btn-registro" mdbWavesEffect>
                                <img src="../../../.../../assets/home/iconos/icono-registro-blanco.svg">
                                Registrarme
                            </button>
                            <!-- <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                            <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                            <i class="fas fa-caret-right primary-text fa-3x mx-2"></i> -->
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-center mt-5">
                        <div class="d-flex align-items-center">
                            <button mdbBtn type="submit" color="primary" routerLink="/emp/unirse" size="lg" class="d-block waves-light my-0 mr-3" mdbWavesEffect>
                                Registrarme Como Empresa
                            </button>
                            <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                            <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                            <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                        </div>
                    </div> -->
    
                </div>
                <div class="col-lg-4 px-0 d-none d-lg-block mb-5" style="position: unset !important;">
                    <img src="../../assets/home/fondo_chica.webp" class="referidos-img">
                </div>
            </div>
    
    
        </div>
    </div>
</div>