<app-navbar></app-navbar>
<div class="layout-local">

    <div class="container row flex-column py-4 px-2">
        <div class="col-12 col-sm-6 mx-auto" *ngIf="testError==false">
            <div class="m-0 p-0">
                <div class="col-12 mx-auto d-flex flex-column">
                    <div class="col-10 col-sm-8 p-2 titulo m-0 ml-4 d-flex">
                        <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate">Solicitá tu préstamo</span>
                        <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt="">
                        <!-- <i class="fas fa-hand-holding-usd"></i> -->
                    </div>
                </div>

                <!-- PASO 1 -->
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2"  *ngIf="paso==1">
                  <p class="font-size-085 text-center my-2">Completa los campos a continuación para solicitar tu préstamo:</p>
                  <!-- <div class="row">
                    <div class="d-flex justify-content-between mb-2 mt-2">
                        <button mdbBtn type="button" color="primary" block="true" class="d-block waves-light mb-0" mdbWavesEffect (click)="verifyFormDisponibilidad2()">
                            TEST
                        </button>
                    </div>
                  </div> -->
                  <form [formGroup]="formSelect" class="d-flex flex-column h-100" >
                      <div class="row">
                          <div class="col-12 my-3">
                              <div class="md-form md-outline whiteness m-0">
                                  <mdb-select [outline]="true" (ngModelChange)="selectPronvicia($event)" [options]="opcionesProvincia" label="Provincia *" formControlName="provincia"></mdb-select>
                              </div>
                          </div>
                      </div>
        
                  </form>
                    <form [formGroup]="formPrestamo" class="d-flex flex-column h-100" (ngSubmit)="verifyFormDisponibilidad()" *ngIf="showForm">
                        <div class="row" *ngIf="showFormContacto">
                            <div class="col-12">
                                <p class="font-size-085 text-center text-danger font-weight-bold">Por el momento no tenemos préstamos disponibles para tu provincia.</p>
                                <p class="font-size-085 text-center text-danger font-weight-bold">Si querés dejanos tus datos y cuando haya disponibilidad nos pondremos en contacto.</p>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="text" id="formNombre" class="form-control" formControlName="nombre" mdbInput>
                                    <label for="formNombre">Nombres *</label>
                                    <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched">Nombre Inválido</mdb-error>
                                    <mdb-success *ngIf="form_nombre.valid && form_nombre.touched">Nombre Válido</mdb-success>
                                </div>
                            </div>
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="text" id="formApellido" class="form-control" formControlName="apellido" mdbInput>
                                    <label for="formApellido">Apellidos *</label>
                                    <mdb-error *ngIf="form_apellido.invalid && form_apellido.touched">Apellido Inválido</mdb-error>
                                    <mdb-success *ngIf="form_apellido.valid && form_apellido.touched">Apellido Válido</mdb-success>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="email" id="formEmailRegistro" class="form-control" formControlName="email" mdbInput>
                                    <label for="formEmailRegistro">Tu Email *</label>
                                    <mdb-error *ngIf="form_email.invalid && form_email.touched">Email Inválido</mdb-error>
                                    <mdb-success *ngIf="form_email.valid && form_email.touched">Email Válido</mdb-success>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2 my-3 mr-0 pr-0">
                                <div class="md-form md-outline whiteness m-0">
                                    <label>+549</label>
                                </div>
                            </div>
                            <div class="col-4 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="number" id="formCodArea" class="form-control no-arrows" formControlName="codArea" mdbInput
                                    mdbTooltip="Código de Área, sin el 15 ni 0">
                                    <label for="formCodArea">Cod.Area *</label>
                                    <mdb-error *ngIf="form_codArea.invalid && form_codArea.touched && form_codArea.errors?.required">Cod. Inválido</mdb-error>
                                    <mdb-error *ngIf="form_codArea.invalid && form_codArea.touched && form_codArea.errors?.min">Min. 2 cifras</mdb-error>
                                    <mdb-error *ngIf="form_codArea.invalid && form_codArea.touched && form_codArea.errors?.max">El valor es muy largo</mdb-error>
                                    <mdb-success *ngIf="form_codArea.valid && form_codArea.touched">Cod. Válido</mdb-success>
                                </div>
                            </div>
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="number" id="formNumero" class="form-control no-arrows" formControlName="numCel" mdbInput
                                    mdbTooltip="Número sin código de área, sin 15 ni 0">
                                    <label for="formNumero">Número Teléfono *</label>
                                    <mdb-error *ngIf="form_numCel.invalid && form_numCel.touched && form_numCel.errors?.required">Número Inválido</mdb-error>
                                    <mdb-error *ngIf="form_numCel.invalid && form_numCel.touched && form_numCel.errors?.min">Min. 7 cifras</mdb-error>
                                    <mdb-error *ngIf="form_numCel.invalid && form_numCel.touched && form_numCel.errors?.max">El valor es muy largo</mdb-error>
                                    <mdb-success *ngIf="form_numCel.valid && form_numCel.touched">Número Válido</mdb-success>
                                </div>
                            </div>
                        </div>
                        <div class="row">  
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="text" id="formFechaNac" class="form-control" (click)="openDateTime()" formControlName="fechaNac" mdbInput autocomplete="off">
                                      <label for="formFechaNac">Fecha de Nacimiento *</label>
                                    <mdb-error *ngIf="form_fechaNac.invalid && form_fechaNac.touched">Fecha Inválida</mdb-error>
                                    <mdb-success *ngIf="form_fechaNac.valid && form_fechaNac.touched">Fecha Válida</mdb-success>
                                </div>
                            </div>
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <div class="md-form md-outline whiteness m-0">
                                        <mdb-select [outline]="true"  [options]="opcionesSexo" label="Sexo *" formControlName="sexo"></mdb-select>
                                    </div>
                                    <mdb-error *ngIf="form_sexo.invalid && form_sexo.touched">Debe elegir sexo</mdb-error>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="row">  
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="number" id="formDni" class="form-control no-arrows" formControlName="dni" mdbInput>
                                    <label for="formDni">DNI *</label>
                                    <mdb-error *ngIf="form_dni.invalid && form_dni.touched">DNI Inválido</mdb-error>
                                    <mdb-success *ngIf="form_dni.valid && form_dni.touched">DNI Válido</mdb-success>
                                </div>
                            </div>
                            <div class="col-6 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="number" id="formCuil" class="form-control no-arrows" formControlName="cuil" mdbInput>
                                    <label for="formCuil">CUIL *</label>
                                    <mdb-error *ngIf="form_cuil.invalid && form_cuil.touched">CUIL Inválido</mdb-error>
                                    <mdb-success *ngIf="form_cuil.valid && form_cuil.touched">CUIL Válido</mdb-success>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 my-3 justify-content-center align-self-center d-flex">
                                <p class="text-primary font-weight-bold my-auto">¿No sabés tu CUIL?</p>
                            </div>
                            <div class="col-6 my-3">
                                <a mdbBtn type="button" href="https://micuilonline.com.ar/averiguar-cuil-cuit" size="sm" target="_blank" color="primary" block="true" class="d-block waves-light my-0" mdbWavesEffect>
                                    Averiguar CUIL
                                </a>
                            </div>
                        </div>
                        <div class="row">  
                            <div class="col-12 my-3">
                                <div class="md-form md-outline whiteness m-0">
                                    <input type="number" id="formImporte" class="form-control no-arrows" formControlName="importe" mdbInput>
                                    <label for="formImporte">¿Cuánto necesitas? *</label>
                                    <mdb-error *ngIf="form_importe.invalid && form_importe.touched && form_importe.errors?.required">Ingresá el importe</mdb-error>
                                    <mdb-error *ngIf="form_importe.invalid && form_importe.touched && form_importe.errors?.min">El mínimo permitido es ${{importeMinimo | number : ".2"}}</mdb-error>
                                    <mdb-error *ngIf="form_importe.invalid && form_importe.touched && form_importe.errors?.max">El máximo permitido es ${{importeMaximo | number : ".2"}}</mdb-error>
                                    <mdb-success *ngIf="form_importe.valid && form_importe.touched">Importe Válido</mdb-success>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="showFormPrestamo">
                            <div class="col-12 my-1">
                                <div class="form-group form-check">
                                    <input type="checkbox" formControlName="terminos" id="acceptTerms" class="form-check-input mr-2"/>
                                    <label for="acceptTerms" class="form-check-label" id="labelAcceptTerms">Acepto los <a href="https://bestcard-production.s3.us-east-2.amazonaws.com/bestcard/assets/tyc/tyc_prestamos_personales.pdf" target="_blank">Términos y condiciones</a></label>
                                    <div *ngIf="form_terminos.touched && form_terminos.errors?.requiredTrue" class="invalid-feedback">Debe aceptar los términos y condiciones</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-12 d-flex">
                                <re-captcha class="m-auto align-content-center my-3" siteKey="{{recaptcha_KEY}}"></re-captcha>
                            </div>
                        </div> -->
                        <!-- activar el re-captch -->
                        
                        <div class="col-12 d-flex">
                            <re-captcha class="m-auto align-content-center my-3" formControlName ="recaptchaReactive" siteKey="{{recaptcha_KEY}}"></re-captcha>
                        </div>

                        <!-- BOTONES SIN RECPATCHA -->
                        <div class="d-flex justify-content-between mb-2 mt-2" *ngIf="showFormPrestamo">
                            <button mdbBtn [disabled]="formPrestamo.status!='VALID'" type="submit" block="true" class="btn-continuar text-white d-block waves-light mb-0" mdbWavesEffect>
                                <span class="h6 mx-2">Solicitar Préstamo</span>
                            </button>
                        </div>
        
                        <div class="d-flex justify-content-between mb-2 mt-2" *ngIf="showFormContacto">
                            <button mdbBtn [disabled]="formPrestamo.status!='VALID'" type="submit" block="true" class="btn-continuar text-white d-block waves-light mb-0" mdbWavesEffect>
                                <span class="h6 mx-2">Contactarme</span>
                            </button>
                        </div>
                              
                    </form>
        
                </div>

                <!-- PASO 2 -->
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2"  *ngIf="paso==2">
                    
                    <!-- VALID -->
                    <span *ngIf="valid==true">
                        <h4 class="text-center mb-4 mt-4 py-1 titulo-primary">¡Tenés un préstamo disponible!</h4>
                        <br>
                        <div class="row mb-1 ">
                            <div class="col-6">
                                <h4><b>Monto:</b></h4>
                            </div>
                            <div class="col-6 text-right">
                                <h4>${{importeElegido | number: '.2'}} </h4>
                                <p><a (click)="paso=1" style="text-decoration: underline;">Elegir otro monto</a></p>
                                
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-12 d-flex">
                                <h4><b>Cuotas:</b></h4>
                                <div class="md-form md-outline whiteness m-0" style=" width: 60% !important;position: absolute;right: 15px;bottom: 1px;">
                                    <mdb-select [outline]="true" (ngModelChange)="selectCuotas($event)" [options]="opcionesCuotas" label="Cantidad de cuotas *" [(ngModel)]="cantCuotas"></mdb-select>
                                </div>
                            </div>
                        </div>

                        <span *ngIf="simulacionConfig==null">
                            <div class="row my-3">
                                <div class="col-12 text-center">
                                    <b><p>Seleccioná la cantidad de cuotas en las que querés pagar</p></b>
                                </div>
                            </div>
                            
                        </span>
                        <span *ngIf="simulacionConfig!=null">
                            
                            <div class="row my-3">
                                <div class="col-12 d-flex">
                                    <h4><b>Monto de cada cuota:</b></h4>
                                    <h4 class="ml-auto mt-auto">${{simulacionConfig.promedioCuota | number: '.2'}}</h4>
                                </div>
                            </div>
                            <div class="row my-3">
                                
                                <div class="col-12 d-flex">
                                    <h4><b>Fecha 1er Vencimiento:</b></h4>
                                    <h4 class="ml-auto mt-auto">{{simulacionConfig.primerVencimiento | date: 'dd/MM/yyyy'}}</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 my-3 text-center" style="color: #aaa;font-size: 12px;font-weight: 100;">
                                    <p class="color-primary m-0">El importe de las cuotas y la fecha de vencimiento es estimada. Los valores finales serán determinados a la hora de validar el préstamo. Cualquier variación será informada por el operador antes de finalizar el proceso de validación.
                                    Monto total a pagar: ${{simulacionConfig.total | number: '.2'}}.
                                    CFT: {{cft | number: ".2"}}%. TEM: {{tem | number: ".2"}}%</p>
                                </div>
                            </div>

                            <button mdbBtn type="button" (click)="confirmarPrestamo()" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                                <span class="h6 mx-2">¡Quiero este préstamo!</span>
                            </button>
                        </span>
                    </span>

                    <!-- NO VALID -->
                    <span *ngIf="valid==false">

                        <!-- ERROR == PRE0001L -->
                        <span *ngIf="error=='PRE0001L'">
                            <!-- <p class="font-size-085 text-center">Ya tenés una cuenta en BestCard</p> -->
                            <h4 class="text-center mb-4 mt-4 py-1 titulo-primary">¡Ya tenés una cuenta en BestCard!</h4>
                            <p class="font-size-085 text-center">Inicia sesión con este mail y solicitá tu préstamo desde ahí</p>
                            <a mdbBtn type="button" href="https://billetera.bestcard.com.ar" target="_blank" block="true" class="d-block btn-continuar text-white waves-light mb-0" mdbWavesEffect>
                                <span class="h6 mx-2">Ir a la billetera</span>
                            </a>
                        </span>
                        <!-- ERROR == PRE0002L -->
                        <span *ngIf="error=='PRE0002L'">
                            <h4 class="text-center mb-4 mt-4 py-1 titulo-primary">¡Ya tenés una cuenta en BestCard!</h4>
                            <!-- <p class="font-size-085 text-center">Ya tenés una cuenta en BestCard, </p> -->
                            <p class="font-size-085 text-center">Pero con otro email, tu email es: {{emailExistente}}</p>
                            <a mdbBtn type="button" href="https://billetera.bestcard.com.ar" target="_blank" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                                <span class="h6 mx-2">Ir a la billetera</span>
                            </a>
                        </span>

                        <!-- ERROR == PRE0000X -->
                        <span *ngIf="error=='PRE0000X'">
                            <h4 class="text-center">Ocurrió un error inesperado al consultar la disponibilidad del préstamo. Reintentá nuevamente.</h4>
                        </span>
                        <!-- ERROR == PRE0003L,PRE0004L,PRE0006L -->
                        <span *ngIf="error=='PRE0003L' || error=='PRE0004L' || error=='PRE0006L'">
                            <h4 class="text-center my-4">Este email no está disponible para solicitar un préstamo</h4>
                        </span>
                        <!-- ERROR == PRE0005L,PRE0007L -->
                        <span *ngIf="error=='PRE0005L' || error=='PRE0007L'">
                            <h4 class="text-center">En este momento no podés solicitar un préstamo. Contactá a soporte para tener mas información</h4>
                        </span>
                        <!-- ERROR == PRE0008L -->
                        <span *ngIf="error=='PRE0008L'">
                            <h4 class="text-center">En este momento no tenemos un préstamo disponible para vos</h4>
                        </span>

                        <!-- BOTON DE AYUDA -->
                        <hr class="w-100">
                        <p class="font-size-085 text-center">¿Necesitas ayuda? <a class="font-weight-bold text-secondary" style="text-decoration: underline;" (click)="consultaSoporteConCodigo()">Comunicate con soporte</a></p>
        

                    </span>

                            
                </div>

                <!-- PASO 3 -->
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2"  *ngIf="paso==3">
                    <!-- <p class="font-size-085 text-center">¡Tu solicitud de préstamo fue pre-aprobada!</p> -->
                    <h4 class="text-center mb-4 mt-4 py-1 titulo-primary">¡Tu solicitud de préstamo fue pre-aprobada!</h4>
                    <p class="font-size-085 text-center">Para terminar de aprobarlo necesitamos que te registrés en la billetera y te acerqués a una de nuestras sucursales con la siguiente documentación:</p>

                    <span *ngFor="let item of documentacion; index as i">
                        <div class="row">
                            <div class="col-12 my-2 d-flex">
                                <h4 class="h4-item-list-number my-2 px-2 py-1" >{{i+1}}.</h4>
                                <h4 class="h4-item-list-text my-2 py-1 ml-4 pl-4 pr-2 w-100">{{item}}</h4>
                            </div>
                            
                        </div>
                    </span>
                    <!-- <h4 class="h4-item-list my-2" ><span class="h4-item-list-number px-2 py-1">{{i+1}}.</span> <span class="h4-item-list-text px-2 py-1">{{item}}</span></h4> -->
                    <hr class="w-100">
                    <button mdbBtn type="button" (click)="registro()" block="true" class="d-block btn btn-continuar waves-light mb-0" mdbWavesEffect>
                        
                        <span class="h6 mx-2">Registrarme Ahora</span>
                    </button>

          
                  </div>

                  <!-- PASO 10 - SOLICITUD CONTACTO -->
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2"  *ngIf="paso==10">
                    
                    <p class="font-size-085 text-center my-4">¡Nos pondremos en contacto con vos en cuanto haya novedades!</p>
                    
                  </div>


            </div>
        </div>

        <div class="col-12 col-sm-6 mx-auto" *ngIf="testError==true">
            <h4 class="text-center mb-4 mt-4 py-1 font-weight-bold text-white">Acceso Denegado</h4>
        </div>
        
        <div class="col-12 col-sm-6 mx-auto mt-4 ">
            <div class="row h-100 m-0 p-0">
                <div class="col-12 d-flex flex-wrap align-items-center">
                    <img src="../../assets/home/logo-white.png" width="30%" class="mx-auto">
                </div>
            </div>
        </div>
    </div>
    
    <!-- ESTO DEBE IR DENTRO DEL LAYOUT PARA NO OCUPAR ESPACIO ENLA PANTALLA -->
    <mdb-date-picker name="mydate" class="invisible-item" #datePicker [options]="myDatePickerOptions" [placeholder]="'Selected date'" [(ngModel)]="fechaNac" (ngModelChange)="detectDateChange($event)"
    required></mdb-date-picker>
</div>

<app-footer-general></app-footer-general>
