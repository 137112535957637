import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() items: any
  @Input() iconUrl: string = '../assets/home/logo-white.png'
  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  goMenu(_id) {
    window.open('/#'+_id, '_self');
  }

  isHome()
  {
    if(this.router.url==='/') return true
    else return false;
  }
}
