<app-navbar></app-navbar>
<div class="layout-local mt-5">
    
    <div class="container row flex-column py-4 px-0 px-sm-2 ">
        <div class="col-12 mx-auto" *ngIf="testError==false">
            <div class="m-0 p-0">
                <div class="col-12 mx-auto d-flex flex-column">
                    <div class="col-10 col-sm-6 p-2 titulo m-0 ml-4 d-flex">
                        <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate">
                            Simulá tu préstamo
                        </span>
                        <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt="">
                        <!-- <i class="fas fa-hand-holding-usd"></i> -->
                    </div>
                </div>

                
                <!-- PASO 1 -->
                <div class="col-12 mx-auto card-data d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="paso==1">
                    <div class="row d-flex justify-content-center justify-content-sm-start mb-5 mt-3">
                        <div class="col-10 col-sm-5 rounded-pill mx-sm-5 px-0 d-flex align-items-center" style="height:3.5rem; width:40%; background-color:#868791; padding:10px;">
                            <div class="circulo"></div>
                            <span style="font-size:1.2rem; margin-left:2rem;" class="text-white text-uppercase">Importe</span>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-12 col-sm-10 mx-auto col-slider px-4">
                            <h4 class="text-center text-gray mb-0 pt-3">${{rangeImporte | number: ".2"}}</h4>
                            <div class="custom-slider pb-2">
                                <ngx-slider [(value)]="rangeImporte" (userChange)="rangeChange()" [options]="optionsRangeImporte"></ngx-slider>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-10 mx-auto col-slider-2 px-4">
                            <p class="text-center text-detalle mb-0">(desde ${{importeMinimo}} hasta ${{importeMaximo}})</p>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-center justify-content-sm-start mb-5 mt-5">
                        <div class="col-10 col-sm-5 rounded-pill mx-sm-5 px-0 d-flex align-items-center" style="height:3.5rem; width:40%; background-color:#868791; padding:10px;">
                            <div class="circulo"></div>
                            <span style="font-size:1.2rem; margin-left:2rem;" class="text-white text-uppercase">Cuotas</span>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-12 col-sm-10 mx-auto col-slider px-4">
                            <h4 class="text-center text-gray mb-0 pt-3">{{rangeCuotas}}</h4>
                            <div class="custom-slider pb-2">
                                <ngx-slider [(value)]="rangeCuotas" (userChange)="rangeChange()" [options]="optionsRangeCuotas"></ngx-slider>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-10 mx-auto col-slider-2 px-4">
                            <p class="text-center text-detalle mb-0">(entre {{cuotasMinimas}} y {{cuotasMaximas}} cuotas)</p>
                        </div>
                    </div>
                    <!-- <p class="text-center"></p>
                    <div class="custom-slider">
                        <ngx-slider [(value)]="rangeCuotas" (userChange)="rangeChange()" [options]="optionsRangeCuotas"></ngx-slider>
                    </div> -->

                    <h4 class="text-center text-gray pt-3" *ngIf="!simulando && !mostrarResultado && error==null">&nbsp;</h4>
                    <h4 class="text-center text-gray pt-3" *ngIf="simulando">Simulando...</h4>
                    <h4 class="text-center text-primary pt-3" *ngIf="mostrarResultado">Pagás ${{valorCuota | number: "1.2-2"}} cada mes</h4>
                    
                    <h4 class="text-center text-danger pt-3" *ngIf="error!=null">{{error}}</h4>
                    <div class="row">
                        <div class="d-flex justify-content-between mb-2 mt-2 mx-auto">
                            <button mdbBtn [disabled]="mostrarResultado!=true" type="button" block="true" class="d-block waves-light mb-0 text-white" style="background-color:#760d7a;" mdbWavesEffect (click)="solicitarPrestamo()">
                                Quiero este préstamo
                            </button>
                        </div>
                    </div>
                    <!-- <label for="customRange2" class="form-label">Example range</label>
                    <input type="range" class="form-range custom-range" min="0" max="5" id="customRange2"> -->
                  

                </div>
                


            </div>
        </div>

        <div class="col-12 col-sm-6 mx-auto" *ngIf="testError==true">
            <h4 class="text-center mb-4 mt-4 py-1 font-weight-bold text-white">Acceso Denegado</h4>
        </div>

        <div class="col-12 col-sm-6 mx-auto mt-4 ">
            <div class="row h-100 m-0 p-0">
                <div class="col-12 d-flex flex-wrap align-items-center">
                    <img src="../../assets/home/logobc.png" width="30%" class="mx-auto">
                </div>
            </div>
        </div>
    </div>
    
</div>
<app-footer-general></app-footer-general>