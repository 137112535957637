import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-pink-bg',
  templateUrl: './btn-pink-bg.component.html',
  styleUrls: ['./btn-pink-bg.component.scss']
})
export class BtnPinkBgComponent implements OnInit {

  @Input() text: string;
  @Input() icon: string;
  @Input() bg: boolean;
  btn_bg: boolean;

  constructor() { }

  ngOnInit(): void {
    if (this.bg) {
      this.btn_bg = false
    }
    else{
      this.btn_bg = true
    }
  }

}
