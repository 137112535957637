import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { IMyOptions } from 'ng-uikit-pro-standard';


@Component({
    selector: 'app-boton-arrepentimiento',
    templateUrl: './boton-arrepentimiento.component.html',
    styleUrls: ['./boton-arrepentimiento.component.scss']
})
export class BotonArrepentimientoComponent implements OnInit {

    constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute,) {

    }

    opcionesProductos: any = [
        { value: 'billetera', label: 'Billetera BestCard' },
    ];


    paso: any =1
    prerating: any = 0
    formBoton: any = new FormGroup({
        nombre: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        dni: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
        celular: new FormControl(null, [Validators.required]),
        // celular: new FormControl(null, [Validators.required, Validators.pattern(this.ds.expRegEmail)]),
        fechaNac: new FormControl(null, [Validators.required]),
        producto: new FormControl('billetera', [Validators.required]),
        motivos: new FormArray([]),
        otroMotivo: new FormControl(null, [Validators.maxLength(100)]),
        rating: new FormControl(null),
    }, { updateOn: 'blur' });


    ngOnInit(): void {
        // this.valForm.patchValue({
        //   telefono: this.datos_user.c_telefono.c_celular
        // });

    }

    get form_celular() { return this.formBoton.get('celular'); }
    get form_nombre() { return this.formBoton.get('nombre'); }
    get form_dni() { return this.formBoton.get('dni'); }
    get form_fechaNac() { return this.formBoton.get('fechaNac'); }
    get form_motivos() { return this.formBoton.get('motivos') }
    get form_otroMotivo() { return this.formBoton.get('otroMotivo') }
    get form_rating() { return this.formBoton.get('rating') }

    submitForm() {

        console.log('this.formBoton.value');
        console.log(this.formBoton.value);

        
        // if(this.formBoton.)
        // console.log(this.formPaso1.status);
        if (this.formBoton.status == 'VALID') {

            if(this.form_motivos.value.length==0 && (this.form_otroMotivo.value==null || this.form_otroMotivo.value==""))
            {
                return this.ds.generateErrorAlert('Seleccioná los motivos por los cuales solicitas la baja');
            }
            else if(this.form_rating.value == null)
            {
                return this.ds.generateErrorAlert('Seleccioná tu grado de satisfacción con el producto');
            }

            this.ds.generateWaitingAlert()
            this.ds.httpPostNoToken_V2(2,"landing/boton/arrepentimiento",this.formBoton.value).subscribe((res:any) => {
                this.ds.closeWaitingAlert()
                this.ds.loader = false;
                if(res && res.r && res.r.c==0)
                {
                    this.paso = 2
                }
                else if (res && res.r && res.r.c==1) this.ds.generateErrorAlert(res.r.m)
                else this.ds.generateErrorAlert('Ocurrió un error inesperado al procesar la solicitud')
            });

            
        } else {
            this.ds.generateErrorAlert('Completá correctamente todos los campos obligatorios (*)');
        }
    }

    rating(value)
    {
        this.formBoton.patchValue({'rating':value})
    }

    onCheckboxChange(event: any) {
        const selectedChkbox = (this.formBoton.controls.motivos as FormArray);
        if (event.target.checked) {
            selectedChkbox.push(new FormControl(event.target.value));
        } else {
            const index = selectedChkbox.controls
                .findIndex(x => x.value === event.target.value);
            selectedChkbox.removeAt(index);
        }
    }

    @ViewChild('datePicker') datePicker: any;

    fechaNac: any;
    public myDatePickerOptions: IMyOptions = {
      dayLabelsFull: { su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miércoles', th: 'Jueves', fr: 'Viernes', sa: 'Sábado' },
      dayLabels: { su: 'Do', mo: 'Lu', tu: 'Ma', we: 'Mi', th: 'Ju', fr: 'Vi', sa: 'Sa' },
      monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
      monthLabelsFull: { 1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre' },
      dateFormat: 'yyyy-mm-dd',
      todayBtnTxt: 'Hoy',
      firstDayOfWeek: 'mo',
      clearBtnTxt: 'Limpiar',
      closeBtnTxt: 'Hecho',
      showTodayBtn: true,
      showClearDateBtn: true,
      markCurrentDay: true,
      minYear: 1900,
      maxYear: this.ds.datePickerRegisterLastYear,
      disableSince: this.ds.datePickerRegisterDisabled,
    };

    openDateTime() {
        this.datePicker.openBtnClicked();
      }

      detectDateChange(val) {
        this.formBoton.get('fechaNac').setValue(val);
      }
}
