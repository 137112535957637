
<div class="layout-local">
    <div class="container row flex-column py-4 px-0 px-sm-2 ">
        <div class="col-12 col-sm-12 mx-auto">
            <div class="m-0 p-0">
                <div class="col-12 mx-auto d-flex flex-column">
                    <div class="col-10 col-sm-8 p-2 titulo m-0 ml-4 d-flex">
                        <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate">
                            Informe de Pago
                        </span>
                        <!-- <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt=""> -->
                        <!-- <i class="fas fa-hand-holding-usd"></i> -->
                    </div>
                </div>

                
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="detallePrestamo!=null">
                    <h2 class="text-left my-2">Crédito {{detallePrestamo.numeroPrestamo}} </h2>
                    <hr class="w-100">
                    <span *ngIf="detallePago!=null">
                        <h4 class="text-center py-3 m-0">Detalle del Pago</h4>
                        <h5 *ngIf="detallePago.parcial==false"><b>La cuota {{detallePago.numeroCuota}} fue pagada correctamente<i class="fas fa-check-circle text-success ml-2"></i></b></h5>
                        <h5 *ngIf="detallePago.parcial==true"><b>El pago fue realizado correctamente<i class="fas fa-check-circle text-success ml-2"></i></b></h5>
                        <h5><b>Fecha del Pago:</b> {{detallePago.fechaPago | date: "HH:mm dd/MM/yyyy"}}</h5>
                        <h5><b>Importe:</b> ${{detallePago.importe | number: ".2"}}</h5>
                        
                            <button mdbBtn type="button" color="primary" class="d-block waves-light my-3 mx-auto" mdbWavesEffect (click)="descargarComprobante()">
                                <i class="fas fa-file-alt mr-2"></i> Descargar Comprobante
                            </button>
                            <hr class="w-100">
                    </span>
                    <span *ngIf="detallePrestamo!=null">
                        <h4 class="text-center py-3 m-0">Detalle del Crédito</h4>
                    
                        <span *ngIf="detallePrestamo.estado==20">
                            <h5>Restan pagar <b>{{detallePrestamo.cuotasImpagas}}</b> {{(detallePrestamo.cantCuotas==1)? 'cuota': 'cuotas'}} por un total de <b>${{detallePrestamo.importeRestante | number: ".2"}}</b></h5>
                            <h5 *ngIf="detallePrestamo.cuotaSig!=null && detallePrestamo.cuotaSig.vencida!=true">La próxima cuota vence el día <b>{{detallePrestamo.cuotaSig.vto | date: "dd/MM/yyyy"}}</b></h5>
                            <h5 *ngIf="detallePrestamo.cuotaSig!=null && detallePrestamo.cuotaSig.vencida==true">La próxima cuota ha vencido el día <b>{{detallePrestamo.cuotaSig.vto | date: "dd/MM/yyyy"}}</b></h5>
                        
                        </span>
                        <span *ngIf="detallePrestamo.estado==30 || detallePrestamo.estado==31">
                            <h5><b>El crédito ha sido cancelado</b></h5>
                        </span>
                        
                    </span>
                    <hr class="w-100">
                    <button mdbBtn type="button" color="secondary" class="d-block waves-light my-3 mx-auto" mdbWavesEffect (click)="realizarOtroPago()">
                        Realizar otro pago
                    </button>
                    
                    <!-- <label for="customRange2" class="form-label">Example range</label>
                    <input type="range" class="form-range custom-range" min="0" max="5" id="customRange2"> -->
                  

                </div>
                
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="detallePago==null && paso==2">
                    
                    <h4 class="text-center text-dark py-3 mt-3 m-0">{{msgError}}</h4>
                    <span class="text-center" *ngIf="enProceso==true">
                        <hr>
                        <h5>Actualizá la página en algunos minutos para ver el informe.</h5>
                        <h5><a (click)="buscarInforme()" class="btn btn-md btn-primary text-white">Actualizar</a></h5>
                        <h5>ó</h5>
                        <h5>Consultá a soporte enviando el siguiente código:</h5>
                        <h5><b>{{hash}}</b></h5>
                        <h5><a (click)="consultarSoporte()" class="btn btn-md btn-primary text-white">Consultar</a></h5>
                    </span>
                    <hr class="w-100">
                    <button mdbBtn type="button" color="secondary" class="d-block waves-light my-3 mx-auto" mdbWavesEffect (click)="realizarOtroPago()">
                        Realizar otro pago
                    </button>
                </div>
                
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2" *ngIf="detallePago==null && paso==1">
                    
                    <h4 class="text-center text-dark py-3 my-3 m-0">Procesando solicitud. Por favor, espere...</h4>
                </div>


            </div>
        </div>

        <div class="col-12 col-sm-6 mx-auto mt-4 ">
            <div class="row h-100 m-0 p-0">
                <div class="col-12 d-flex flex-wrap align-items-center">
                    <img src="../../assets/home/logo-white.png" width="30%" class="mx-auto">
                </div>
            </div>
        </div>
    </div>
    
</div>
