<div class="layout-1" id="referidos">
    <div class="container py-4">
        <h2 class="white-text text-display-2 mb-3">¡Unite con tus amigos y ganá!</h2>
        <h1 class="white-text text-display-1 mb-4">Sumá a la BestComunidad</h1>
        <div class="row">
            <div class="col-lg-8">
                <p class="white-text text-display-description">Les regalamos $150 a vos y a tu amigo en sus billeteras virtuales con tan solo tu registro ¿No conoces BestCard?:</p>
                <p class="white-text text-display-description">
                    <i class="fas fa-check primary-text mr-1"></i>
                    Hacé compras en cualquier comercio o pagina web
                </p>
                <p class="white-text text-display-description">
                    <i class="fas fa-check primary-text mr-1"></i>
                    Pagá tus facturas, recarga tus servicios
                    prepagos y cargá la SUBE
                </p>
                <p class="white-text text-display-description">
                    <i class="fas fa-check primary-text mr-1"></i>
                    Sacá plata de cualquier cajero de
                        la Red Link o Banelco con tu tarjeta
                </p>
                <p class="white-text text-display-description">
                    <i class="fas fa-check primary-text mr-1"></i>
                    Transferí dinero a cualquier cuenta
                </p>
                <p class="white-text text-display-description">
                    <i class="fas fa-check primary-text mr-1"></i>
                    Utilizala en cualquier parte del mundo
                </p>
                <p class="white-text text-display-description font-weight-bold">¿Te interesa, no?</p>

                <div class="d-flex align-items-center justify-content-center mt-5 mr-4">
                    <div class="d-flex align-items-center">
                        <button mdbBtn type="submit" color="primary" routerLink="/unirse" size="lg" class="d-block waves-light my-0 mr-3" mdbWavesEffect>
                            Sumáte ahora
                        </button>
                        <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                        <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                        <i class="fas fa-caret-right primary-text fa-3x mx-2"></i>
                    </div>
                    <a href="https://bestcard-production.s3.us-east-2.amazonaws.com/bestcard/assets/tyc/tyc_referidos.pdf" class="white-text text-display-description pl-4 ml-4 my-auto" style="font-size: 16px;">
                        <u>Términos y Condiciones</u>
                    </a>

                </div>


            </div>
            <div class="col-lg-4 px-0 d-none d-lg-block" style="position: unset !important;">
                <img src="../../assets/home/chicos.png" class="referidos-img">
            </div>
        </div>


    </div>
</div>