<!-- <div class="row d-flex justify-content-center mt-7">
    <div class="col-md-4">
        <img class="img-thumbnail" src="/assets/placeholder/undraw_Well_done_re_3hpo.png" alt="img">
    </div>
</div> -->



<div class="layout-1">
    <div class="container row">
        <div class="col-sm-7 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">

            <div class="row d-flex justify-content-center mb-5">
                <div class="col">
                    <h1 class="text-center" style="color:#b5117a">Finalizaste la validación</h1>
                    <hr>
                    <span *ngIf="statusSession!=null">
                        <div [ngSwitch]="statusSession">
                            <h3 class="text-center" *ngSwitchCase="'VERIFIED'">Estado de la validación: <span class="badge badge-success">VALIDADO</span></h3>
                            <!-- <h3 class="text-center" *ngSwitchCase="'CREATED'">Estado de la validación: <span class="badge badge-primary">CREADO</span></h3> -->
                            <h3 class="text-center" *ngSwitchCase="'CREATED'">Estado de la validación: <span class="badge badge-primary">EN PROGRESO</span></h3>
                            <h3 class="text-center" *ngSwitchCase="'IN_PROGRESS'">Estado de la validación: <span class="badge badge-primary">EN PROGRESO</span></h3>
                            <h3 class="text-center" *ngSwitchCase="'PROCESSING'">Estado de la validación: <span class="badge badge-warning">PROCESANDO</span></h3>
                            <!-- <h3 class="text-center" *ngSwitchCase="'MANUAL_REVIEW'">Estado de la validación: <span class="badge badge-warning">REVISION MANUAL</span></h3> -->
                            <h3 class="text-center" *ngSwitchCase="'MANUAL_REVIEW'">Estado de la validación: <span class="badge badge-warning">PROCESANDO</span></h3>
                            <h3 class="text-center" *ngSwitchCase="'CANCELLED'">Estado de la validación: <span class="badge badge-danger">CANCELADA</span></h3>
                            <h3 class="text-center" *ngSwitchCase="'REJECTED'">Estado de la validación: <span class="badge badge-danger">RECHAZADA</span></h3>
                            <h3 class="text-center" *ngSwitchCase="'NOT_VALIDATED'">Estado de la validación: <span class="badge badge-danger">NO VALIDADO</span></h3>
                        </div>
                        <span *ngIf="statusSession=='VERIFIED'">
                            <span *ngIf="userRegistrado && plataforma == 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">¡Felicidades! Tu identidad fue verificada.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Vamos a comprobar tus datos y si todo es correcto, te habilitaremos tu cuenta VISA.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Ya puedes volver a la billetera</h4>
                            </span>
                            <span *ngIf="userRegistrado && plataforma != 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">¡Felicidades! Tu identidad fue verificada.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Vamos a comprobar tus datos y si todo es correcto, te habilitaremos tu cuenta VISA.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Ya puedes volver a la app</h4>
                            </span>
                            <h4 *ngIf="userRegistrado == false && plataforma == 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la billetera web para continuar</h4>
                            <h4 *ngIf="userRegistrado == false && plataforma != 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la app para continuar</h4>
                        </span>
                        <span *ngIf="statusSession=='CANCELLED' || statusSession=='REJECTED' || statusSession=='NOT_VALIDATED'">
                            <span *ngIf="userRegistrado && plataforma == 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">No pudimos validar tu identidad.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Vuelve a la billetera para reintentarlo</h4>
                            </span>
                            <span *ngIf="userRegistrado && plataforma != 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">No pudimos validar tu identidad.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Vuelve a la app para reintentarlo</h4>
                            </span>
                            
                            <h4 *ngIf="userRegistrado == false && plataforma == 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la billetera web para continuar</h4>
                            <h4 *ngIf="userRegistrado == false && plataforma != 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la app para continuar</h4>
                        </span>
                        <span *ngIf="statusSession=='CREATED' || statusSession=='IN_PROGRESS' || statusSession=='PROCESSING' || statusSession=='MANUAL_REVIEW'">
                            <span *ngIf="userRegistrado && plataforma == 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">Estamos procesando tus datos.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Ya puedes volver a la billetera</h4>
                            </span>
                            <span *ngIf="userRegistrado && plataforma != 'local'">
                                <h4  class="text-center mt-4" style="color:#6c6c6c;">Estamos procesando tus datos.</h4>
                                <h4  class="text-center mt-3" style="color:#6c6c6c;">Ya puedes volver a la app</h4>
                            </span>
                            <h4 *ngIf="userRegistrado == false && plataforma == 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la billetera web para continuar</h4>
                            <h4 *ngIf="userRegistrado == false && plataforma != 'local'" class="text-center" style="color:#6c6c6c;">Vuelve a la app para continuar</h4>
                        </span>
                        

                    </span>
                </div>
            </div>

        </div>
    </div>
</div>