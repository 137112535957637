import { NgModule } from '@angular/core';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { BlockCopyDirective } from './block-copy.directive';
import { BlockPasteDirective } from './block-paste.directive';

@NgModule({
  declarations: [TwoDigitDecimaNumberDirective, BlockCopyDirective, BlockPasteDirective],
  exports: [TwoDigitDecimaNumberDirective,BlockCopyDirective,BlockPasteDirective]
  
})
export class DirectivesModule { }
