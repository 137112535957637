<section id="sucursales" class="py-8" style="background-color: rgb(243, 243, 243);">
  <div class="container">
    <h2 class="h2-responsive font-weight-bold text-secondary mb-6 wow fadeInUp" data-wow-delay="0.25s">Nuestras
      sucursales</h2>
    <div class="wow fadeInUp">
      <div id="mapId" style="height: 500px !important; border-radius: 12px;"></div>
      <a mdbBtn style="position: relative;
        bottom: 70px;
        left: 0px;
        z-index: 9000;" floating="true" color="secondary" class="waves-light" mdbWavesEffect (click)="modalMapa.show()">
          <mdb-icon fas icon="filter" ></mdb-icon>
        </a>
    </div>
  </div>
</section>

<div mdbModal #modalMapa="mdbModal" class="modal fade" style="top: 20%;z-index: 9010; " tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #FF8B00">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalMapa.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 white-text" id="myModalLabel">Filtros</h4>
      </div>
      <div class="modal-body">
        <div class="md-form md-outline whiteness my-3">
          <input type="text" id="formNombre" class="form-control" mdbInput [(ngModel)]="query.nombre">
          <label for="formNombre">Nombre</label>
        </div>
        <!-- <div class="md-form md-outline whiteness my-3">
          <mdb-select [outline]="true" [options]="" label="Actividad Comercial" [(ngModel)]="query.actividad"></mdb-select>
        </div> -->
        <div class="md-form md-outline whiteness my-3">
          <mdb-select [outline]="true" [options]="opcionesTipos" label="Tipo de Punto" [(ngModel)]="query.tipo">
          </mdb-select>
        </div>
      </div>
      <div class="modal-footer p-2">
        <button type="button" mdbBtn color="secondary" class="relative waves-light w-100" mdbWavesEffect
          (click)="forzarBusqueda()">FILTRAR</button>
      </div>
    </div>
  </div>
</div>
