import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-formulario-error',
  templateUrl: './formulario-error.component.html',
  styleUrls: ['./formulario-error.component.scss']
})
export class FormularioErrorComponent implements OnInit {


  urlGoBack: any;
  tipo: any= null;
  cod: any= null;
  hash: any= null;

  constructor(public ds: GlobalService, private activatedRoute: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(async (params) => {
      let param= params.data;
      this.hash= param;
      this.cod= param.slice(0,2); 
     
      if(this.cod=='04') {this.tipo= 'link'; this.urlGoBack= params.data2+'/'+param;}
      if(this.cod=='03') {this.tipo= 'boton'; this.urlGoBack= param;}


    });

  }

  goBack() {

    
    this.router.navigateByUrl('/pago/'+this.urlGoBack);
  }

}
