<app-navbar></app-navbar>
<div class="layout-1" id="referidos">
    <div class="view jumbotron-2 w-100 container-banner-faq">
        <h1 class="">Preguntas Frecuentes</h1>
        <img class="cards" src="../../assets/home/frente_dorso_cruzados.svg">
    </div>
</div>

<div class="container">
    <section id="inicio" class="py-8">
        <div class="container" id="preg_frecuentes" data-wow-delay="0.25s">
            <div class="row align-items-center">
                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp" data-wow-delay="0.25s">¿Qué es BestCard?</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items1; index as i;">
                            <section id="seccion_1_preg_{{(item.id!=null)? item.id : i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_1_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_1_preg_{{(item.id!=null)? item.id : i+1}}">
                                             {{ item.title }}
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                    
                   
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">¿Cómo solicitarla?</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">

                        <span *ngFor="let item of items2; index as i;">
                            <section id="seccion_2_preg_{{i+2}}">
                                <mdb-accordion-item class="my-4" [collapsed]="!(('seccion_2_preg_'+(i+2)) == bandPreg)">
                                    <mdb-accordion-item-head>
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_2_preg_{{i+2}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                        
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">Carga tu Billetera</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items3; index as i;">
                            <section id="seccion_3_preg_{{i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_3_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_3_preg_{{i+1}}"> {{ item.title }} </a> 
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">¿Cómo puedo utilizar mi BestCard?</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items4; index as i;">
                            <section id="seccion_4_preg_{{(item.id!=null)? item.id : i+1}}">
                                <mdb-accordion-item [collapsed]="!(('seccion_4_preg_'+((item.id!=null)? item.id : i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_4_preg_{{(item.id!=null)? item.id : i+1}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s" *ngIf="false">¿Puedo solicitar préstamos?</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s" *ngIf="false">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items5; index as i;">
                            <section id="seccion_5_preg_{{i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_5_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_5_preg_{{i+1}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">¿Cómo realizo consultas?</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items6; index as i;">
                            <section id="seccion_6_preg_{{i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_6_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_6_preg_{{i+1}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">Préstamos</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of itemsPrestamos; index as i;">
                            <section id="seccion_prestamos_preg_{{i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_prestamos_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_prestamos_preg_{{i+1}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                <h4 class="h4-responsive font-weight-bold text-center mx-auto wow fadeInUp mt-4 mb-0" data-wow-delay="0.25s">BestCard Pagos</h4>
                <div class="col-lg-12 wow fadeInRight my-3" data-wow-delay="0.5s">
                    <mdb-accordion [multiple]="false" aria-multiselectable="true">
                        <span *ngFor="let item of items7; index as i;">
                            <section id="seccion_7_preg_{{i+1}}">
                                <mdb-accordion-item  [collapsed]="!(('seccion_7_preg_'+(i+1)) == bandPreg)" class="my-4">
                                    <mdb-accordion-item-head> 
                                        <a class="d-block .not-active" href="/preguntas_frecuentes#seccion_7_preg_{{i+1}}">
                                            {{ item.title }} 
                                        </a>
                                    </mdb-accordion-item-head>
                                    <mdb-accordion-item-body style="white-space: pre-wrap;"><div [innerHTML]='item.description'></div></mdb-accordion-item-body>
                                </mdb-accordion-item>
                            </section>
                        </span>
                    </mdb-accordion>
                </div>

                
            </div>
        </div>
    </section>
</div>

<app-footer-general></app-footer-general>