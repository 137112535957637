<app-navbar></app-navbar>
<div class="layout-1">
    <div class="container row">
        <div class="col-sm-5 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
          <p class="font-size-085 text-center">¿Tenés alguna consulta? ¡Completá el formulario y consultanos por Whatsapp!</p>
            <form [formGroup]="formConsulta" class="d-flex flex-column h-100" (ngSubmit)="generarLink()">
                <div class="row">
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="text" id="formNombre" class="form-control" formControlName="nombre" mdbInput>
                            <label for="formNombre">Tu Nombre*</label>
                            <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched && form_nombre.errors?.required">Escribí tu nombre</mdb-error>
                            <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched && form_nombre.errors?.maxlength">Nombre demasiado largo</mdb-error>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="text" id="formApellido" class="form-control" formControlName="apellido" mdbInput>
                            <label for="formApellido">Tu Apellido*</label>
                            <mdb-error *ngIf="form_apellido.invalid && form_apellido.touched && form_apellido.errors?.required">Escribí tu apellido</mdb-error>
                            <mdb-error *ngIf="form_apellido.invalid && form_apellido.touched && form_apellido.errors?.maxlength">Apellido demasiado largo</mdb-error>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="email" id="formEmail" class="form-control" formControlName="email" mdbInput>
                            <label for="formEmail">Email de Contacto*</label>
                            <mdb-error *ngIf="form_email.invalid && form_email.touched">Email Inválido</mdb-error>
                            <mdb-success *ngIf="form_email.valid && form_email.touched">Email Válido</mdb-success>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <textarea style="border-radius: 4px; resize: none;" value="" type="text" formControlName="cuerpo" rows="6" id="formCuerpo" class="form-control" mdbInput></textarea>
                            <label for="formCuerpo">Consulta*</label>
                            <mdb-error *ngIf="form_cuerpo.invalid && form_cuerpo.touched && form_cuerpo.errors?.required">Escribí tu consulta</mdb-error>
                            <mdb-error *ngIf="form_cuerpo.invalid && form_cuerpo.touched && form_cuerpo.errors?.maxlength">La consulta es demasiado larga</mdb-error>
                        </div>
                    </div>
                    <!-- <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="text" id="formCodInv" class="form-control" mdbInput>
                            <label for="formCodInv">Código de Invitación</label>
                        </div>
                    </div> -->
                  </div>
                <div class="d-flex justify-content-between mb-2 mt-3">
                    <button mdbBtn type="submit" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                        Consultar por Whatsapp
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-footer-general></app-footer-general>
