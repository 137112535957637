import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {Title} from "@angular/platform-browser";
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prestamos-consultas',
  templateUrl: './prestamos-consultas.component.html',
  styleUrls: ['./prestamos-consultas.component.scss']
})
export class PrestamosConsultasComponent implements OnInit {
  ref: any = null;
  showForm: any = false
  showFormPrestamo: any = false
  showFormContacto: any = false
  formSelect = new FormGroup({
      provincia: new FormControl(null, [Validators.required]),
  }, { updateOn: 'change' });


  recaptcha: any = null

  dni: any = null;
  sexo: any = null;
  paso: any = 1;
  prestamos: any = null
  titular: any = null

  test: any = false;
  testSlider: any = true
  testPass: any = null;
  testError: any = false;

  opcionesSexo = [
      { value: 2, label: "Femenino" },
      { value: 1, label: "Masculino" },
    //   { value: 0, label: "Sexo", selected: true }
  ]

  constructor(public ds: GlobalService, public router: Router, private titleService:Title,private route: ActivatedRoute,) {

    let params = this.route.snapshot.queryParams

    if(params && params.dni) this.dni = params.dni
    if(params && params.s) this.sexo = parseInt(params.s)

  }

  hideCards: any = {}

  ngOnInit(): void {}

// TODO: copiaod en componente -- borrar
	showHideCard(i) {
		if(this.hideCards[i]==true) this.hideCards[i]=false
		else this.hideCards[i]=true
	}

	consultar() {
    if(this.dni == null || this.dni=="" ) return this.ds.generateErrorAlert("Ingrese su número de documento")
    if(parseInt(this.dni) != this.dni ) return this.ds.generateErrorAlert("Número de documento no válido")
    if(this.sexo == null || this.sexo=="" ) return this.ds.generateErrorAlert("Ingrese su sexo")
    if(this.recaptcha==null || this.recaptcha=="") return this.ds.generateErrorAlert('Completa la casilla "No soy un Robot"')

    let data = {
			dni: this.dni,
			sexo: this.sexo,
			recaptcha: this.recaptcha
    }
    this.paso=1

    this.ds.generateWaitingAlert()
    this.ds.httpPostNoToken_V2(3,"prestamos/publico/api/v1/consulta/pendientes",data).subscribe((res:any) => {
			this.ds.closeWaitingAlert()
			this.recaptcha = null
			this.ds.loader = false;
			if(res && res['r'] && res['r'].c==0) {
				if(res.titular!=null) {
					this.titular = res.titular;
					Swal.fire({
						title: '¡Atención!',
						html: `¿Es <b>${this.titular}</b>?`,
						icon: 'warning',
						confirmButtonText: 'Si, soy yo',
						confirmButtonColor: '#7d0c65',
						cancelButtonText: 'No soy yo',
						showCancelButton: true,
						backdrop: `rgba(214,176,39,0.4)`,
						reverseButtons: true
					}).then((result) => {
						if (result.value == true) {
							this.loadPrestamos(res.prestamos)
							this.paso=2
						}
					});
				} else {
					this.loadPrestamos(res.prestamos)
					this.paso=2
				}
			} else if (res && res['r'] && res['r'].c==1) {
				if(res['r'].m=="Acceso Denegado") this.testError = true
				this.ds.generateErrorAlert(res['r'].m)
				// this.router.navigateByUrl("/");
			} else {
				this.ds.generateErrorAlert('Ocurrió un error inesperado al obtener la información')
				// this.router.navigateByUrl("/");
			}
    });
  }

  loadPrestamos(paramPrestamos)
  {
    this.prestamos = paramPrestamos;

    for(let i=0;i<this.prestamos.length;i++)
    {
        // this.prestamos[i].vencimiento = this.prestamos[i].cuotas[0].vencimiento // TEST
        // this.prestamos[i].importeCuotaVencimiento = 2000 // TEST
        // this.prestamos[i].numeroCuotaVencimiento = 0 // TEST

        for(let j=0;j<this.prestamos[i].cuotas.length;j++)
        {
            if(this.prestamos[i].cuotas[j].importeRestante>0) { 
                this.prestamos[i].vencimiento = this.prestamos[i].cuotas[j].vencimiento; 
                this.prestamos[i].importeCuotaVencimiento = this.prestamos[i].cuotas[j].importeRestante; 
                this.prestamos[i].numeroCuotaVencimiento = this.prestamos[i].cuotas[j].numeroCuota; 
                break; }
        }
    }
  }

  @ViewChild('captchaRef') captchaRef; //toma el modal de la plantilla
  recaptcha_KEY = environment.recaptcha_KEY;
  recaptcha_execute = false;

  detalleModalCuota: any = {}
  @ViewChild('modalCuota', {static: false}) modalCuota: ModalDirective; //toma el modal de la plantilla


  showMsgPagarCuotaAnterior()
  {
    Swal.fire({
        title: '¡Atención!',
        html: `No puede realizar el pago de esta cuota porque hay otra cuota vencida o más próxima a vencer. Por favor, realice el pago de esa cuota y vuelva a intentarlo.`,
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#7d0c65',
        backdrop: `rgba(214,176,39,0.4)`,
        reverseButtons: true
      }).then((result) => {
        if (result.value == true) {
        }
      });
  }

}
