<div mdbModal #modalCuota2="mdbModal" class="modal fade center overflow-auto" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-notify modal-primary">
        <!--Content-->
        <div class="modal-content text-center" style="background: rgb(0,0,0,0) !important;">
            <!--Header-->
            <div class="modal-header d-flex modal-head">
                <p class="heading text-white mx-auto">
                    PAGAR CUOTA
                </p>
                <p class="align-end  my-auto" type="button" (click)="modalCuota.hide();" >
                    <i class="fas fa-times-circle text-white" style="font-size: 22px;cursor: pointer;"></i>
                </p>
            </div>
            <div class="m-0 py-1 transparente">
            </div>
            <!--Inicia sesión en la billetera (con link). Si no tenés una cuenta, podés registrarte pulsando "Soy nuevo en BestCard"-->
            <div class="modal-body modal-body-customize">
                <h4>{{detalleModalCuota.title}}</h4>
                <p>Elegí el medio de pago</p>
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-4 my-0 p-0 d-flex text-right justify-content-center" *ngIf="detalleModalCuota.hab2==true">
                        <h5 class="w-100 px-2">
                            <a mdbBtn type="button" block="true" color="primary" [ngClass]="{'btn-primary text-white border-button':detalleModalCuota.medio==2,'btn-outline-primary text-dark':detalleModalCuota.medio!=2}" class="waves-light mb-0 px-3 py-1" (click)="selectMedioPago(2)">
                                <span class="font-weight-bold">Saldo BestCard</span> 
                            </a>
                            
                        </h5>
                    </div>
                    <div class="col-12 col-sm-4 my-0 p-0 d-flex text-right justify-content-center" *ngIf="detalleModalCuota.hab1==true">
                        <h5 class="w-100 px-2">
                            <a mdbBtn type="button" block="true" color="primary" [ngClass]="{'btn-primary text-white border-button':detalleModalCuota.medio==1,'btn-outline-primary text-dark':detalleModalCuota.medio!=1}" class="waves-light mb-0 px-3 py-1" (click)="selectMedioPago(1)">
                                <span class="font-weight-bold">Tarjeta de Crédito / Débito</span> 
                            </a>
                        </h5>
                    </div>
                    
                    <div class="col-12 col-sm-4 my-0 p-0 d-flex text-right justify-content-center" *ngIf="detalleModalCuota.hab3==true">
                        <h5 class="w-100 px-2">
                            <a mdbBtn type="button" block="true" color="primary" [ngClass]="{'btn-primary text-white border-button':detalleModalCuota.medio==3,'btn-outline-primary text-dark':detalleModalCuota.medio!=3}" class="waves-light mb-0 px-3 py-1" (click)="selectMedioPago(3)">
                                <span class="font-weight-bold">MercadoPago</span> 
                            </a>
                        </h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 my-0 p-0 d-flex text-right justify-content-center">
                        <p class="text-center" [ngClass]="{'text-invisible':detalleModalCuota.recargo==0 || detalleModalCuota.recargo==null}">Este medio de pago incluye un recargo del {{detalleModalCuota.recargo}}%</p>
                    </div>  
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 my-0 p-0 d-flex text-right justify-content-center">
                        <h5>
                            <a mdbBtn type="button" size="lg" [ngClass]="{'btn-secondary text-white':detalleModalCuota.importe!=null,'btn-light text-dark':detalleModalCuota.importe==null}" class="waves-light mb-0 px-3 py-1" (click)="pagar()">
                                <span class="font-weight-bold">{{(detalleModalCuota.importe==null)? 'Pagar' : 'Pagar $' + (detalleModalCuota.importe | number: '.2')}}</span> 
                            </a>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>