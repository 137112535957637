<div class="layout-local">
    <!-- En caso de error -->
    <div class="container row flex-column py-4 px-2" *ngIf="errorBand && !loading">
        <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-4 pb-2-half">
            <h5 class="text-center text-danger mb-3">{{errorMsj}}</h5>
            <hr class="w-100">
            <button mdbBtn type="button" *ngIf="permitirNuevoPago" color="success" block="true" class="d-block waves-light mb-0 m-0 mt-2 ml-auto btn-continuar text-white" (click)="nuevoPago()" mdbWavesEffect>
                <i class="fas fa-credit-card"></i>
                Realizar otro pago
            </button>
            <button mdbBtn type="button" color="success" block="true" class="d-block waves-light mb-0 m-0 mt-2 ml-auto btn-continuar text-white" routerLink="/" mdbWavesEffect>
                <i class="fas fa-arrow-left"></i>
                Ir a Home
            </button>
        </div>
    </div>

    


    <div class="container row py-4 px-2" *ngIf="!errorBand && !loading">

        <div class="col-12 col-sm-6">
            <div class="row2 m-0 p-0">
                <!-- Formulario -->
                <span *ngIf="data.procesador!=null && data.procesador!=0">
                    <div class="col-12 mx-auto d-flex flex-column">
                        <div class="col-8 p-2 titulo m-0 ml-4 d-flex">
                            <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate">{{title}}</span>
                            <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt="">
                        </div>
                    </div>

                    <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2 pt-4">

                        <!-- TEXTO INICIAL -->
                        <span class="text-secondary text-center " *ngIf="btnContinuar && mediosPago[2]==true">
    
                            <button mdbBtn type="button"  block="true" class="d-block waves-light mb-0 text-white btn-continuar" (click)="modalAlertaFormulario.show();" mdbWavesEffect>
                                <!-- <p class="font-size-085 mx-auto mb-0 " style="font-weight: 700 !important;"> -->
                                    <h4 class="h4-responsive mb-0" style="text-decoration: underline;">¡Pagar desde la billetera!</h4>
                                <!-- </p> -->
                            </button>
                            
                            <hr style="height: 1px;">
                                <h5 class="h5-responsive mb-3">O pagá con tu tarjeta:</h5>
                        </span>

                        <!-- Link de Pago -->
                        <span *ngIf="(tipoLink == '01') || (tipoLink == '02')">
                            <span class="m-0 p-0" *ngIf="btnContinuar">
                                <p class="font-size-085 text-center bold color-text h5" *ngIf="data && data.nombreEmpresa && !data.nombreCaja">Datos de la Empresa</p>
                                <p class="font-size-085 text-center bold color-text h5" *ngIf="data && data.nombreCaja">Datos de la Caja</p>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreEmpresa">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Empresa" class="form-control" value="{{data.nombreEmpresa}}" disabled mdbInput>
                                        <label for="Empresa">Empresa</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreSucursal">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Sucursal" class="form-control" value="{{data.nombreSucursal}}" disabled mdbInput>
                                        <label for="Sucursal">Sucursal</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreCaja">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Caja" class="form-control" value="{{data.nombreCaja}}" disabled mdbInput>
                                        <label for="Caja">Caja</label>
                                    </div>
                                </div>
                            </span>
    
                            <p class="font-size-085 text-center bold color-text h5 pt-5" *ngIf="btnContinuar">Complete los Siguientes Datos</p>
                            <form [formGroup]="formulario" class="d-flex flex-column h-100" (ngSubmit)="verifyForm();">
                                <div class="row" *ngIf="btnContinuar">
                                    <div class="col-12 mb-4 mt-1">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="email" id="formEmailRegistro" class="form-control" formControlName="email" [attr.disabled]="notInputsLnk" mdbInput>
                                            <label for="formEmailRegistro">Tu Email *</label>
                                            <mdb-error *ngIf="form_email.invalid && form_email.touched">Email noválido</mdb-error>
                                            <mdb-success *ngIf="form_email.valid && form_email.touched">Email Válido</mdb-success>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-4 mt-1">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="number" id="formTelefonoRegistro" class="form-control" formControlName="telefono" [attr.disabled]="notInputsLnk" mdbInput>
                                            <label for="formTelefonoRegistro">Teléfono (opcional, sin 0 ni 15)</label>
                                            <mdb-error *ngIf="form_telefono.invalid && form_telefono.touched">Teléfono no válido</mdb-error>
                                            <mdb-success *ngIf="form_telefono.valid && form_telefono.touched">Teléfono Válido</mdb-success>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-4 mt-2">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="text" id="Referencia" class="form-control" formControlName="referencia" [attr.disabled]="notInputsLnk" mdbInput>
                                            <label for="Referencia">Referencia *</label>
                                            <mdb-error *ngIf="form_referencia.errors?.required && form_referencia.touched">Ingrese un valor de Referencia</mdb-error>
                                            <mdb-error *ngIf="form_referencia.errors?.minlength && form_referencia.touched">Mínimo 3 caracteres</mdb-error>
                                            <mdb-success *ngIf="form_referencia.valid && form_referencia.touched">Referencia Válida</mdb-success>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-4 mt-2">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="number" id="importe" class="form-control no-arrows" formControlName="amount" min="0" [attr.disabled]="notInputsLnk" mdbInput appTwoDigitDecimaNumber>
                                            <label for="importe">Importe *</label>
                                            <mdb-error *ngIf="form_importe.invalid && form_importe.touched">Importe Inválido</mdb-error>
                                            <mdb-success *ngIf="form_importe.valid && form_importe.touched">Importe Válido</mdb-success>
                                        </div>
                                    </div>
    
                                    <!-- <input type="text" class="form-control" formControlName="idEmpresa" hidden>
                                    <input type="text" class="form-control" formControlName="idSucursal" hidden>
                                    <input type="text" class="form-control" formControlName="idCaja" hidden> -->
                                    
                                </div>
                                <div class="d-flex justify-content-between mb-2 mt-2" *ngIf="btnContinuar">
                                    <button mdbBtn type="button" block="true" class="d-block waves-light text-white mb-0 btn-continuar" (click)="verifyForm();" mdbWavesEffect>
                                        <span class="h6 mx-2">Continuar</span>
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                                <div class="text-center" *ngIf="!btnContinuar">
                                    <h5 class="responsive text-danger">{{msjError}}</h5>
                                </div>
                            </form>
                        </span>

                        <!-- Boton de Pago -->
                        <span *ngIf="tipoLink == '03'">

                            <span class="m-0 p-0" *ngIf="btnContinuar">
                                <p class="font-size-085 text-center bold color-text h5" *ngIf="data && data.nombreEmpresa && !data.nombreCaja">Datos de la Empresa</p>
                                <p class="font-size-085 text-center bold color-text h5" *ngIf="data && data.nombreCaja">Datos de la Caja</p>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreEmpresa">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Empresa" class="form-control" value="{{data.nombreEmpresa}}" disabled mdbInput>
                                        <label for="Empresa">Empresa</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreSucursal">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Sucursal" class="form-control" value="{{data.nombreSucursal}}" disabled mdbInput>
                                        <label for="Sucursal">Sucursal</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.nombreCaja">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Caja" class="form-control" value="{{data.nombreCaja}}" disabled mdbInput>
                                        <label for="Caja">Caja</label>
                                    </div>
                                </div>
                            </span>
    
                            <span class="m-0 p-0" *ngIf="btnContinuar">
                                <!-- <hr class="w-75"> -->
                                <p class="font-size-085 text-center bold color-text h5 pt-4">Datos del Pago</p>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.titulo">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Empresa" class="form-control" value="{{data.titulo}}" disabled mdbInput>
                                        <label for="Empresa">Titulo</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.referencia">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Sucursal" class="form-control" value="{{data.referencia}}" disabled mdbInput>
                                        <label for="Sucursal">Referencia</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.detalle">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Caja" class="form-control" value="{{data.detalle}}" disabled mdbInput>
                                        <label for="Caja">Detalle</label>
                                    </div>
                                </div>
                                <div class="col-12 my-1 px-0" *ngIf="data && data.importe">
                                    <div class="md-form md-outline whiteness m-0">
                                        <input type="text" id="Caja" class="form-control" value="{{data.importe}}" disabled mdbInput appTwoDigitDecimaNumber>
                                        <label for="Caja">Importe</label>
                                    </div>
                                </div>
                            </span>
    
                            <span class="m-0 p-0" *ngIf="btnContinuar">
                                <!-- <hr class="w-75"> -->
                                <p class="font-size-085 text-center bold color-text h5 pt-4">Fechas de Vencimiento</p>
                                <div class=" text-center mt-1" *ngFor="let item of fechaVencimiento; index as i;">
                                    <div class="alert alert-warning mb-0" *ngIf="!item.c_band">
                                        {{item.c_text}}
                                    </div>
                                    <div class="alert alert-success mb-0" *ngIf="item.c_band">
                                        {{item.c_text}}
                                    </div>
                                </div>
                            </span>
    
                            <!-- <hr class="w-75"> -->
                            <p class="font-size-085 text-center bold color-text h5 pt-4" *ngIf="btnContinuar">Complete el Siguiente Dato</p>
                            <form [formGroup]="formBoton" class="d-flex flex-column h-100" (ngSubmit)="verifyForm();">
                                <div class="row" *ngIf="btnContinuar">
                                    <div class="col-12 mb-4 mt-1">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="email" id="formBotonEmailRegistro" class="form-control" formControlName="email" [attr.disabled]="notInputsBtn" mdbInput>
                                            <label for="formBotonEmailRegistro">Tu Email *</label>
                                            <mdb-error *ngIf="formBoton_email.invalid && formBoton_email.touched">Email Inválido</mdb-error>
                                            <mdb-success *ngIf="formBoton_email.valid && formBoton_email.touched">Email Válido</mdb-success>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-4 mt-1">
                                        <div class="md-form md-outline whiteness m-0">
                                            <input type="number" id="formBotonTelefonoRegistro" class="form-control" formControlName="telefono" [attr.disabled]="notInputsLnk" mdbInput>
                                            <label for="formBotonTelefonoRegistro">Teléfono (opcional, sin 0 ni 15)</label>
                                            <mdb-error *ngIf="formBoton_telefono.value!=null && formBoton_telefono.invalid && formBoton_telefono.touched">Teléfono no válido</mdb-error>
                                            <mdb-success *ngIf="formBoton_telefono.value!=null && formBoton_telefono.valid && formBoton_telefono.touched">Teléfono Válido</mdb-success>
                                        </div>
                                    </div>
    
                                    <input type="text" class="form-control" formControlName="hash" hidden>
                                </div>
    
                                <div class="d-flex justify-content-between mb-2 mt-2" *ngIf="btnContinuar">
                                    <button mdbBtn type="button" color="success" block="true" class="d-block waves-light mb-0 btn-continuar h5" (click)="verifyFormBoton();" mdbWavesEffect>
                                        <span class="h6 mx-2">Continuar</span>
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                </div>
                                <div class="text-center" *ngIf="!btnContinuar">
                                    <h5 class="responsive text-danger">{{msjError}}</h5>
                                </div>
                            </form>
                        </span>


                    </div>
                </span>
            </div>
        </div>

        <div class="col-12 col-sm-6 mt-4 mt-sm-0">
            <div class="row h-100 m-0 p-0">
                <!-- BestCard Img-->
                <!-- <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column justify-content-center align-items-center">
                    <img src="../../assets/logo_Bestcardpagos_B2.png" alt="BestCard" class="m-4 logo-login">
                </div> -->
                <div class="col-12 d-flex flex-wrap align-items-center">
                    <img src="../../assets/home/logoBCP_blanco.svg" width="80%" class="mx-auto">
                </div>
            </div>
        </div>

        
        <!-- <div class="col-12 col-sm-6 mt-4 mt-sm-0" *ngIf="ds.build=='test'">
            <div class="row h-100 m-0 p-0">
                <div class="col-sm-12 col-md-4 mt-3 d-flex justify-content-center align-items-center flex-column">
                    <button class="waves-effect" type="button" mdbBtn color="secondary" outline="true" mdbWavesEffect (click)="generarPdf()">
                        <i class="fas fa-download  text-secondary"></i>&nbsp;&nbsp; Generar PDF
                    </button>
                </div>  
            </div>
        </div> -->
        

        
    </div>

    <div mdbModal #modalAlertaFormulario="mdbModal" class="modal fade center overflow-auto" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-notify modal-primary" role="Formulario caducado">
            <!--Content-->
            <div class="modal-content text-center" style="background: rgb(0,0,0,0) !important;">
                <!--Header-->
                <div class="modal-header d-flex modal-head">
                    <p class="heading text-white mx-auto">
                      ¡PASOS PARA PAGAR DESDE LA BILLETERA!
                      <!-- <i class="ml-2 fas fa-exclamation-triangle text-white fa-lg"></i> -->
                    </p>
                    <p class="align-end  my-auto" type="button" (click)="modalAlertaFormulario.hide();" >
                      <i class="fas fa-times-circle text-white" style="font-size: 22px;cursor: pointer;"></i>
                    </p>
                </div>
                <div class="m-0 py-1 transparente">
                </div>
                <!--Inicia sesión en la billetera (con link). Si no tenés una cuenta, podés registrarte pulsando "Soy nuevo en BestCard"-->
                <div class="modal-body modal-body-customize">
                    <ol  style="font-weight: 700 !important;" >
                        <li><p class="font-size-085 mb-0 "><a style="color: #0275d8;font-weight: 700 !important;" href="https://billetera.bestcard.com.ar/login" target="_blank">Inicia sesión en la billetera.</a> Si no tenés una cuenta, podés registrarte pulsando "Soy nuevo en BestCard"</p></li>
                        <li><p class="font-size-085 mb-0 ">En el menu lateral, seleccioná la opción "Pagar un Link de Pago"</p></li>
                        <li><p class="font-size-085 mb-0 ">Escaneá el código o ingresa el link en el campo "Link de Pago"</p></li>
                    </ol>

                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <span class="img-qr">
                                <ngx-qrcode 
                                    [elementType]="elementType" 
                                    [value]="codigoQRLink"
                                    [width]="280"
                                    [margin]="1"
                                    cssClass="aclass"
                                    errorCorrectionLevel="L">
                                </ngx-qrcode>
                            </span>
                        </div>
                        
                        <div class="col-sm-12 col-md-8 mt-3 d-flex justify-content-center align-items-center flex-column">
                            
                            <input type="email" id="formBotonEmailRegistro" class="form-control" [attr.disabled]="notInputsBtn" value="{{codigoQRLink}}" mdbInput>
                        </div>
                        <div class="col-sm-12 col-md-4 mt-3 d-flex justify-content-center align-items-center flex-column">
                            <button class="waves-effect" type="button" mdbBtn color="secondary" outline="true" mdbWavesEffect (click)="copiarPortaPapeles(codigoQRLink)">
                                <i class="fas fa-copy  text-secondary"></i>&nbsp;&nbsp; Copiar link
                            </button>
                        </div>    
                    </div>

                    <!-- <div class="justify-content-center mt-4">
                      <button class="waves-effect btn-volver" type="button" mdbBtn outline="true" (click)="modalAlertaFormulario.hide();" mdbWavesEffect>
                        Cerrar
                      </button> -->
                      <!-- <button class="waves-effect text-white btn-continuar" type="submit" mdbBtn mdbWavesEffect>
                        Ir a App
                        <i class="ml-2 fas fa-arrow-right"></i>
                      </button> -->
                    <!-- </div> -->
                </div>
            </div>
            <!--/.Content-->
        </div>
    </div>
</div>