import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-finalizar-identity',
  templateUrl: './finalizar-identity.component.html',
  styleUrls: ['./finalizar-identity.component.scss']
})
export class FinalizarIdentityComponent implements OnInit {

  session_id: string;
  statusSession: string;
  checkStatusLocal: boolean = false;
  urlCheckStatus: string = 'pomelo/check/status/session/fromLanding';
  plataforma: string;
  userRegistrado: boolean;

  constructor(private rutaActiva: ActivatedRoute, private globalService: GlobalService, private http: HttpClient) { }

  async ngOnInit() {

    this.session_id = this.rutaActiva.snapshot.params.idSession;
    await this.whereCheckStatusSession();

    if(this.session_id) {
      this.checkStatusSession()
    } else {
      this.globalService.generateErrorAlert('No se pudo obtener el id de la session')
    }
  }


  async whereCheckStatusSession() {
    return new Promise((resolve, reject) => {
      this.globalService.generateWaitingAlert("Obteniendo resultados")
      this.globalService.httpPostNoToken(2, `pomelo/check/status/session/local`, {}).then((response:any) => {
        if(response.status) {
          this.checkStatusLocal = true;
          this.urlCheckStatus = response.ngrock;
        }

        resolve(true);
      })
    })
  }

  checkStatusSession() {
    if(this.checkStatusLocal) {
      this.http.post(`${this.urlCheckStatus}`, {'session_id': this.session_id, 'checkStatusLocal': this.checkStatusLocal})
      .subscribe((response:any) => {
        this.globalService.closeWaitingAlert()
        if(response.r && response.r.c != 0) {
          return this.globalService.generateErrorAlert(response.r.m);
        }

        this.plataforma = response.plataforma;
        this.statusSession = response.status;
        this.userRegistrado = response.userRegistrado;
        
      })
    } else {
      this.globalService.httpPostNoToken(2, 'pomelo/check/status/session/fromLanding', {'session_id': this.session_id, 'checkStatusLocal': this.checkStatusLocal})
        .then((response:any) => {
          this.globalService.closeWaitingAlert()
          if(response.r && response.r.c != 0) {
            return this.globalService.generateErrorAlert(response.r.m);
          }
  
          this.plataforma = response.plataforma;
          this.statusSession = response.status;
          this.userRegistrado = response.userRegistrado;

        })
    }
  }
}
