export const environment = {
  //Version de Frontend  
  production: false, // false= modo Dev, true= modo Producción
  plataforma: "web", //posible: (web , android)
  origen: "landing", //posible: (billetera , backoffice, landing)
  version: "1.2.1", //posible: (x.x.x: version )
  versionFecha: "23/09/2022", // fecha de la ultima actualización

  URL_MS_AUTH: 'http://localhost:2000/',
  URL_MS_USUARIOS: 'http://localhost:8000/',
  URL_MS_LOGGER: 'http://localhost:5000/logger/',
  URL_MS_MAILER: 'http://localhost:9000/',
  URL_MS_TRANSFER: 'http://localhost:3000/',
  
  recaptcha_KEY: '6LdMubEaAAAAAMPD6YeWBs6QRfTZIxpmIVgx4Vet'
};

//recaptcha_KEY: sacada por seba es de desarrollo