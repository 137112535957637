const baseUrl = "../../../../assets/marca-blanca/iconos/"
export const imgs = [
    {
        id: 0,
        src: `${baseUrl}icon-angular.svg`
    },
    {
        id: 1,
        src: `${baseUrl}icon-ionic.svg`
    },
    {
        id: 2,
        src: `${baseUrl}icon-nodejs.svg`
    },
    {
        id: 3,
        src: `${baseUrl}icon-ts.svg`
    },
    {
        id: 4,
        src: `${baseUrl}icon-aws.svg`
    },
    {
        id: 5,
        src: `${baseUrl}icon-redis.svg`
    },
    {
        id: 6,
        src: `${baseUrl}icon-openai.svg`
    },
    {
        id: 7,
        src: `${baseUrl}icon-mongodb.svg`
    },
    {
        id: 8,
        src: `${baseUrl}icon-jenkins.svg`
    }
]