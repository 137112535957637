import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor( public router: Router) {
  }

  regex:boolean = false

  ngOnInit(): void {
    this.regex = new RegExp('^\/#.*').test(this.router.url)
    this.isHome();
  }

  goMenu(_id) {
    window.open('/#'+_id, '_self');
  }

  classNavbar()
  {
    if(this.isHome()) return {'navbar-dark': true}
    else return {'navbar-black': true}
  }

  isHome()
  {
    if(this.router.url==='/' || this.regex) return true
    else return false;
  }

  showBaseNavbar()
  {
    if(this.router.url==='/' || this.regex) return true
    else if (this.router.url.includes('/prestamos') && !this.router.url.includes('/prestamos/consulta')) return true
    else if (this.router.url.includes('/pagos')) return true
    else if (this.router.url.includes('/preguntas_frecuentes')) return true
    else if (this.router.url.includes('/preguntas_frecuentes')) return true
    else if (this.router.url.includes('/visa/activar')) return true
    else return false;
  }
}
