import { Component, OnInit } from '@angular/core';
import {imgs} from "./imgs"

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {
  imgs = imgs
  constructor() { }

  ngOnInit(): void {
  }

}
