import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FranquiciaService {

  infoFranquicia: any = null;

  constructor(private http: HttpClient, private ds: GlobalService) { }

  // simular http post para obtener info de franquicia
  // color principal, color secundario
  getInfoFranquiciaSinDominio(nombreFranquicia) {
    return this.http.get(`${environment.URL_MS_USUARIOS}backoffice/franquicia/v1/configuracion/landing/public/${nombreFranquicia}`)
    .pipe(
      catchError( error => {
        console.log("algo paso ☢☢", error);
        this.ds.generateErrorAlert('Error de conexion')
        return of([])
      })
    )
  }

  getInfoFranquiciaConDominio(dominio) {
    return this.http.get(`${environment.URL_MS_USUARIOS}backoffice/franquicia/v1/configuracion/landing/public/dominio/${dominio}`)
    .pipe(
      catchError( error => {
        console.log("algo paso ☢☢", error);
        this.ds.generateErrorAlert('Error de conexion')
        return of([])
      })
    )
  }
  
  getValidacionMetamap(token: string) {
    return this.http.post(`${environment.URL_MS_TRANSFER}prestamos/franquicia/api/v1/obtener/validacion/metamap`,{token})
    .pipe(
      catchError( error => {
        console.log("algo paso ☢☢", error);
        this.ds.generateErrorAlert('Error de conexion')
        return of([])
      })
    )
  }
  
}

