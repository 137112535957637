import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log("enviar",environment.production);

    const req_modificado = req.clone({
      setHeaders: {
        "Ori": environment.production ? environment.origen : 'dev_' + environment.origen,
        "Ver": environment.version,
        "Plat": environment.plataforma,
      }
    });
    // console.log("enviar mod",req_modificado);
    return next.handle(req_modificado)
    /*
    return next.handle(req_modificado).pipe(
      tap(event => {
        //se puede ver lo dato de la peticion cuando vuelve
        //console.log("evento repuesta",event);
        let datos: any = event;
        if (datos && datos.body && datos.body.r && datos.body.r.c && datos.body.r.c == 2) {
          this.ds.logOut();
        }
      }),

      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let body = event.body
          //console.log("dato body 1",body);
          if (body && body.r && body.r.c && body.r.c == 2) {
            body.r.c = 1
            if (!body.r.m) body.r.m = 'La sesión ha vencido. Volvé a iniciar sesión e intentalo nuevamente';
          }
          // console.log("dato body 2",body);
          const modEvent = event.clone({ body: body });
          return modEvent;
        }
      }),

      finalize(() => {
        //cuando finaliza la peticion
        //console.log("finalize");
      }),

      catchError((err: HttpErrorResponse) => {
        //cuando se produce un error en la petición (se puede manejar lo error 401 de la petición)
        // console.log("err",err);
        return throwError(err);
      })
    );
  */
  }
}
