<div class="hero-container">
    <div class="left-background">
        <img class="background" src="../../../../assets/marca-blanca/left-hero-background.svg" alt="">
        <div class="left-section-hero">
            <!-- titulo hero -->
            <div class="title-section-left-hero">
                <h1>ACORTÁ EL CAMINO AL ÉXITO DE TU EMPRESA, DE MESES A SEMANAS, LISTO PARA OPERAR.</h1>
                <div class="container-buttons-left-hero">
                    <a class="btn" mdbPageScroll [pageScrollOffset]="80" href="#contact"><app-btn-pink-bg  bg="false" icon="../../../../assets/marca-blanca/iconos/cell-phone.svg" text="Contáctanos"></app-btn-pink-bg></a>
                    <a class="btn" mdbPageScroll [pageScrollOffset]="100" href="#functionalities"><app-btn-pink-bg  icon="../../../../assets/marca-blanca/iconos/rocket.svg" text="Ver funcionalidades"></app-btn-pink-bg></a>
                </div>
            </div>
            <div class="cell-container">
                <img src="../../../../assets/marca-blanca/left-cell-hero.webp" alt="">
                <img src="../../../../assets/marca-blanca/right-cell-hero.webp" alt="">
            </div>
        </div>
    </div>
    <div class="right-background">

        <div class="container-cards">
            <img class="cards" src="../../../../assets/marca-blanca/cards-marca-blanca.webp" alt="">
        </div>


        <img class="background" src="../../../../assets/marca-blanca/right-hero-background.png" alt="">
        <div class="right-section-hero">
            <p>Con nuestra experiencia evitá los retrasos y obstáculos que surgen durante el proceso de implementación. Desde el diseño del producto hasta su lanzamiento, te damos soporte y proveemos mejoras en toda la vida útil del proyecto.</p>
        </div>
    </div>
</div>

<!-- MOBILE -->
<div class="hero-container-mobile">
    <div class="title-section-hero-mobile">
        <h1>ACORTÁ EL CAMINO AL ÉXITO DE TU EMPRESA, DE MESES A SEMANAS, LISTO PARA OPERAR.</h1>
        <div class="container-cards-mobile">
            <img class="cards" src="../../../../assets/marca-blanca/cards-marca-blanca.webp" alt="">
        </div>
        <img class="background" src="../../../../assets/marca-blanca/right-hero-background.png" alt="">
    </div>
    <div class="container-buttons-hero-mobile">
        <a class="btn" mdbPageScroll [pageScrollOffset]="80" href="#contact"><app-btn-pink-bg  bg="false" icon="../../../../assets/marca-blanca/iconos/cell-phone.svg" text="Contáctanos"></app-btn-pink-bg></a>
        <a class="btn" mdbPageScroll [pageScrollOffset]="100" href="#functionalities"><app-btn-pink-bg  link="#functionalities" icon="../../../../assets/marca-blanca/iconos/rocket.svg" text="Ver funcionalidades"></app-btn-pink-bg></a>
    </div>
    <div class="text-section-hero-mobile">
        <div class="bottom-background-mobile">
            <img class="background" src="../../../../assets/marca-blanca/bottom-hero-background-mobile.svg" alt="">
        </div>
        <p>Con nuestra experiencia evitá los retrasos y obstáculos que surgen durante el proceso de implementación. Desde el diseño del producto hasta su lanzamiento, te damos soporte y proveemos mejoras en toda la vida útil del proyecto.</p>
    </div>
    
</div>
