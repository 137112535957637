<app-navbar></app-navbar>
<div class="row m-0 p-0" id="row-carousel" style="background-image: url(../../assets/home/pagos-fondo-1.svg);">
    <div class="col-lg-6 d-none d-lg-flex jumbotron-content" id="col-img-1">
        <div class="mx-auto p-0">
            <img src="../../assets/home/pagos-celular-1.png" height="60%" >
        </div>
    </div>
    <div class="col-lg-6 d-flex flex-column h-100 justify-content-center">
        <div class="d-flex mx-auto mb-5 align-items-center pagos-title">
            <div class="row m-0 p-0">
                <!-- DESKTOP -->
                <div class="col-12 d-none d-lg-flex flex-wrap align-items-center">
                    <img src="../../assets/home/LOGO BC PAGOS.png" class="mx-auto">
                </div>
                <!-- MOBILE -->
                <div class="col-12 d-flex d-lg-none flex-wrap align-items-center">
                    <img src="../../assets/home/logoBCP_blanco.svg" class="mx-auto w-100">
                </div>
                
            </div>
        </div>

        <!-- DESKTOP -->
        <span class="d-none d-lg-block">
            <h1 class="h1-responsive text-center text-secondary mb-4 mt-5"><b>¡Queremos ayudarte a crecer!</b></h1>
            <h4 class="h4-responsive text-center text-subtitulo mt-2">Cobrá todas tus ventas en el acto,</h4>
            <h4 class="h4-responsive text-center text-subtitulo">fácil y seguro</h4>
        </span>
        <!-- MOBILE -->
        <span class="d-block d-lg-none">
            <h1 class="h1-responsive text-center text-white mb-4 mt-5"><b>¡Queremos ayudarte a crecer!</b></h1>
            <h4 class="h4-responsive text-center text-subtitulo text-white mt-2">Cobrá todas tus ventas en el acto,</h4>
            <h4 class="h4-responsive text-center text-subtitulo text-white">fácil y seguro</h4>
        </span>
    </div>
</div>


 
<section id="descripción" class="d-block py-4 px-3 p-sm-7">
    <div class="container">
        <div class="row wow align-items-center my-auto fadeInUp" id="row-descripcion" data-wow-delay="0.25s">
            <div class="col-lg-6 col-12 mr-auto">
                <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¡Llegó BestCard Pagos!</h1>
                <p class="text-body-lg-1 text-subtitulo text-justify">
                    Podrás cobrar con tarjetas de crédito y débito,
                    sin costos de mantenimiento, solo te
                    cobramos un pequeño porcentaje por cada
                    venta que realizas según el plazo de
                    acreditación que elijas.
                </p>
            </div>
            <div class="col-lg-6 d-none d-sm-block mt-4 mt-sm-0 position-relative">
                <img src="../../assets/home/pagos-celular-2.png" height="100%" class="img-normal">
            </div>
        </div>
    </div>

</section>

<hr>

<section id="pagos_vender" class="d-block py-4 px-3 p-sm-7">
    <div class="container h-100">
        <div class="row d-block d-sm-none" style="min-height: 40vh;">
            <div class="col-12">
                <img src="../../assets/home/03_imagen_a.png" class="img-1" width="100%">
            </div>
        </div>
        <div class="row wow h-100 align-items-center fadeInUp" data-wow-delay="0.25s">
            <!-- <div class="d-none d-sm-block col-sm-7 col-md-5 col-lg-5 mx-auto">
                <img src="../../assets/home/landing Bestcardpagos.png" class="bg-girl">
            </div> -->
            <div class="d-none d-lg-block col-lg-6 mt-4 mt-sm-0 img-collage position-relative">
                <img src="../../assets/home/03_imagen_a.png" class="img-1">
                <img src="../../assets/home/03_imagen_b.png" class="img-2">
                <img src="../../assets/home/03_imagen_c.png" class="img-3">
                <img src="../../assets/home/03_imagen_d.png" class="img-4">
                <img src="../../assets/home/03_imagen_e.png" class="img-5">
            </div>
            <div class="col-sm-5 col-md-7 col-lg-6 ml-auto">
                <div class="p-sm-2 bg-white position-relative" style="z-index: 200;">
                    <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp mx-sm-4" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¿Querés vender mejor?</h1>
                    <!-- <img class="my-3 my-sm-0 d-block d-sm-none bg-girl" src="../../assets/home/landing Bestcardpagos.png"> -->
                    <p class="text-body-lg-1 mb-0 text-subtitulo mx-sm-4 text-justify">
                        Si sos emprendedor o comercio podrás cobrar
                        con todas las tarjetas y tener acreditado tu
                        dinero en tu cuenta BestCard, sin necesidad de
                        cuenta bancaria.
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>

<hr>

<section id="pagos_sumate" class="d-block py-4 px-3 p-sm-7">
    <div class="container">
        <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>Sumate a BestCard Pagos y vendé más</h1>
        <div class="row wow h-100 align-items-center fadeInUp" data-wow-delay="0.25s">
            <!-- <div class="d-none d-sm-block col-sm-7 col-md-5 col-lg-5 mx-auto">
                <img src="../../assets/home/landing Bestcardpagos.png" class="bg-girl">
            </div> -->
            <div class="col-12 mx-auto">
                <div class="row my-2 my-sm-5">
                    <div class="col-2 d-none d-sm-block">
                    
                    </div>
                    <div class="col-12 col-sm-2 text-center mb-4 mb-sm-0">
                        <img class="my-3 my-sm-0 mx-auto d-md-block d-sm-none bg-girl" src="../../assets/home/pagos-icono-registro.svg" height="100%">
                    </div>
                    <div class="col-12 col-sm-8">
                        <h3 class="h3-responsive text-subtitulo text-justify">Registrá tu negocio gratis y fácilmente</h3>
                        <h3 class="h3-responsive text-subtitulo-2 text-justify">Hacelo sin costo con nuestro formulario online.</h3>
                    </div>
                </div>
                <div class="row my-2 my-sm-5">
                    <div class="col-2 d-none d-sm-block">
                    
                    </div>
                    <div class="col-12 col-sm-2 text-center mb-4 mb-sm-0">
                        <img class="my-3 my-sm-0 mx-auto d-md-block d-sm-none bg-girl" src="../../assets/home/pagos-icono-tarjeta.svg" height="100%">
                    </div>
                    <div class="col-12 col-sm-8">
                        <h3 class="h3-responsive text-subtitulo text-justify">Aceptá tarjetas de crédito y débito</h3>
                        <h3 class="h3-responsive text-subtitulo-2 text-justify">Millones de personas van a poder comprar lo que vendan</h3>
                    </div>
                </div>
                <div class="row my-2 my-sm-5">
                    <div class="col-2 d-none d-sm-block">
                    
                    </div>
                    <div class="col-12 col-sm-2 text-center mb-4 mb-sm-0">
                        <img class="my-3 my-sm-0 mx-auto d-md-block d-sm-none bg-girl" src="../../assets/home/pagos-icono-sumate.svg" height="100%">
                    </div>
                    <div class="col-12 col-sm-8">
                        <h3 class="h3-responsive text-subtitulo text-justify">Sumate a la red de comercios BestCard con beneficios</h3>
                        <h3 class="h3-responsive text-subtitulo-2 text-justify">Generamos beneficios para que logres más ventas y te ayudamos con la publicidad de tu negocio</h3>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

</section>

<hr>

<section id="pagos_adelantar" class="d-block py-4 px-3 p-sm-7">
    <div class="container h-100">
        <div class="row wow align-items-center fadeInUp h-100" data-wow-delay="0.25s">
            <!-- <div class="d-none d-sm-block col-sm-7 col-md-5 col-lg-5 mx-auto">
                <img src="../../assets/home/landing Bestcardpagos.png" class="bg-girl">
            </div> -->
            <div class="col-12 col-lg-6 mt-0 text-center position-relative"  style="height: 50%;">
                    <img id="pagos_adelantar_img"  height="100%" src="../../assets/home/pagos-icono-adelanto-dinero.svg" >

            </div>
            <div class="col-sm-5 col-md-7 col-lg-6 ml-auto">
                <div class="p-2 bg-white position-relative" style="z-index: 200;">
                    <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp  mx-4" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¡Adelantá solo el dinero que necesites!</h1>
                    <!-- <img class="my-3 my-sm-0 d-block d-sm-none bg-girl" src="../../assets/home/landing Bestcardpagos.png"> -->
                    <p class="text-body-lg-1 mb-0 text-subtitulo mx-4 text-justify">
                        Podés adelantar las acreditaciones por
                        día de venta con la tasa más baja 0.26%
                        ¡Los días que quieras y cuando quieras!
                    </p>
                    <p class="text-body-lg-1 mb-0 text-subtitulo mx-4">
                        ¡Tu dinero en el acto!
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>

<hr>

<section id="pagos_comisiones" class="d-block py-4 px-3 p-sm-7">
    <div class="container">
        <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>Comisiones</h1>
        <div class="row wow align-items-center fadeInUp" data-wow-delay="0.25s" >
            <!-- <div class="d-none d-sm-block col-sm-7 col-md-5 col-lg-5 mx-auto">
                <img src="../../assets/home/landing Bestcardpagos.png" class="bg-girl">
            </div> -->
            <div class="col-12 mx-auto">
                <div class="row my-0 my-sm-5" id="pagos_comisiones_row">
                        <img  id="pagos_comisiones_img" class="my-3 my-sm-0 mx-auto d-md-block d-sm-none bg-girl" src="../../assets/home/pagos-comisiones.svg" height="100%">
    
                </div>
                
            </div>
            
        </div>

    </div>

</section>

<hr>

<section id="pagos_metodo_cobro" class="d-block py-4 px-3 p-sm-7">
    <div class="container">
        <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>Elegí el método de cobro</h1>
        <div class="row wow fadeInUp" data-wow-delay="0.25s" style="height: auto;">
            <div class="col-lg-4 col-12 position-relative my-2 d-flex" style="z-index: 200;">
                <div class="box-card bg-c-2 card" style="min-height: 60vh;">
                    <h2 class="h2-responsive mb-3 text-white font-weight-bold">Con link de pago:</h2>
                    <hr class="mt-0" style="border-color: #FFF;">
                    <div class="row card-body m-0 p-0">
                        <ol class="pl-3">
                            <li class="text-white mb-2 text-justify">Generá tu link de cobro</li>
                            <li class="text-white mb-2 text-justify">Envíalo a tus clientes por mail, redes sociales o Whatsapp</li>
                            <li class="text-white mb-2 text-justify">Tu cliente detalla sus datos, el monto a pagar y los datos de su tarjeta, paga y recibe su comprobante por mail</li>
                            <li class="text-white mb-2 text-justify">Recibes el dinero en tu cuenta bestcard donde podrás seguir todos tus movimientos</li>
                        </ol>
                    </div>
                    <div class="row m-0 p-0">
                        <img class="my-3 my-sm-0 mx-auto bg-girl" src="../../assets/home/pagos-link-pago.svg" height="80px">
                    </div>
                        <div class="triangle-white"></div>
                </div>
            </div>
            <div class="col-lg-4 position-relative my-2 d-flex" style="z-index: 200;">
                <div class="box-card bg-c-2 card" style="min-height: 60vh;">
                    <h2 class="h2-responsive mb-3 text-white font-weight-bold">Código QR:</h2>
                    <hr  class="mt-0" style="border-color: #FFF;">
                    <div class="row card-body m-0 p-0">
                        <ol class="pl-3 ">
                            <!-- <li class="text-white mb-2">Ingresa el importe a cobrar</li> -->
                            <li class="text-white mb-2 text-justify">Mostrá tu código QR a tu cliente</li>
                            <li class="text-white mb-2 text-justify">Tu cliente escanea el código y paga! </li>
                            <li class="text-white mb-2 text-justify">Recibes el dinero en tu cuenta bestcard donde se registran todas tus ventas</li>
        
                        </ol>
                    </div>
                    <div class="row m-0 p-0 ">
                        <img class="my-3 my-sm-0 mx-auto  bg-girl" src="../../assets/home/pagos-qr-pago.svg" height="80px">
                    </div>
                    <div class="triangle-white"></div>
                </div>
            </div>
            <div class="col-lg-4 position-relative my-2 d-flex" style="z-index: 200;">
                <div class="box-card bg-c-2 card" style="min-height: 60vh;">
                    <h2 class="h2-responsive mb-3 text-white font-weight-bold">Botón de pago:</h2>
                    <hr  class="mt-0" style="border-color: #FFF;">
                    <div class="row card-body m-0 p-0">
                        <ol class="pl-3">
                            <!-- <li class="text-white mb-2">Ingresa el importe a cobrar</li> -->
                            <li class="text-white mb-2 text-justify">Generá tu botón de pago detallando el concepto y el monto a cobrar. ¡Puedes agregar fechas de vencimiento!</li>
                            <li class="text-white mb-2 text-justify">Envíalo a tus clientes por mail, redes sociales o Whatsapp</li>
                            <li class="text-white mb-2 text-justify">Tu cliente completa los datos de su tarjeta y paga. </li>
                            <li class="text-white mb-2 text-justify">Recibes el dinero en tu cuenta bestcard donde podrás seguir todos tus movimientos</li>
                            
                        </ol>
                    </div>
                    <div class="row m-0 p-0">
                        <img class="my-3 my-sm-0 mx-auto bg-girl" src="../../assets/home/pagos-boton-pago.svg" height="80px">
                    </div>
                    <div class="triangle-white"></div>
                </div>
            </div>
            
        </div>

    </div>
</section>

<hr>

<section id="pagos_movimientos" class="d-block py-4 px-3 p-sm-7">
    <div class="container">
        <div class="row wow align-items-center my-auto fadeInUp" id="pagos_movimientos_row" data-wow-delay="0.25s">
            <div class="col-12 col-lg-6 mt-0 position-relative" id="pagos_movimientos_col_img">
                <img id="pagos_movimientos_img" src="../../assets/home/pagos-celular-3.png">
            </div>
            <div class="col-12 col-lg-6 mr-auto">
                <h1 class="h1-responsive font-weight-bold text-secondary mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>Seguí tus ventas en el panel
                    de “Tus Movimientos”
                    en la app de BestCard
                </h1>

            </div>
        </div>
    </div>

</section>

<hr>


<section id="registra_negocio" class="position-relative py-5 wow fadeInUp" data-wow-delay="0.25s">
    <div class="container h-100">
        <div class="row align-items-center h-100">
            <div class="col-lg-5 mx-auto">
                <div class="p-2  position-relative" >
                    <h1 class="h1-responsive font-weight-bold text-center text-secondary mb-5">¡Registrá tu negocio en BestCard Pagos!</h1>
                    <button mdbBtn id="pagos_sumate_btn" color="primary" size="lg" type="button" routerLink="/emp/unirse" fragment="inicio" class="d-block waves-light mt-4 mx-auto rounded-pill" mdbWavesEffect>
                        <h2 class="h2-responsive font-weight-bold text-center mb-0">Sumáte</h2>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="section-consultas" class="d-flex">
    <div class="container my-auto">
        <div class="row">
            <div class="col-lg-5">
                <h2 class="h2-responsive font-weight-bold text-white mb-4 wow fadeInUp" data-wow-delay="0.25s">
                    Recibí atención personalizada todo el tiempo
                </h2>
                <p class="text-body-lg-1 text-white mb-0 wow fadeInUp" data-wow-delay="0.5s">
                    ¿Tenés alguna duda? No te preocupes, podés hacer la consulta que necesites
                </p>
            </div>
            <div class="col-lg-4 ml-auto">
                <!-- <div class="z-depth-1 bg-white px-4 pt-2 pb-3" style="border-radius: 8px;">
                    <div class="md-form md-outline my-3">
                        <input type="email" id="form1" class="form-control" mdbInput>
                        <label for="form1">Email</label>
                    </div>
                    <div class="md-form md-outline my-3">
                        <textarea type="text" id="form75" class="form-control md-textarea" rows="3" mdbInput></textarea>
                        <label for="form75">Consulta</label>
                    </div>
                    <button mdbBtn color="secondary" type="button" block="true" class="mx-auto d-block waves-light mb-2" mdbWavesEffect>
                        Enviar
                        <i class="fas fa-paper-plane ml-1"></i>
                    </button>
                </div> -->
                <!-- <a mdbBtn color="primary" type="button" class="mx-auto d-block waves-light mt-4" mdbWavesEffect target="_blank" href='https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quería%20hacerles%20la%20siguiente%20consulta:%20'> -->
                    <a mdbBtn color="primary" id="btn-consulta" type="button" outline="true" class="mx-auto d-block waves-light mt-4" mdbWavesEffect href='https://wa.me/5492644424998' target="_blank">
                        Consultar por Whatsapp
                        <i class="fab fa-whatsapp ml-1 fa-lg"></i>
                    </a>
            </div>
        </div>
    </div>
</section>


<section id="whatsapp" class="d-flex">
    <div class="container my-auto">
        <div class="d-flex">
            <h2 class="h2-responsive text-white">
                <i class="fab fa-whatsapp fa-lg mx-2"></i>
                <b>¿Chateamos?</b>
                Envianos un Whatsapp a +54 9 264 4424998
            </h2>
        </div>
    </div>
</section>
<app-footer-general></app-footer-general>