import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consulta-wsp',
  templateUrl: './consulta-wsp.component.html',
  styleUrls: ['./consulta-wsp.component.scss']
})
export class ConsultaWspComponent implements OnInit {
  formConsulta: any;

  constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute,) { 

  }

  

  ngOnInit(): void {
    this.formConsulta = new FormGroup({
      nombre: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      apellido: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      email: new FormControl(null, [Validators.required, Validators.maxLength(100),  Validators.pattern(this.ds.expRegEmail)]),
      cuerpo: new FormControl("Hola, equipo de BestCard, quería hacerles la siguiente consulta: ", [Validators.required, Validators.maxLength(500)])
    }, { updateOn: 'blur' }); 

    // this.valForm.patchValue({
    //   telefono: this.datos_user.c_telefono.c_celular
    // });

  }

  get form_email() { return this.formConsulta.get('email'); }
  get form_nombre() { return this.formConsulta.get('nombre'); }
  get form_apellido() { return this.formConsulta.get('apellido'); }
  get form_cuerpo() { return this.formConsulta.get('cuerpo'); }

  

  generarLink() {
    // console.log(this.formPaso1.status);
    if (this.formConsulta.status == 'VALID') {

      let parseNombre = this.formConsulta.get('nombre').value;
      parseNombre = this.replaceString(parseNombre)
      let parseApellido = this.formConsulta.get('apellido').value;
      parseApellido = this.replaceString(parseApellido)

      let parseEmail = this.formConsulta.get('email').value;
      let parseCuerpo = this.formConsulta.get('cuerpo').value;
      let parseCuerpo2 = this.replaceString(new String(parseCuerpo))

      
      let link = `https://api.whatsapp.com/send/?phone=+5492645800950&text=*Nombre*:%20${parseNombre}.%0A*Apellido*:%20${parseApellido}.%0A*Email*:%20${parseEmail}%0A%0A*Consulta*:%20${parseCuerpo2}`
      window.open(link, "_blank");
    } else {
      this.ds.generateErrorAlert('Completá correctamente todos los campos obligatorios (*)');
    }
  }


  replaceString(str)
  {
    str = str.replaceAll(" ","%20").replaceAll("#","%23").replaceAll("&","%26").replaceAll("?","%3f").replaceAll("<","%3c").replaceAll("=","%3d").replaceAll(">","%3e").replaceAll("+","%2b")

    // .replaceAll("ñ","%f1").replaceAll("Ñ","%d1")

    for (var i = 0; i < str.length; i++) {
      if(str.charCodeAt(i) == 10) str = str.substr(0, i) + "%0A" + str.substr(i+1, str.length);
    }    

    return str;
  }
}
