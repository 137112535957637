import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';

@Component({
    selector: 'app-entrada-blog',
    templateUrl: './entrada-blog.component.html',
    styleUrls: ['./entrada-blog.component.scss']
})
export class EntradaBlogComponent implements OnInit {
    data: any = {}
    idPublico: string
    preview: any = false;
    categorias = [];
    headerItems = [{ title: 'Obtener Bestcard' },
        // {title: 'Artículos destacados', location: '/'},
        // {title: 'Novedades', location: '/'},
        // {title: 'Sobre nosotros', location: '/'}
    ];
    dataLoaded: boolean
    viewInitiated: boolean
    query: any;
    constructor(private route: ActivatedRoute, public gs: GlobalService, private router: Router) {
        this.dataLoaded = false
        this.viewInitiated = false
    }

    async ngOnInit() {
        this.route.params.subscribe(async params => {
            this.idPublico = params.idPublico;
            this.route.queryParams.subscribe(async qparams => {
                this.query = qparams
                if (qparams.prev == null) {

                    await this.getEntradaBlog()
                }
                else {

                    await this.getEntradaPrevBlog(qparams.prev)
                }
                // await this.getCategorias()
                // if (!this.query.ENTRADA_64) {
                //   this.idPublico = this.router.url.replace('/', '').replace('blog', '').replace('/', '') //This deletes '/blog/:idPublic' to keeps only the idPublic param
                //   await this.getEntradaBlog()
                // } else {
                //   this.idPublico = this.router.url.replace('/', '').replace('blog', '').replace('/', '') //This deletes '/blog/:idPublic' to keeps only the idPublic param
                //   await this.getEntradaBlog()
                //   const entradaDecoded = JSON.parse(unescape(atob(this.query.ENTRADA_64)))
                //   if (entradaDecoded) {
                //     // console.log(entradaDecoded)
                //     this.data = entradaDecoded
                //     // this.patchCategoria()
                //     this.fillContent()
                //     this.dataLoaded = true
                //   }
                // }
            });
        })


    }

    // async getCategorias() {
    //   const res = await this.gs.httpPostNoToken('2', 'landing/entradas-blog/categorias/listar', {})
    //   if (res.r && res.r.c != 0) return this.gs.generateErrorAlert(res.r.m)
    //   this.categorias = [{ value: null, label: 'Sin categoría' }];
    //   const categorias = res.result.map(elem => { return { value: elem.id, label: elem.nombre }; })
    //   this.categorias = this.categorias.concat(categorias)
    // }

    async getEntradaBlog() {
        
        this.gs.generateWaitingAlert2()
        const res = await this.gs.httpPostNoToken('2', 'landing/entradas-blog/' + this.idPublico + '/detalle', {})
        this.gs.closeWaitingAlert()
        if (res.r && res.r.c != 0) {
            this.router.navigateByUrl('/blog');
            return this.gs.generateErrorAlert(res.r.m)
        }
        this.data = res.result
        // this.patchCategoria()
        this.fillContent()
        this.dataLoaded = true
    }

    async getEntradaPrevBlog(prev) {
        this.gs.generateWaitingAlert2()
        const res = await this.gs.httpPostNoToken('2', 'landing/entradas-blog/' + this.idPublico + '/detalle', {prev: prev})
        this.gs.closeWaitingAlert()
        if (res.r && res.r.c != 0) {
            this.router.navigateByUrl('/blog');
            return this.gs.generateErrorAlert(res.r.m)
        }
        this.data = res.result
        this.preview = true;
        // this.patchCategoria()
        this.fillContent()
        this.dataLoaded = true
    }

    ngAfterViewInit() {
        this.viewInitiated = true;
    }

    // patchCategoria() {
    //   this.data.c_categoria = this.categoriaToName(this.data.c_id_categoria)
    // }

    // private categoriaToName(id_categoria) {
    //   const item = this.categorias.find(item => item.value == id_categoria)
    //   return item.label
    // }

    private fillContent() {
        const content = document.getElementById('content');
        content.innerHTML = this.data.c_contenido
    }
}
