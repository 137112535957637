<app-header [items]='headerItems' [iconUrl]="'../assets/home/icono_blog.png'"></app-header>

<div class="container">
  <div class="row row-preview text-center" *ngIf="preview==true">
      <h2 class="text-center mx-auto">Previsualización de contenido</h2>
  </div>
  <div class="row">
    <div class="container mx-auto w-100 w-sm-70 px-0 px-sm-3">
      <div class="mx-auto post-data-wrapper row" *ngIf='dataLoaded && viewInitiated' style="position: relative;top:0px; left:0px">
        <div class="portada-container mt-0 mt-sm-5 col-12 col-sm-10 px-0 mx-auto">
          <div class='box-shadow z-index portada d-none d-sm-block mx-auto' id='portada'>
            <img class="w-100" src="{{data.c_portada}}" alt="" width="100%" height="100%">
          </div>
          <div class='box-shadow z-index portada-mobile d-block d-sm-none' id='portada'>
            <img class="w-100" src="{{data.c_portada}}" alt="" width="100%" height="100%">
          </div>
        </div>
      </div>
      <div class="mx-auto post-data-wrapper row bg-white border-top-left border-top-right" *ngIf='dataLoaded && viewInitiated' style="position: relative;top:0px; left:0px">
        
        
        <div class="col-12 col-sm-10 px-0 mx-auto" id="encabezado">
              
          <h4 class="bg-white mt-4 mb-1 card-category text-truncate">{{data.c_id_categoria}}</h4>
          <h2 class="bg-white mb-1 card-title line-clamp-3">{{data.c_titulo}}</h2>
          <h4 class="bg-white mb-1 card-date mt-2 text-truncate">
            {{data.c_datetime_publicacion | date: 'd \'de\' MMM \'de\' yyyy'}}</h4>
        </div>
      </div>
      <div class="mx-auto content-wrapper row border-bottom-left border-bottom-right">
        <span class='col-12 main-section-container bg-white'>
          <div id='content' class='overflow-x-auto mx-auto mt-3 mt-sm-5 px-1 px-sm-5' [ngClass]="{'d-none': dataLoaded == false}">
          </div>
        </span>
        <div class='col-12 px-0 px-sm-3 mx-auto bg-white'>
          <div class="row mb-0 my-sm-5 mx-auto py-0 py-sm-2 px-0 px-sm-4 bg-white" *ngIf='dataLoaded && viewInitiated'>
            <div class="col-11 mx-auto mb-2 mb-sm-0 col-sm-8 wrapfooter mx-auto" style="height: 30vh !important; ">
              <span class="meta-footer-thumb">
                <img class='author-thumb' src="{{data?.c_autor?.c_foto}}">
              </span>
              <div class="author-meta h-100 w-100">
                <div class="row" id="footer-row-empty" style="background-color: transparent !important;">
                </div>
                <div class="row" id="footer-row-author-name" style="background-color: transparent !important;">
                  <p class="post-name-author text-secondary text-left pl-4 mb-0 mt-auto">{{data?.c_autor?.c_nombre}}</p>
                </div>
                <div class="row" style="height: 50%; background-color: transparent !important;">
                  <p class="post-description-author text-wrap text-left px-4">{{data?.c_autor?.c_descripcion}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf='dataLoaded && viewInitiated'></app-footer>
