<div id="functionalities" class="functionalities-container">
    <div class="left-functionalities">
        <div class="container-title-functionalities">
            <h1 class="title-functionalities">FUNCIONALIDADES</h1>
            <img src="../../../../assets/marca-blanca/underlined.svg" alt="underlined">
        </div>
        <div class="container-diamond">
            <!-- INFO -->
            <div class="container-desktop">
                <div id="1" class="diamond-info">
                    <p>Te asesoramos en el diseño y materiales de tu tarjeta VISA, una vez aprobado el diseño, gestionamos el embozado y entrega a tus clientes.</p>
                    <img src="../../../../assets/marca-blanca/send-physical-card.png" alt="">
                </div>
                <div id="2" class="diamond-info">
                    <p>Elegís un UIKIT customizable para respetar el branding de tu marca. Todas las funcionalidades se encuentran disponible para ser usadas tanto desde el navegador web en cualquier dispositivo como en apps desde App Store y Play Store. Actualizaciones inmediatas mediante nuestro sistema de Live Updates.</p>
                    <img src="../../../../assets/marca-blanca/wallet-info.png" alt="">
                </div>
                <div id="3" class="diamond-info">
                    <p>Tus usuarios van a poder usar su cuenta digital, disponer de una tarjeta VISA Virtual lista para usar, cash-in y cash-out por transferencia vía CVU/ALIAS y puntos pago fácil.</p>
                    <img src="../../../../assets/marca-blanca/digital-account-info.png" alt="">
                </div>
            </div>
           
            <img class="img-base" src="../../../../assets/marca-blanca/diamond.svg" alt="image">
        </div>
    </div>
    <div class="right-functionalities">
        <img class="background" src="../../../../assets/marca-blanca/right-functionalities-background.svg" alt="">
        <div class="container-buttons-functionalities">
            <app-btn-white (eventoHijo)="onMensajeHijo($event)" icon="../../../../assets/marca-blanca/iconos/credit-card.svg" id={{btns[1].id}} active={{btns[1].active}} text="Emisión de tarjeta física VISA"></app-btn-white>
            <app-btn-white (eventoHijo)="onMensajeHijo($event)" icon="../../../../assets/marca-blanca/iconos/palette.svg"  id={{btns[2].id}} active={{btns[2].active}} text="Billetera web y App"></app-btn-white>
            <app-btn-white (eventoHijo)="onMensajeHijo($event)" icon="../../../../assets/marca-blanca/iconos/user.svg" id={{btns[3].id}} active={{btns[3].active}} text="Cuenta digital y tarjeta VISA Virtual"></app-btn-white>
        </div>
    </div>
</div>
<div class="container-benefits">
    <div class="container-img">
        <img class="background" src="../../../../assets/marca-blanca/background-benefits.svg" alt="">
    </div>
    <div class="container">
        <div class="container-cards">
            <app-card-info *ngFor="let item of items" title={{item.title}} description={{item.description}} img={{item.img}}></app-card-info>
        </div>
    </div>
    <!-- carousel mobile -->
    <mdb-carousel [controls]="false" [indicators]="true" [dark]="true" class="carousel-mobile">
        <mdb-carousel-item  *ngFor="let item of items" >
            <div class="mx-auto py-3">
                <app-card-info title={{item.title}} description={{item.description}} img={{item.img}}></app-card-info>
            </div>
        </mdb-carousel-item>
      </mdb-carousel>
</div>
