import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  filtros: any = {}
  cargando: boolean = true
  paginacion: any = { page: 1 }
  data: any = []
  categorias = [];
  headerItems = [{ title: 'Obtener Bestcard' },
  // { title: 'Artículos destacados', location: '/' },
  // { title: 'Novedades', location: '/' },
  // { title: 'Sobre nosotros', location: '/' }
  ];
  constructor(public ds: GlobalService, public router: Router) {

  }
  async ngOnInit() {
    // await this.getCategorias()
    await this.cargarLista()
  }

  // getCategorias() {
  //   return new Promise((resolve,reject) => {
  //     this.ds.httpPostNoToken('2', 'landing/entradas-blog/categorias/listar', {}).then((res:any) => {
  //       this.cargando = false;
  //       if(res && res.r && res.r.c == 0) {
  //         this.categorias = [{ value: null, label: 'Sin categoría' }];
  //         const categorias = res.result.map(elem => { return { value: elem.id, label: elem.nombre }; })
  //         this.categorias = this.categorias.concat(categorias)
  //         this.cargando = false;
  //         return resolve(true)
  //       }
  //       else 
  //       {
  //         this.categorias = []
  //         return resolve(true)
  //       }
  //       // else if (res && res.r && res.r.c==1) this.ds.generateErrorAlert(res.r.m)
  //       // else this.ds.generateErrorAlert('Ocurrió un error inesperado')
        
  //     })
  //   })
  // }


  async cargarLista() {
    this.filtros.limit = 6
    this.filtros.skip = (this.paginacion.page - 1) * this.filtros.limit
    this.cargando = true;
    this.ds.generateWaitingAlert2()
    const res = await this.ds.httpPostNoToken('2', 'landing/entradas-blog/listar', this.filtros).then(async(res:any) => {
      this.cargando = false;
      this.ds.closeWaitingAlert()
      if(res && res.r && res.r.c == 0) {
        this.categorias = [{ value: null, label: 'Sin categoría' }];
        const categorias = res.categorias.map(elem => { return { value: elem.id, label: elem.nombre }; })
        this.categorias = this.categorias.concat(categorias)
        res.result.forEach(item => item.c_categoria = this.categoriaToName(item.c_id_categoria))
        this.data = [...this.data, ...res.result];
        await this.setearPaginacion(res)
        this.cargando = false;
      }
      else 
      {
        this.categorias = []
      }
    })
  }

  async loadNextPage() {
    this.ds.generateWaitingAlert2('Cargando...')
    this.paginacion.page++
    await this.cargarLista()
    this.ds.closeWaitingAlert()
  }

  private async setearPaginacion(res: any) {
    const pageBkp = this.paginacion.page
    this.paginacion = await this.ds.createPagination({ paginaActual: this.paginacion.page, totalPaginas: res.pages, cantImparMaxDeBotones: 5 })
    this.paginacion.inicio = res.count == 0 ? 0 : ((this.paginacion.page - 1) * this.filtros.limit) + 1
    const fin = this.paginacion.inicio + this.filtros.limit - 1;
    this.paginacion.fin = fin > res.count ? res.count : fin
    this.paginacion.limit = this.filtros.limit
    this.paginacion.count = res.count
    this.paginacion.page = pageBkp
    // const totalPaginas = Math.ceil(this.paginacionTablLoanRequest.count / this.paginacionTablLoanRequest.limit)
  }

  private categoriaToName(id_categoria) {
    const item = this.categorias.find(item => item.value == id_categoria)
    return item.label
  }
}
