import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
    selector: 'app-tarjeta-activada',
    templateUrl: './tarjeta-activada.component.html',
    styleUrls: ['./tarjeta-activada.component.scss']
})
export class TarjetaActivadaComponent implements OnInit {

    email: any = null
    empresa:any = null
    estado: any = 0;
    msgError: any = null
    cardId: any = null
    constructor(public ds: GlobalService, private rutaActiva: ActivatedRoute,) {

        this.email = this.rutaActiva.snapshot.params.email
        this.empresa = this.rutaActiva.snapshot.params.empresa
        this.estado = 0
        this.cardId = this.rutaActiva.snapshot.queryParams.card_id

        this.ds.generateWaitingAlert("Activando Tarjeta")
        this.ds.httpPostNoToken('3', "cuenta/visa/app/v1/tarjeta/activar/procesar", { email: this.email, empresa: this.empresa, cardId: this.cardId }).then((res: any) => {
            this.ds.closeWaitingAlert()

            if(res && res.r && res.r.c==0)
            {
                if(res.ok==true) this.estado=1
            }
            else if (res && res.r && res.r.c==1)
            {
                this.estado = 2
                this.msgError = res.r.m
            }
            else
            {
                this.estado = 2
                this.msgError = null
            }
            
        })
    }

    ngOnInit(): void {

    }

}
