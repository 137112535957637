import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CobroinmediatoService } from '../services/cobroinmediato.service';

@Component({
    selector: 'app-links-pago',
    templateUrl: './links-pago.component.html',
    styleUrls: ['./links-pago.component.scss']
})
export class LinksPagoComponent implements OnInit {

    codigoQRLink = '';
    elementType = 'text';
    notInputsLnk: any = null;
    notInputsBtn: any = null;
    msjError: any;
    btnContinuar: Boolean = true;
    fechaVencimiento = [];
    parametroHash: any;
    title: string;
    tipoLink: any;
    data: any;
    formulario = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.maxLength(100), Validators.pattern(this.ds.expRegEmail)]),
        telefono: new FormControl(null, [Validators.min(1000000000), Validators.max(9999999999)]),
        referencia: new FormControl(null, [Validators.required, Validators.minLength(3)]),
        amount: new FormControl(null, [Validators.required]),
        // idEmpresa: new FormControl(null, [Validators.required]),
        // idSucursal: new FormControl(null, []),
        // idCaja: new FormControl(null, []),
        hash: new FormControl(null, [Validators.required]),
        hashLP: new FormControl(null),
    }, { updateOn: 'blur' });

    get form_referencia() { return this.formulario.get('referencia'); }
    get form_email() { return this.formulario.get('email'); }
    get form_telefono() { return this.formulario.get('telefono'); }
    get form_importe() { return this.formulario.get('amount'); }

    // Error General
    loading: Boolean = true;
    errorBand: Boolean = false;
    errorMsj: any = '';
    permitirNuevoPago: any = false

    mediosPago = {
        1: false,
        2: false,
    }


    constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute, public ci: CobroinmediatoService) {
        this.activatedRoute.params.subscribe(params => {
            let ref = params.data || null;
            this.parametroHash = ref;
            ds.loader = true;
            this.codigoQRLink = 'https://bestcard.com.ar/pago/' + ref;
            this.tipoLink = ref.toString().slice(0, 2);

            if (this.tipoLink == '03') { this.title = 'BOTÓN DE PAGO'; this.setInformacionBotonPago(ref); }
            else {
                this.title = 'LINK DE PAGO';
                let hashValidadcion = params.data2 || null;
                this.loading = true;
                ds.getInfoEmpresa(ref, hashValidadcion).then(res => {
                    this.loading = false;
                    ds.loader = false;
                    let resInfo: any = res;
                    if (resInfo.r) {
                        if (resInfo.r.c == 0 || resInfo['btnContinuar']) {

                            if (resInfo['btnContinuar']) {
                                this.notInputsLnk = true;
                                this.btnContinuar = false;
                                this.msjError = resInfo['r'].m;
                            }

                            this.mediosPago[1] = true 
                            this.mediosPago[2] = true 

                            this.data = resInfo['data'];
                            this.formulario.patchValue({
                                hash: ref,
                                // idEmpresa: this.data['idEmpresa']? this.data['idEmpresa'] : null,
                                // idSucursal: this.data['idSucursal']? this.data['idSucursal'] : null,
                                // idCaja: this.data['idCaja']? this.data['idCaja'] : null,
                                email: this.data['email'] ? this.data['email'] : null,
                                telefono: this.data['telefono'] ? parseInt(this.data['telefono']) : null,
                                referencia: this.data['referencia'] ? this.data['referencia'] : null,
                                amount: this.data['importe'] ? this.data['importe'] : null,
                                hashLP: hashValidadcion,
                            });
                        } else {
                            // this.router.navigateByUrl('/');
                            // this.ds.generateErrorAlert(resInfo.r.m);
                            this.errorBand = true;
                            this.errorMsj = resInfo.r.m;
                            if(resInfo.new == true) this.permitirNuevoPago = true
                        }
                    } else {
                        // this.router.navigateByUrl('/');
                        // this.ds.generateErrorAlert("Ocurrió un error inesperado");
                        this.errorBand = true;
                        this.errorMsj = "Ocurrió un error inesperado";
                    }
                });
            }
        });
    }

    ngOnInit(): void {

    }

    nuevoPago()
    {
        this.router.navigateByUrl(`pago/${this.parametroHash}`);
    }

    verifyForm() {


        if (this.formulario.status != "VALID") this.ds.generateErrorAlert('Debe completar todos los campos del formulario')
        else if (this.formulario.get('amount').value < this.ds.montoPagoMinimo) {
            this.ds.generateErrorAlert(`El monto mínimo es $${this.ds.montoPagoMinimo}`)
        }
        else if (this.formulario.get('amount').value > this.ds.montoLinkPagoMaximo) {
            this.ds.generateErrorAlert(`El monto máxmo es $${this.ds.montoLinkPagoMaximo}`)
        }
        else {

            this.ds.loader = true;
            this.ds.setDatosUsuario(this.formulario.value).then(res => {
                this.ds.loader = false;
                if (res['r'] && res['r'].c == 0) {
                    this.ds.procesador_pago = this.data.procesador;
                    this.router.navigateByUrl(`/formulario/${this.data.procesador}/${res['param']}/${this.parametroHash}`);
                } else if (res['r'] && res['r'].c == 1) {
                    this.ds.generateErrorAlert(res['r'].m);
                } else {
                    // this.ds.generateErrorAlert('Ocurrió un error inesperado. Por favor, inténtelo nuevamente');
                    this.errorBand = true;
                    this.errorMsj = "Ocurrió un error inesperado";
                }
            });
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////Boton Pago//////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    formBoton = new FormGroup({
        email: new FormControl(null, [Validators.required, , Validators.maxLength(100), Validators.pattern(this.ds.expRegEmail)]),
        telefono: new FormControl(null, [Validators.min(1000000000), Validators.max(9999999999)]),
        hash: new FormControl(null, [Validators.required]),
    }, { updateOn: 'blur' });

    get formBoton_email() { return this.formBoton.get('email'); }
    get formBoton_telefono() { return this.formBoton.get('telefono'); }

    setInformacionBotonPago(ref: any) {
        this.loading = true;
        this.ds.getInfoEmpresaBotonPago(ref).then(res => {
            console.log('res');
            console.log(res);
            this.loading = false;
            this.ds.loader = false;
            let resInfo: any = res;
            if (resInfo.r) {
                if (resInfo.r.c == 0 || resInfo['btnContinuar']) {

                    if (resInfo['btnContinuar']) {
                        this.notInputsBtn = true;
                        this.btnContinuar = false;
                        this.msjError = resInfo['r'].m;
                    }

                    this.fechaVencimiento = resInfo['fechas'];
                    this.data = resInfo['data'];
                    this.formBoton.patchValue({
                        hash: ref,
                        email: this.data['email'] ? this.data['email'] : null,
                        telefono: this.data['telefono'] ? parseInt(this.data['telefono']) : null,
                    });

                    if(resInfo.data.medios==null)
                    {
                        this.errorBand = true;
                        this.errorMsj = "No se puede realizar el pago";
                    }
                    else
                    {
                        if(resInfo.data.medios.includes(1)) this.mediosPago[1] = true 
                        if(resInfo.data.medios.includes(2)) this.mediosPago[2] = true 
                    }

                } else {
                    // if(resInfo['btnContinuar']) {
                    //   this.btnContinuar= false;
                    //   this.msjError= resInfo['r'].m;
                    // } else {
                    // this.router.navigateByUrl('/');
                    // this.ds.generateErrorAlert(resInfo.r.m);
                    this.errorBand = true;
                    this.errorMsj = resInfo.r.m;
                    // }

                }
            } else {
                // this.router.navigateByUrl('/');
                // this.ds.generateErrorAlert("Ocurrió un error inesperado");
                this.errorBand = true;
                this.errorMsj = "Ocurrió un error inesperado";
            }
        });
    }

    verifyFormBoton() {
        if (this.formBoton.status != "VALID") this.ds.generateErrorAlert('Debe ingresar el email');
        else {

            this.ds.loader = true;
            this.ds.btnContinuar(this.formBoton.value).then(res => {
                this.ds.loader = false;
                // console.log('res');
                // console.log(res);
                if (res['r'] && res['r'].c == 0) {
                    this.ds.procesador_pago = this.data.procesador;
                    this.router.navigateByUrl(`/formulario/${this.data.procesador}/${this.parametroHash}`);
                } else if (res['r'] && res['r'].c == 1) {
                    this.ds.generateErrorAlert(res['r'].m);
                } else {
                    // this.ds.generateErrorAlert('Ocurrió un error inesperado. Por favor, inténtelo nuevamente');
                    this.errorBand = true;
                    this.errorMsj = "Ocurrió un error inesperado";
                }
            });
        }
    }

    ngOnDestroy() {
        this.notInputsBtn = null;
        this.notInputsLnk = null;
        this.btnContinuar = true;
        this.errorBand = false;
        this.errorMsj = '';
    }

    copy: any = null;
    copiarPortaPapeles(texto) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = texto;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copy = true;
        setTimeout(() => {
            this.copy = false;
        }, 2000)
    }

    generarPdf()
    {
        this.ds.generateWaitingAlert("Descargando comprobante...")
        let hash = ""
        this.ds.httpPostNoToken('2',"link/v2/pago/postpago/generar/comprobante",{idLinkPago:hash}).then((res:any)=> {
        // this.ds.httpPostNoToken('2',"link/v2/pago/postpago/generar/comprobante",{idLinkPago:"04d28a027984862450da28dd37b6128b70"}).then((res:any)=> {
            this.ds.closeWaitingAlert()
            if(res && res.r && res.r.c==0)
              {
                  const dataBlob = this.dataURItoBlob(res['wbout'],'application/octet-stream');

                  var link = document.createElement('a');
                  link.href = window.URL.createObjectURL(dataBlob);
                  link.download = 'bc_comprobante_pago_'+hash+'_'+ new Date().toLocaleDateString() +'.pdf';
      
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  return
              }
              else if(res && res.r && res.r.c==1)
              {
                  return this.ds.generateErrorAlert(res.r.m)
              }
              else
              {
                  return this.ds.generateErrorAlert("Ocurrió un error inesperado")
              }
        })
    }

    dataURItoBlob(dataURI,type) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: type });
        return blob;
     }


}
