<header [ngClass]="classNavbar()" *ngIf="showBaseNavbar()" class="h-100">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav py-2" [containerInside]="true">
        <mdb-navbar-brand>
            <a class="logo navbar-brand" href="#">
                <img src="../assets/home/logo-white.png" alt="Logo de BestCard" height="44"/>
            </a>
        </mdb-navbar-brand>
        <!-- *ngIf="!router.url.includes('consultas')" -->
        <links >
            <ul class="navbar-nav ml-auto">
                <li class="nav-item waves-light mx-2" mdbWavesEffect *ngIf="isHome()">
                    <a class="nav-link" mdbPageScroll href="#obtener">Obtené tu BestCard</a>
                </li>
                <li class="nav-item waves-light mx-2" mdbWavesEffect *ngIf="!isHome()">
                    <a class="nav-link" mdbPageScroll (click)="goMenu('obtener');">Obtené tu BestCard</a>
                </li>
                <!-- <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" href="https://billetera.bestcard.com.ar" target="_blank" >Billetera Virtual</a>
                </li> -->
                <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" href="https://billetera.bestcard.com.ar" target="_blank" >Ingresar a Billetera</a>
                </li>
                <!-- <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" mdbPageScroll routerLink="/prestamo/solicitar">BestPréstamo</a>
                </li> -->
                <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" mdbPageScroll routerLink="/simular-prestamo" title="Préstamos">"Solicitá tu Préstamo"</a>
                </li>
                <li class="nav-item waves-light mx-2" mdbWavesEffect *ngIf="isHome()">
                    <a class="nav-link" mdbPageScroll href="#faqs">Preguntas Frecuentes</a>
                </li>
                <li class="nav-item waves-light mx-2" mdbWavesEffect *ngIf="!isHome()">
                    <a class="nav-link" mdbPageScroll routerLink="/preguntas_frecuentes">Preguntas Frecuentes</a>
                </li>

                <li class="nav-item waves-light mx-2"  mdbWavesEffect>
                    <a mdbBtn color="secondary" id="btn-bc-pagos" size="sm" type="button" routerLink="/pagos" class="waves-light mx-auto my-0 nav-link d-none d-sm-block" style="text-transform: none !important; font-size: 16px; padding: 12px 5px;
                    border-radius: 5px; background-color: rgba(245, 150, 39, 1) !important;" mdbWavesEffect>
                        BestCard Pagos
                    </a>
                    <a mdbBtn color="secondary" size="sm" type="button" routerLink="/pagos" class="waves-light mx-auto my-0 nav-link d-block d-sm-none" style="text-transform: none !important; font-size: 16px;" mdbWavesEffect>
                        BestCard Pagos
                    </a>
                </li>
                <!-- <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" mdbPageScroll href="#">Promociones </a>
                </li> -->
                <!-- <li class="nav-item waves-light mx-2" mdbWavesEffect>
                    <a class="nav-link" mdbPageScroll>Promociones</a>
                </li> -->
                <li class="nav-item waves-light mx-2 d-flex align-items-center" mdbWavesEffect >
                    <!-- <a target="_blank" class="nav-link" href='https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quería%20hacerles%20la%20siguiente%20consulta:%20'> -->
                    <a target="_blank" class="nav-link d-none d-sm-block" href='/consulta'>
                        <mdb-icon fab icon="whatsapp" size="lg" class="white-text"></mdb-icon>
                    </a>
                    <a target="_blank" class="nav-link d-block d-sm-none" href='/consulta'>
                        <mdb-icon fab icon="whatsapp" size="lg" class="white-text"></mdb-icon> Consultar por Whatsapp
                    </a>
                </li>
            </ul>
        </links> 
    </mdb-navbar>
</header>   

<header class="h-100" *ngIf="router.url.includes('/reg/registro') ">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav py-3" [containerInside]="true">
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
    </mdb-navbar>
</header>   

<header class="h-100" *ngIf="router.url.includes('consulta') ">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
            <links style="color: #fff;">
                <h3 class="ml-3 my-auto" style="color: #fff;">Consultar por Whatsapp</h3>
            </links>
    </mdb-navbar>
</header>   
<header class="h-100" *ngIf="router.url.includes('prestamos/consulta') ">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
            <links style="color: #fff;">
                <h3 class="ml-3 my-auto" style="color: #fff;">Consultar Prestamo</h3>
            </links>
    </mdb-navbar>
</header>   
<header class="h-100" *ngIf="router.url.includes('simular-prestamo') ">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
            <links style="color: #fff;">
                <h3 class="ml-3 my-auto" style="color: #fff;">Simular Prestamo</h3>
            </links>
    </mdb-navbar>
</header>   
<header class="h-100" *ngIf="router.url.includes('baja') ">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
            <links style="color: #fff;">
                <h3 class="ml-3 my-auto" style="color: #fff;">Botón de Arrepentimiento</h3>
            </links>
    </mdb-navbar>
</header>   
<header class="h-100" *ngIf="router.url.includes('link-validacion-identidad')">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
        </mdb-navbar-brand>
        <links style="color: #fff;">
            <h3 class="ml-3 my-auto" style="color: #fff;">Validación de identidad</h3>
        </links>
    </mdb-navbar>
</header>   
<header class="h-100" *ngIf="router.url.includes('kyc')">
    <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg top-nav scrolling-navbar intro-fixed-nav py-3"  >
        <mdb-navbar-brand>
            <a href="#" class="d-inline">
                <mdb-icon fas icon="arrow-left" size="2x" class="white-text"></mdb-icon>
            </a>
            <h3 class="ml-3 my-auto d-inline" style="color: #fff;">Validación de identidad</h3>

        </mdb-navbar-brand>
        <!-- <links style="color: #fff;">
            <h3 class="ml-3 my-auto" style="color: #fff;">Validación de identidad</h3>
        </links> -->
    </mdb-navbar>
</header>   
