import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
    selector: 'app-tarjeta-activar',
    templateUrl: './tarjeta-activar.component.html',
    styleUrls: ['./tarjeta-activar.component.scss']
})
export class TarjetaActivarComponent implements OnInit {

    url: any = null
    loading: boolean = false
    constructor(public ds: GlobalService, public rutaActiva: ActivatedRoute) {

        let params = this.rutaActiva.snapshot.params

        this.url = params.url
        if (this.url == null) this.ds.generateErrorAlert("Ocurrió un error inesperado")
        else {
            // document.getElementById("iframe_activar_tarjeta").setAttribute("src", this.url)
        }

    }

    ngOnInit(): void {

        if (this.url == null) this.ds.generateErrorAlert("Ocurrió un error inesperado")
        else {

            this.loading = true
            this.url = this.url.replaceAll('%2F', '/')
            this.url = this.url.replaceAll('%3F', '?')
            this.url = this.url.replaceAll('%3D', '=') 
            this.url = this.url.replaceAll('%2B', '+')
            this.url = atob(this.url)

            document.getElementById("iframe_activar_tarjeta").setAttribute("src", this.url)
        }

    }

    uploadDone()
    {
        this.loading=false
    }

}
