import { Component, OnInit } from '@angular/core';
import { FranquiciaService } from '../../../modules/franquicias/services/franquicia.service';

@Component({
  selector: 'app-navbarFranquicia',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarFranquiciaComponent implements OnInit {

  constructor(public franquiciaService: FranquiciaService) { 
  }

  ngOnInit(): void {
  }

}
