import { Component, OnInit } from '@angular/core';
import { items} from './items';

@Component({
  selector: 'app-personalization',
  templateUrl: './personalization.component.html',
  styleUrls: ['./personalization.component.scss']
})
export class PersonalizationComponent implements OnInit {
  items:Object[] = items


  constructor() { }

  ngOnInit(): void {
  }

}
