const baseUrl = "../../../../assets/marca-blanca/iconos/"

export const items = [
    {
        id:0,
        title: "Pago con QR",
        description:"Comisiones configurables, tus usuarios pueden pagar con cualquier medio de pago.",
        img:`${baseUrl}qr-icon.svg`
    },
    {
        id:1,
        title: "Préstamos y créditos",
        description:"Te asesoramos en las tasas de préstamos/créditos otorgados. Cobranza online y gestionada por nuestros operadores.",
        img:`${baseUrl}dollar-icon.svg`
    },
    {
        id:2,
        title: "Pago de servicios",
        description:"Tus usuarios pueden pagar mas de 3000 servicios desde la comodidad de su billetera.",
        img:`${baseUrl}document-icon.svg`
    },
    {
        id:3,
        title: "Sistema de Fidelización",
        description:"Premiá a tus usuarios configurando nuestro motor de recomendación para cashback, cupones de descuentos o puntos.",
        img:`${baseUrl}following-icon.svg`
    },
    {
        id:4,
        title: "Suscripciones",
        description:"Podés crear un producto digital con pago recurrente configurable, que permita acceder al usuario a distintos servicios/beneficios.",
        img:`${baseUrl}apps-add-icon.svg`
    },
    {
        id:5,
        title: "Soporte",
        description:"Nos encargamos de dar soporte a tus usuarios en todo momento.",
        img:`${baseUrl}headset-icon.svg`
    }
]