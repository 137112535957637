<div>
    <div class="personalization-container">
        <div class="container-title-personalization">
            <h1 class="title-personalization-desktop">TARJETA FÍSICA Y VIRTUAL VISA INNOMINADA</h1>
            <h1 class="title-personalization-mobile">TARJETA FÍSICA Y VIRTUAL VISA</h1>
            <img src="../../../../assets/marca-blanca/underlined.svg" alt="underlined">
            <p>Personalizamos tu tarjeta en base a la identidad de tu marca.</p>
        </div>
        <div class="content">
            <div class="container-cards">
                <app-card-icon-title *ngFor="let item of items" title={{item.title}} img={{item.img}}></app-card-icon-title>
            </div>
            <div class="container-card">
                <img class="img-5" src="../../../../assets/marca-blanca/card-violet-personalization.svg">
                <img class="img-4" src="../../../../assets/marca-blanca/card-orange-personalization.svg">
                <img class="img-3" src="../../../../assets/marca-blanca/card-lightBlue-personalization.svg">
                <img class="img-2" src="../../../../assets/marca-blanca/card-pink-personalization.svg">
                <img class="img-1" src="../../../../assets/marca-blanca/card-white-personalization.svg">
            </div>
        </div>
        <img class="background" src="../../../../assets/marca-blanca/background-personalization.svg" alt="">
    </div>
</div>
