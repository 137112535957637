import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {

  bandPreg: String= '';

  items1: Object[]= [
    {
      title: '¿Qué es BestCard?',
      description: 'BestCard es una billetera virtual dentro de una app y una tarjeta prepaga VISA internacional, para que puedas manejar de forma sencilla tu dinero. Con la tarjeta BestCard podrás comprar en cualquier local y sitio web del mundo. También podras enviar y recibir transferencias a cuentas bancarias (CBU) y cuentas virtuales (CVU) instantáneamente. Con BestCard controla tus gastos y seguir tus movimientos desde tu celular cuando quieras.'
    },
    {
      title: '¿Es una tarjeta de crédito o una tarjeta de débito?',
      description: 'BestCard es una tarjeta prepaga. La podes utilizar para todas tus compras, sin embargo, no necesitas la tarjeta para realizar transferencias ni pagar tus servicios, desde tu celular o computadora ahora lo podes hacer.'
    },
    {
      title: '¿Qué es una tarjeta virtual?',
      description: 'En la app podés ver al instante tu tarjeta virtual. Es una opción rápida para que realices compras online.\nLa tarjeta virtual ofrece un mayor nivel de seguridad que las tarjetas tradicionales ya que estas no pueden ser “sustraídas” y cada operación está sujeta a una validación.'
    }
  ];

  items2: Object[]= [
    {
      title: '¿Cómo obtener BestCard?',
      description: `1. SIN PAPELES: Descargá la App o ingresá a <a href="https://www.bestcard.com.ar">https://www.bestcard.com.ar/</a>  y completa un sencillo formulario con tus datos.\n2. APROBAMOS TU CUENTA: Recibirás un email de confirmación.\n3. CARGA DINERO A TU CUENTA: Con tranferencia bancaria o en efectivo desde Rapipago o Pagofacil o en nuestros puntos de recarga.\n4. RECIBÍ TU BESTCARD: Retirala por el local seleccionado o recibila en tu domicilio o trabajo.`
    },
    {
      title: '¿Cuáles son los requisitos para solicitarla?',
      description: `Tener más de 18 años.\nContar con un email para el registro.\nTener un teléfono móvil con sistema operativo Android o un navegador web para acceder a la versión multiplataforma desde <a href="https://www.bestcard.com.ar">https://billetera.bestcard.com.ar</a>`
    },
    {
      title: '¿En qué Sistemas operativos está disponible?',
      description: 'Por el momento BestCard está disponible en Play Store para Android y en la billetera WEB. El sistema iOS estará muy pronto. '
    },
    {
      title: '¿Cómo solicito mi tarjeta BestCard física?',
      description: 'Una vez aprobada tu cuenta puedes empezar a utilizar tu billetera virtual desde la app o computadora, junto con una tarjeta BestCard virtual. Podrás solicitar una tarjeta física realizando los siguientes pasos:\n1. Entrá a la App\n2. Ingresá al menú "Mi BestCard" de la app en el margen inferior derecho\n3. Haz clic en "Ver Más" en la sección VISA\n4. Presioná el botón "Solicitar tarjeta" sobre el recuadro de la tarjeta física.\n5. Seguí los pasos, ¡y listo!'
    },
    {
      title: '¿A dónde mi tarjeta BestCard?',
      description: 'Una vez procesada tu solicitud, la tarjeta de BestCard llegará a tu domicilio o local seleccionado en el proceso.'
    },
    {
      title: '¿Qué costo de mantenimiento tiene BestCard? ',
      // description: 'El costo de mantenimiento es el mínimo. Con solo $100 mensuales podes empezar a utilizarla.'
      description: 'BestCard no te cobra mantenimiento por tu cuenta'
    },
    {
      title: '¿Qué costo de otorgamiento tiene la tarjeta?',
      description: 'No tiene costo de otorgamiento, es gratis.'
    },
    {
      title: '¿Qué pasa si pierdo mi tarjeta BestCard?',
      description: 'Podrás solicitar una nueva tarjeta desde la app o página web de la billetera. Se pueden aplicar costos por la reimpresión.'
    }
  ];

  items3: Object[]= [
    {
      title: '¿Cómo puedo cargar dinero a mi BestCard?',
      description: 'Con transferencia bancaria o en efectivo desde Pagofácil, mostrando el código que aparece en la app o página web o en nuestros puntos de Recargas. La carga de dinero es sin costo.'
    },
    {
      title: '¿Cuándo tengo disponible la carga que realicé?',
      description: 'De forma inmediata.'
    }
  ];

  items4: Object[]= [
    {
      title: '¿Que podes hacer con BestCard?',
      description:`Compras: Hacé compras en cualquier comercio o página web.\nPagá y Recargá tus servicios: Pagá tus facturas, recargá tus servicios prepagos y cargá la SUBE.\nRetiro de efectivo en cajeros: Sacá plata de cualquier cajero de la Red Banelco o LINK con tu tarjeta.\nTransferencias: Transferí dinero a cualquier cuenta.\nComprá fuera del país: Utilizala en cualquier parte del mundo.`
    },
    {
      title: '¿Se puede solicitar tarjetas adicionales?',
      description: '¡No funcionan como adicionales! tu pareja, hijos o amigos deben crearse una cuenta BestCard individualmente y solicitar la tarjeta desde ahí.'
    },
    {
      title: '¿Se puede usar fuera del país?',
      description: '¡Si! La tarjeta BestCard es Internacional, la puedes utilizar en cualquier comercio que reciba VISA. '
    },
    {
      title: '¿Es necesario contar con la tarjeta para hacer pagos y transferencias?',
      description: '¡No! Desde la App o Computadora puedes realizar pagos y transferencias.'
    },
    {
      title: '¿Dónde puedo realizar una extracción de efectivo con mi tarjeta?',
      description: 'Puedes extraer efectivo con tu tarjeta en todos los cajeros Banelco.'
    },
    // {
    //   title: '¿Cuales son los beneficios disponibles en comercios adheridos?',
    //   description: 'Haciendo click aquí podrás ver las Promociones y Beneficios vigentes.'
    // },
    {
      title: '¿Qué comercios reciben BestCard?',
      description: 'Todos los comercios que reciben VISA.'
    },
    {
      title: '¿Qué servicios pago con BestCard? ¿Cómo pagar los servicios?',
      description: 'Puedes pagar todos los servicios como: Netflix, Telefonía, Spotify, Luz, Agua, Gas, Impuestos, etc. Debes ingresar el código de pago Banelco de tu servicio en la sección de "Pagos de Servicios" desde la app o web.'
    },
    {
      title: '¿Cómo puedo ver desde la app o página web mi tarjeta virtual?',
      description: '1. Entrá a la App\n2. Ingresá al menú "Mi BestCard" de la app en el margen inferior derecho\n3. Haz clic en "Ver Más" en la sección VISA y listo!\nPodrás visualizar los datos necesarios de tu tarjeta virtual'
    },
    {
      title: '¿Cómo asociar mi tarjeta BestCard a Mercado Pago?',
      description: '1. Ingresá a Mercado Pago\n2. Ingresá al menú y hace clic en “Tarjetas”\n3. Hacé clic en “Agrega tus tarjetas”\n4. Ingresá los datos correspondientes a tu tarjeta y listo!!\nTu tarjeta BestCard ya se encuentra asociada y lista para empezar a  usar'
    },
    {
      title: '¿Cómo pagar con QR desde Mercado Pago con BestCard?',
      description: '1. Ingresá a Mercado Pago\n2. Hacé clic en el símbolo de QR y escanealo\n3. Una vez escaneado podrás visualizar tus tarjetas ya asociadas y hacer clic en tu tarjeta BestCard\n4. Realizas el pago y listo!'
    },
    {
      id: 'validar_identidad',
      title: '¿Por qué es necesario validar tu identidad?',
      description: 'La validación de identidad es un proceso obligatorio para todos los usuarios de BestCard.\nEs un sistema seguro y confiable de validación de identidad que respeta la privacidad de los datos y fotos suministradas por el usuario. La biometría de rostro es un modo de identificación legalmente válido y adaptado a las nuevas tecnologías según los términos de la ley 17.671 y su reglamentación decreto 1501/09.\nEstas son nuestras recomendaciones para que, al momento de sacarte las selfies, salgan claras y puedas completar el proceso exitosamente:\n\n1. Al comenzar el proceso debes aceptar el permiso de uso de la cámara por parte de la aplicación, esto es fundamental para poder tomarte las selfies necesarias\n2. Buscá un fondo claro y evitá ubicarte de espaldas a grandes fuentes de luz, como ventanas o lámparas\n3. Colocá el celular a la altura de tu rostro, ubicándote en el centro del círculo\n3. No te pongas anteojos, barbijo, ni nada que tape tu rostro\n5. Al momento de sonreír, ¡recordá mostrar tus dientes!\n\nSi aún teniendo esto en cuenta no pudiste validar tu identidad o tuviste otro inconveniente, envianos tu consulta para que podamos analizar tu caso y ayudarte a resolverlo.'
    },
  ];

  




  items5: Object[]= [
    // {
    //   title: '¿Cuáles son los pasos para pedir el préstamo*?',
    //   description: 'Puedes solicitar el préstamo desde la App o Página Web. Debes completar el formulario con los datos solicitados...'
    // },
    {
      title: '¿Cuánto puedo pedir?',
      description: 'Dependiendo de tu calificación crediticia será el monto aprobado.'
    },
    // {
    //   title: '¿En cuántas cuotas se puede solicitar el préstamo*?',
    //   description: 'Podrás elegir el plan de cuotas que mejor se adapte a tu necesidad.'
    // },
    {
      title: '¿Cuál es el tiempo de acreditación?',
      description: 'Dentro de 24hs hábiles.'
    },
    // {
    //   title: '¿Cómo recibo el dinero del préstamo*?',
    //   description: 'El dinero lo tendrás acreditado en tu billetera virtual.'
    // }
  ];

  items6: Object[]= [
    {
      title: '¿Cómo realizo consultas?',
      description: `Desde la App.\nAtención al cliente (Whatsapp).\nMail.`
    },
  ];

  items7: Object[]= [
    {
      title: '¿Qué es BestCard PAGOS?',
      description: 'BestCard PAGOS es la plataforma con la cual vas a poder cobrar por tus productos y/o servicios como quieras, tanto presencialmente como a distancia.\n\nSi realizas ventas presenciales vas a poder cobrar a través del método QR. Si querés cobrar a distancia vas a poder enviar un link de pago y botón de pago a quien quieras, sin necesidad de estar en el mismo lugar que tu cliente.\n\nTu cliente podrá pagar con cualquier tarjeta Visa, Mastercard o Cabal'
    },
    {
      title: '¿Cuáles son los métodos de cobro?',
      description: 'BestCard PAGOS cuenta con múltiples métodos de cobros: QR, Link de pago y Botón de Pago.'
    },
    {
      title: '¿Qué es un link de pago? ¿Cómo lo genero?',
      description: 'El link de pago es un método de cobro con el que podés cobrar tus ventas donde sea y a quien sea sin necesidad de estar en el mismo lugar que tu cliente. ¡Generas el link, lo compartís, tu cliente indica el monto a pagar y realiza el pago!\n\nGenerás el link de pago así:\n1. Generá tu link de cobro\n2. Envíalo a tus clientes por mail, redes sociales o whatsapp\n3. Tu cliente detalla sus datos, el monto a pagar y los datos de su tarjeta, paga y recibe su comprobante por mail\n4. Recibes el dinero en tu cuenta bestcard donde podrás seguir todos tus movimientos.'
    },
    {
      title: '¿Qué es un botón de pago? ¿Cómo se genera?',
      description: 'El botón de pago al igual que el link de pago es un método de cobro. Creas el botón de pago con el monto a cobrar y fecha de vencimiento, después se lo envías a tus clientes.\n\nGeneras el botón de pago así:\n1. Generá tu botón de pago, desde la App página web, detallando el concepto y el monto a cobrar. ¡Puedes agregar fechas de vencimiento!\n2. Envíalo a tus clientes por mail, redes sociales o whatsapp\n3. Tu cliente completa los datos de su tarjeta y paga.\n4. Recibes el dinero en tu cuenta bestcard y observas el resumen de tus ventas.'
    },
    {
      title: '¿Qué es un QR? ¿Cómo lo visualizo?',
      description: 'Un código QR es la evolución del código de barras. A través de este código podrás ofrecerles a tus clientes otra opción de pago a través de su celular.\n\nCompartes el QR  de tu empresa así:\n1. Ingresas a tu cuenta desde la App o página web.\n2. Haces clic en Link de pago dentro del menu.\n3. Activas link de pago abierto y listo!\nAhora podes compartir tu QR a quienes quieras.'
    },
    {
      title: '¿Dónde recibo la plata generada a través de mis ventas?',
      description: 'Podrás ver el detalle del cobro en la pantalla de inicio desde la app de BestCard o desde la página web, al igual que todos tus otros movimientos. A su vez, vos y tu cliente recibirán un comprobante en su mail.'
    },
    {
      title: '¿Cuáles son las comisiones que pago por mis ventas?',
      description: 'Con BestCard PAGOS te ofrecemos la comisión más baja de todas las plataformas de cobro. Por cada pago recibido a través del link de pago, QR o botón de pago vas a pagar una comisión de 2.5% + IVA sin importar qué medio de pago use tu cliente. Con acreditación a los 18 días.'
    },
    {
      title: '¿Cuál es el costo por adelantar la acreditación?',
      description: 'Podrás adelantar las acreditaciones pagando el 0.26% del día que quieras adelantar. Elige el día de venta que quieres adelantar y dispones de tu dinero dentro de las 24hs hábiles.\n¡Adelantas las acreditaciones los días que quieras y cuando quieras!'
    },
  ];

  itemsPrestamos: Object[]= [
    {
      title: '¿Cómo puedo solicitar un préstamo?',
      description: 'Solicitá tu préstamo personal a través de la App de BestCard o por <a href="https://www.bestcard.com.ar/prestamos" target="_blank">https://www.bestcard.com.ar/prestamos</a>'
    },
    {
      title: '¿Cuál es el monto máximo que puedo solicitar?',
      description: 'El monto máximo que podrás solicitar es de hasta $100.000.'
    },
    {
      title: '¿Cómo me entregan el dinero?',
      description: 'La acreditación del dinero se realiza a tu cuenta de BestCard.'
    },
    {
      title: '¿En cuántas cuotas se puede devolver el préstamo?',
      description: 'Podrás devolver tu préstamos en hasta 12 cuotas fijas.'
    },
    {
      title: '¿Cómo puedo pagar la cuota de mi préstamo personal?',
      description: 'Ingresando a tu cuenta, desde la app o página web, podrás visualizar tu préstamo y realizar allí el pago de las cuotas. \nPodrás abonar desde tu cuenta con el dinero disponible o elegir la opción de pago con tarjeta de crédito o débito.'
    },
    {
      title: '¿Cuándo vencen las cuotas?',
      description: 'La primera cuota tendrá como vencimiento a partir de los 30 días de haberse acreditado tu préstamo.'
    },
    {
      title: '¿Cuánto tiempo demora en darme el préstamo?',
      description: 'El préstamo se acreditará dentro de las 24hs habiles.'
    }
  ];

  


  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    let urlArre= this.router.url.split('#');
    if(urlArre && urlArre.length) {
      this.bandPreg= urlArre[urlArre.length-1];
    }
  }

}
