<footer class="page-footer font-small py-4 footer-marca-blanca">
    <div class="container text-center text-md-left mt-1 mb-5">
      <div class="row">
        <div class="col-lg-4 ml-auto">
          <a href="https://bestcard.com.ar"><img src="../assets/home/bestcard_blanco_icon_footer.png"
              class="mx-auto d-block mb-3" height="120" /></a>
          <p class="text-center">BestCard 2020, Todos los derechos reservados</p>
        </div>
        <div class="col-lg-4 mr-auto d-flex flex-column mt-3 mt-sm-0">
          <p class="text-center subtitulo-1">Seguinos en redes sociales</p>
          <div class="d-flex justify-content-center mb-4">
            <a class="fb-ic waves-light p-2 rounded-circle mx-3" target="_blank"
              href="https://facebook.com/Best-Card-397560351089246/?business_id=1874250536156711" mdbWavesEffect>
              <mdb-icon fab icon="facebook" size="2x" class="white-text"></mdb-icon>
            </a>
            <a class="fb-ic waves-light p-2 rounded-circle mx-3" target="_blank"
              href="https://www.instagram.com/bestcard.arg" mdbWavesEffect>
              <mdb-icon fab icon="instagram" size="2x" class="white-text"></mdb-icon>
            </a>
            <a class="fb-ic waves-light p-2 rounded-circle mx-3" target="_blank"
              href="https://www.twitter.com/bestcard_arg" mdbWavesEffect>
              <mdb-icon fab icon="twitter" size="2x" class="white-text"></mdb-icon>
            </a>
          </div>
          <span class="d-block text-center fw-700">
            <a href="https://bestcard-production.s3.us-east-2.amazonaws.com/bestcard/assets/tyc/tyc_app_bestcard.pdf"
              class="fw-700" target="_blank">Términos y Condiciones</a>
            |
            <a href="https://bestcard-production.s3.us-east-2.amazonaws.com/bestcard/assets/tyc/tyc_app_bestcard.pdf"
              class="fw-700" target="_blank">Políticas de Privacidad</a>
          </span>
          <div class="row w-100 mx-0 justify-content-center mt-3">
            <div class="col-auto">
              <p class="m-0">
                <mdb-icon fas icon="home"></mdb-icon>
                Mitre 425 Este
              </p>
            </div>
            <div class="col-auto">
              <p class="m-0">
                <mdb-icon fas icon="phone"></mdb-icon>
                +549 264 5800950
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mx-auto d-flex justify-content-center flex-column">
          <a href="http://www.bcra.gob.ar/BCRAyVos/usuarios_financieros.asp"><img src="https://bestcard-production.s3.us-east-2.amazonaws.com/bestcard/assets/usuarios-financieros.png" class="mx-auto d-block mb-3" height="120"/></a>
          <p class="mb-0 text-center"><a class="fw-700" target="_blank" href="https://www.argentina.gob.ar/produccion/defensadelconsumidor">Portal de Defensa del Consumidor</a></p>
          <p class="mb-0 text-center"><a class="fw-700" target="_blank" href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario">Formulario de denuncias Ventanilla Única Federal de Defensa del Consumidor</a></p>
          <p class="mb-0 text-center"><a class="fw-700" target="_blank" href="https://www.argentina.gob.ar/produccion/consumidor/leyes">Leyes de protección al consumidor</a></p>
          <p class="mb-0 text-center"><a class="fw-700" target="_blank" href="https://docs.google.com/document/d/1x-mTbLDg5XyrrxnHqFRCm93MDncdmlY-40PQqcDiwjc">Tasas y Comisiones</a></p>
          <p class="mb-0 text-center"><a class="fw-700" href="/baja">Botón de Arrepentimiento</a></p>
      </div>
      </div>

      <!-- Grid row -->
    </div>
  </footer>