<div class="layout-local">
    <!-- En caso de error -->
    <div class="container row flex-column py-4 px-2 z-depth-1 rounded-lg white py-4 pb-2-half">
        <div class="row">
            <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column ">
                <h5 class="text-center text-danger">Ocurrió un error al procesar el pago</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <button *ngIf="hash!=null" mdbBtn type="button" color="success" block="true" class="waves-light mb-0 m-0 mt-2 mr-auto btn-continuar text-white" (click)="goBack()" mdbWavesEffect>
                    <i class="fas fa-arrow-left"></i>
                    Reintentar Pago
                </button>
            </div>
            <div class="col-12 col-md-6">
                <button mdbBtn type="button" color="success" block="true" class="waves-light mb-0 m-0 mt-2 ml-auto btn-continuar text-white" routerLink="/" mdbWavesEffect>
                    <i class="fas fa-arrow-left"></i>
                    Ir al inicio
                </button>
            </div>
            
            
        </div>
        
    </div>
</div>