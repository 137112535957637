<div class="layout-local" >
  <div class="container row flex-column py-4 px-2" *ngIf="!bandPagoRealizado">
    <!-- <h2 class="text-center text-secondary" style="z-index: 10">{{subtitulo}}</h2> -->
    <div class="col-lg-5 col-sm-10 mx-auto d-flex flex-column">
      <div class="col-10 col-lg-10 col-md-8 col-xl-8 p-2 titulo m-0 ml-4 d-flex">
          <span class="d-flex align-items-center text-white ml-2 text-truncate text-uppercase">{{subtitulo}}</span>
          <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt="">
      </div>
    </div>
    <div class="col-lg-5 col-sm-10 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2 pt-3">
      <div id="myPaymentForm" class="d-flex justify-content-center align-items-center"></div>
      <span *ngIf="this.ds.loader" class="text-center color-text"><h5>Cargando Formulario...</h5></span>
    </div>

    <span *ngIf="!this.ds.loader && fechaVencimiento!=null" class="text-center text-white small">El formulario caduca el {{fechaVencimiento | fechaVencimiento}}</span>

    <!-- BestCard Img-->
    <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column justify-content-center align-items-center">
      <img src="../../home/logo_Bestcardpagos_B2.png" alt="BestCard" class="m-4 logo-login">
    </div>
    
  </div>

  <div class="container row flex-column py-4 px-2" *ngIf="bandPagoRealizado">
    <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-4 pb-2-half">
      <h4 class="text-center color-text" style="z-index: 10">Importante</h4>
      <span *ngIf="numReferencia">
        <p>El pago se realizó exitosamente. Le enviamos un comprobante al correo <b>{{email}}</b>. Ante cualquier inconveniente comuníquese con <a href="https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quer%C3%ADa%20hacerles%20la%20siguiente%20consulta:%20" target="_blank">soporte</a>.</p>
        <!-- <p class="">El pago se realizo exitosamente, se le enviaron detalles del pago al correo <b>{{email}}</b>.</p> -->
        <p class="">Número de referencia: <b class="color-text">{{numReferencia}}</b></p>
        <p class="">Código de Referencia:
          <b class="color-text">{{hashReferencia}}</b>
          <a (click)="copiarPortaPapeles()" class="ml-2 color-text">
           <i class="fas fa-copy"></i>
          </a>
       </p>
      </span>
      <span *ngIf="!numReferencia">
        <p class="">El pago se encuentra en proceso. Una vez finalizado, le enviaremos un comprobante al correo <b>{{email}}.</b></p>
        <p class="">En caso de no recibir el comprobante, comuníquese con <a href="https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quer%C3%ADa%20hacerles%20la%20siguiente%20consulta:%20" target="_blank">soporte</a>.</p>
        <p class="">Código de Referencia:
           <b class="color-text">{{hashReferencia}}</b>
           <a (click)="copiarPortaPapeles()" class="ml-2 color-text">
            <i class="fas fa-copy"></i>
           </a>
        </p>
      </span>
      <!-- <button mdbBtn type="button" block="true" class="d-block waves-light mb-0 col-6 m-0 mt-2 ml-auto btn-continuar text-white" routerLink="/" mdbWavesEffect>
          <i class="fas fa-arrow-left"></i>
          Ir a Home
      </button> -->
      <hr>
      <button *ngIf="redirecting!=true" mdbBtn type="button"  class="waves-light mb-0 m-0 mt-2 ml-auto btn-continuar text-white" routerLink="/" mdbWavesEffect>
          <i class="fas fa-arrow-left"></i>
          Ir a Home
      </button>
      <h4 *ngIf="redirecting==true && secondsToRedirect>0" class="text-center" style="z-index: 10">Serás redigido en <b class="text-primary">{{secondsToRedirect}}</b> segundos</h4>
      <h4 *ngIf="redirecting==true && secondsToRedirect==0" class="text-center" style="z-index: 10">Redirigiendo...</h4>
    
    </div>
  </div>
</div>

<!-- modal lista negra -->
<div mdbModal #modalAlertaFormulario class="modal fade center" tabindex="-1" role="dialog" [config]="{backdrop: 'static'}">
  <div class="modal-dialog  modal-notify modal-primary" role="Formulario caducado">
      <!--Content-->
      <div class="modal-content text-center" style="background: rgb(0,0,0,0) !important;">
          <!--Header-->
          <div class="modal-header d-flex justify-content-center modal-head">
              <p class="heading text-white">
                ¡ALERTA!
                <i class="ml-2 fas fa-exclamation-triangle text-white fa-lg"></i>
              </p>
          </div>
          <div class="m-0 py-1 transparente">
          </div>
          <!--Body-->
          <div class="modal-body modal-body-customize">
              <div>El formulario expiró, que desea hacer: </div>

              <div class="justify-content-center mt-4">
                <button class="waves-effect btn-volver" type="button" mdbBtn outline="true" (click)="goBack();" mdbWavesEffect>
                  <!-- <i class="fas fa-arrow-left"></i> -->
                  Volver
                </button>
                <button class="waves-effect text-white btn-continuar" type="submit" mdbBtn (click)="crearTokenFormulario();" mdbWavesEffect>
                  Recargar Formulario
                </button>
            </div>
          </div>
      </div>
      <!--/.Content-->
  </div>
</div>

<!-- Mensaje final usuario -->
<!-- <div class="layout-local" *ngIf="bandPagoRealizado">
  
</div> -->
