<div class="col-9 col-sm-9 d-flex p-0">
    <div class="d-inline" [ngClass]="{'circulo-rojo': prestamo.vencido==true, 'circulo-verde': prestamo.vencido!=true}"></div>
    <!-- <h4 class="my-auto ml-2 p-1 d-inline title-credit">Crédito {{prestamo.numeroPrestamo}} - {{prestamo.fechaPrestamo | date: "dd/MM/yyyy"}}</h4> -->
    <h4 class="my-auto ml-2 p-1 d-inline title-credit">Crédito {{prestamo.numeroPrestamo}}</h4>
</div>
<!-- <div class="col-2 col-sm-2 my-0 p-0">
    <span class="amount-credit">${{prestamo.importeRestante | number: ".2"}}</span> 
</div> -->
<div class="col-1 col-sm-1 my-0 p-0">
    <a (click)="showHideCard(i)" class="float-right mr-3"><i class="text-primary fas fa-chevron-down fa-2x"></i></a>
</div>

<div class="col-11 col-sm-11 my-0 p-0 card-item-content mt-2" [ngClass]="{'d-block':hideCards[i]!=true,'d-none':hideCards[i]==true}">
    <p class="my-auto py-1">Crédito por ${{prestamo.importePrestamo | number: ".2"}} solicitado el {{prestamo.fechaPrestamo | date: "dd/MM/yyyy"}} (${{prestamo.importeRestante | number: ".2"}} pendientes de pago)</p>
    <p class="my-auto py-1">Cuotas pendientes de pago:</p>
    <span *ngFor="let cuota of prestamo.cuotas;index as j">
        <app-prestamos-cuota [cuota]="cuota" [j]=j [prestamo]="prestamo"></app-prestamos-cuota>
    </span>
    <div class="row m-0 p-0 pt-3 align-items-center justify-content-sm-between" *ngIf="prestamo.permPagoParcial==true">
        <div class="col-12 col-sm-8 px-0 w-75 my-auto">
            <div class="md-form whiteness m-0">
                <input type="number" id="formPagoParcial_{{prestamo._id}}" class="form-control text-white no-arrows" [(ngModel)]="prestamo.pagoParcial" mdbInput>
                <label for="formPagoParcial_{{prestamo._id}}" class="text-white">Pagar una cuota parcial</label>
            </div>
        </div>
        <div class="col-12 col-sm-4 my-0 p-0 d-flex justify-content-center justify-content-sm-end" >
            <h5>
                <a mdbBtn type="button" color="secondary" class="waves-light my-auto px-3 py-1  rounded-pill" mdbWavesEffect (click)="abrirModalPagoParcial()">
                    <span class="font-weight-bold text-white">Pagar</span> 
                </a>
            </h5>
        </div>
    </div>
</div>