<div class="layout-1">
    <div class="container row flex-column py-4 px-2">
        <h2 class="text-center text-secondary" style="z-index: 10">SOLICITUD DE PRÉSTAMO</h2>
        <div class="col-lg-5 col-sm-10 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
          <p class="font-size-085 text-center">Completa los campos a continuación para solicitar tu préstamo:</p>
            <form [formGroup]="formPrestamo" class="d-flex flex-column h-100" (ngSubmit)="verifyForm()">
                <div class="row">
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="email" id="formNombre" class="form-control" formControlName="nombre" mdbInput>
                            <label for="formNombre">Nombre y Apellido *</label>
                            <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched">Nombre Inválido</mdb-error>
                            <mdb-success *ngIf="form_nombre.valid && form_nombre.touched">Nombre Válido</mdb-success>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="email" id="formEmailRegistro" class="form-control" formControlName="email" mdbInput>
                            <label for="formEmailRegistro">Tu Email *</label>
                            <mdb-error *ngIf="form_email.invalid && form_email.touched">Email Inválido</mdb-error>
                            <mdb-success *ngIf="form_email.valid && form_email.touched">Email Válido</mdb-success>
                        </div>
                    </div>
                    <div class="col-5 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="number" id="formDni" class="form-control no-arrows" formControlName="dni" mdbInput>
                            <label for="formDni">DNI *</label>
                            <mdb-error *ngIf="form_dni.invalid && form_dni.touched">DNI Inválido</mdb-error>
                            <mdb-success *ngIf="form_dni.valid && form_dni.touched">DNI Válido</mdb-success>
                        </div>
                    </div>
                    <div class="col-7 my-3">
                        <div class="md-form md-outline whiteness m-0">
                            <input type="number" id="formNumero" class="form-control no-arrows" formControlName="numCel" mdbInput>
                            <label for="formNumero">Número de Teléfono *</label>
                            <mdb-error *ngIf="form_numCel.invalid && form_numCel.touched">Número Inválido</mdb-error>
                            <mdb-success *ngIf="form_numCel.valid && form_numCel.touched">Número Válido</mdb-success>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="d-flex justify-content-between" style="color: gray; border-bottom: 1px solid lightgray;">
                          ¿Posee ingresos comprobables?
                          <mdb-checkbox formControlName="poseeIngresos" (change)="checkCheckBoxvalue($event);"></mdb-checkbox>
                        </div>
                    </div>
                    <div class="col-12 mt-3 mb-2" *ngIf="puede12==true">
                        <p class="mb-2 font-size-07 text-center text-uppercase font-weight-bold">Comprobante de Ingresos</p>
                        <div class="view overlay mb-2 text-center">
                        
                            <span>
                              <button type="button" mdbBtn color="light" rounded="true" size="sm" (click)="inputComprobanteClick()" mdbWavesEffect>
                                Subir Foto
                                <i class="fas fa-camera ml-1"></i>
                              </button>
                              <input type="file" formControlName="fotoIngresos" (change)="onFileChange($event)" mdbBtn color="light" accept="image/*" rounded="true" size="sm" [hidden]="true" class="inputFiles" id="inputComprobante" #inputComprobante mdbInput>
                              <p class="text-danger" style="font-size: .8rem;" *ngIf="form_fotoIngresosSource.errors?.errorFile">Debe subir una foto que demuestre que percibe ingresos</p>
                                <p class="text-success" style="font-size: .8rem;"  *ngIf="form_fotoIngresosSource.valid">Foto válida</p>
                        
                                <!-- <button type="button" mdbBtn color="light" rounded="true" size="sm" (click)="log(form_fotoIngresos);log(form_fotoIngresosSource);" mdbWavesEffect>
                                    check
                                    <i class="fas fa-camera ml-1"></i>
                                  </button> -->
                            </span>

                        </div>
                      </div>
                    <div class="col-12 my-3">
                      <div class="d-flex flex-column justify-content-center align-items-center">
                          <p class="mb-1 font-weight-bold text-center" *ngIf="puede12">Con ingresos comprobables, podés solicitar hasta $20.000 en hasta 12 cuotas:</p>
                          <p class="mb-1 font-weight-bold text-center" *ngIf="puede6">Sin ingresos comprobables, podés solicitar hasta $10.000 en hasta 6 cuotas:</p>
                      </div>
                    </div>
                    <div class="col-12 my-3">
                      <div class="md-form md-outline whiteness m-0">
                          <input type="number" id="formMonto" class="form-control no-arrows" formControlName="monto" #inputMonto (ngModelChange)="calcularCosto()" mdbInput>
                          <label for="formMonto">Monto solicitado *</label>
                          <mdb-error *ngIf="form_monto.errors?.required && form_monto.touched">Debe ingresar el monto</mdb-error>
                          <mdb-error *ngIf="form_monto.errors?.montoMenor0 && form_monto.touched">El monto debe ser mayor a $1000</mdb-error>
                          <mdb-error *ngIf="form_monto.errors?.montoMayor20000 && form_monto.touched">El monto máximo permitido es $20000</mdb-error>
                          <mdb-error *ngIf="form_monto.errors?.montoMayor10000 && form_monto.touched">El monto máximo permitido es $10000</mdb-error>
                          <mdb-success *ngIf="form_monto.valid && form_monto.touched">Monto Válido</mdb-success>
                      </div>
                   </div>
                  <div class="col-12 my-3">
                      <div class="md-form md-outline whiteness m-0">
                          <mdb-select [outline]="true" [options]="opcionesCuotas" formControlName="cantCuotas" (ngModelChange)="calcularCostoTotal()" label="Cantidad de cuotas *"></mdb-select>
                          <mdb-error *ngIf="form_cantCuotas.invalid && form_cantCuotas.touched">Cant. Inválida</mdb-error>
                      </div>
                  </div>
                    <div class="col-12 my-3 mb-5" *ngIf="totalCuota>0">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <p class="mb-1 font-weight-bold">Valor de la Cuota:</p>
                            <p class="mb-0 text-secondary font-size-14">${{totalCuota}}</p>

                        </div>
                    </div>

                    
                    <div class="col-12 my-3 mb-4">
                        <div class="d-flex justify-content-between position-relative"  style="color: gray; border-bottom: 1px solid lightgray;">
                            Acepto términos y condiciones
                            <mdb-checkbox formControlName="terminos"></mdb-checkbox>
                            <mdb-error *ngIf="form_terminos.invalid">Debe aceptar los términos y condiciones</mdb-error>
                        </div>
                    </div>
                    <div class="col-12 d-flex">
                        <re-captcha class="m-auto align-content-center my-3" formControlName ="recaptchaReactive" siteKey="{{recaptcha_KEY}}"></re-captcha>
                    </div>
                </div>

                    <!-- <div class="col-12 px-0 my-3" *ngIf="montoTotal>0">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <p class="mb-1 font-weight-bold">Monto Total:</p>
                            <p class="mb-0 text-secondary font-size-14">${{montoTotal}}</p>

                        </div>
                    </div> -->

              

                <div class="d-flex justify-content-between mb-2 mt-2">
                    <button mdbBtn [disabled]="formPrestamo.status!='VALID'" type="submit" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                        Solicitar Préstamo
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>
