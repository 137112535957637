<app-navbar></app-navbar>
<div class="row m-0 p-0 w-100" id="row-carousel">
    <img src="../../assets/home/prestamos-chicos2.png" height="100%" id="col_intro_1_img" class="d-none d-sm-block">
    <div class="col-l d-none d-lg-flex jumbotron-content h-100" id="col_intro_1">
    </div>
    <div class="col d-flex flex-column h-100 justify-content-center " id="col_intro_2">
        

        <!-- DESKTOP -->
        <span class="d-none d-lg-block text-center">
            <h1 class="font-size-500 text-center text-white mb-1 mt-5"><b>Tu Préstamo</b></h1>
            <h1 class="font-size-500 text-center text-white mb-4 mt-1"><b>¡Al instante!</b></h1>
            <h1 class="h1-responsive text-center text-white mt-2">Hasta $30.000</h1>
            <h1 class="h1-responsive text-center text-white">¡en pocos pasos!</h1>
            <!-- <a mdbBtn id="pagos_sumate_btn" color="primary" size="md" type="button" routerLink="{{urlSolicitar}}" class="waves-light mt-4 mx-auto rounded-pill" mdbWavesEffect>
                <h1 class="font-size-300 font-weight-bold  text-center mb-0" style="text-transform: none !important;">¡Pedilo YA!</h1>
            </a> -->
            <a mdbBtn id="pagos_sumate_btn" color="primary" size="md" type="button" class="waves-light mt-4 mx-auto rounded-pill" mdbWavesEffect>
                <h1 class="font-size-300 font-weight-bold text-white text-center mb-0" style="text-transform: none !important;">Proximamente</h1>
            </a>
        </span>
        <!-- MOBILE -->
        <span class="d-block d-lg-none text-center">
            <h1 class="font-size-300 text-center text-white mb-1 mt-5"><b>Tu Préstamo</b></h1>
            <h1 class="font-size-300 text-center text-white mb-4 mt-1"><b>¡Al instante!</b></h1>
            <h1 class="font-size-200 text-center text-white mt-2">Hasta $30.000</h1>
            <h1 class="font-size-200 text-center text-white">¡en pocos pasos!</h1>
            <!-- <a mdbBtn id="pagos_sumate_btn" color="primary" size="md" type="button" routerLink="{{urlSolicitar}}" class="waves-light mt-4 mx-auto rounded-pill" style="margin-top: 10vh !important;" mdbWavesEffect>
                <h1 class="font-size-200 font-weight-bold  text-center mb-0" style="text-transform: none !important;">¡Pedilo YA!</h1>
            </a> -->
            <a mdbBtn id="pagos_sumate_btn" color="primary" size="md" type="button" class="waves-light mt-4 mx-auto rounded-pill" style="margin-top: 10vh !important;" mdbWavesEffect>
                <h1 class="font-size-200 font-weight-bold  text-white text-center mb-0" style="text-transform: none !important;">Proximamente</h1>
            </a>
        </span>
    </div>
    
</div>


 
<section id="funcionamiento" class="d-block py-4 px-3 p-sm-7">
    <div class="container mx-auto">
        <div class="row wow align-items-center my-auto fadeInUp" data-wow-delay="0.25s">
            <!-- DESKTOP -->
            <div class="col-12 mr-auto d-none d-sm-block">
                <h1 class="font-size-400 font-weight-bold text-secondary text-center mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¿Cómo funciona?</h1>
                <h1 class="h2-responsive text-subtitulo text-center">En simples pasos, con acreditación en tu cuenta BestCard</h1>
            </div>
            <!-- MOBILE -->
            <div class="col-12 mr-auto d-block d-sm-none">
                <h1 class="font-size-200 font-weight-bold text-secondary text-center mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¿Cómo funciona?</h1>
                <h1 class="h2-responsive text-subtitulo text-center">En simples pasos, con acreditación en tu cuenta BestCard</h1>
            </div>
        </div>
        <!-- DESKTOP -->
        <div class="row wow align-items-center my-4 py-4 fadeInUp justify-content-center d-none d-sm-flex" data-wow-delay="0.25s">
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-lg" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_1.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <img src="../../assets/home/prestamos_flecha.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-lg" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_2.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <img src="../../assets/home/prestamos_flecha.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-lg" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_3.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <img src="../../assets/home/prestamos_flecha.svg" width="100%">
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-lg" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_4.svg" width="100%">
            </div>
        </div>
        <div class="row wow align-items-center my-4 py-4 fadeInUp justify-content-center d-none d-sm-flex" data-wow-delay="0.25s">
            <div class="col-12 position-relative mb-auto d-flex col-funcionamiento-lg" style="z-index: 200;">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center">Completa el formulario con tus datos. </h4>
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <!-- <img src="../../assets/home/prestamos_paso_3.svg" width="100%"> -->
            </div>
            <div class="col-12 position-relative mb-auto  d-flex col-funcionamiento-lg" style="z-index: 200;">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center">Eliges el monto que necesitas y cantidad de cuotas.</h4>
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <!-- <img src="../../assets/home/prestamos_paso_3.svg" width="100%"> -->
            </div>
            <div class="col-12 position-relative mb-auto  d-flex col-funcionamiento-lg" style="z-index: 200;">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center">Acércate por algunas de las sucursales con dni, recibo de sueldo y boleta de servicios</h4>
            </div>
            <div class="col-12 position-relative my-2 d-flex col-funcionamiento-sm" style="z-index: 200;">
                <!-- <img src="../../assets/home/prestamos_paso_3.svg" width="100%"> -->
            </div>
            <div class="col-12 position-relative mb-auto d-flex col-funcionamiento-lg" style="z-index: 200;">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center">Se acredita el dinero en tu cuenta BestCard</h4>
            </div>
        </div>
        
        <!-- MOBILE -->
        <div class="row wow align-items-center my-4 py-4 fadeInUp justify-content-center d-block d-sm-none" data-wow-delay="0.25s">
            <div class="col-12 position-relative my-2 d-flex w-100" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_1.svg" width="25%">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center my-auto">Completa el formulario con tus datos.</h4>
            </div>
            <hr class="funcionamiento-hr">
            <div class="col-12 position-relative my-2 d-flex w-100" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_2.svg" width="25%">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center my-auto">Eliges el monto que necesitas y cantidad de cuotas.</h4>
            </div>
            <hr class="funcionamiento-hr">
            <div class="col-12 position-relative my-2 d-flex w-100" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_3.svg" width="25%">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center my-auto">Acércate por algunas de las sucursales con dni, recibo de sueldo y boleta de servicios</h4>
            </div>
            <hr class="funcionamiento-hr">
            <div class="col-12 position-relative my-2 d-flex w-100" style="z-index: 200;">
                <img src="../../assets/home/prestamos_paso_4.svg" width="25%">
                <h4 class="h5-responsive text-secondary font-weight-bold text-center my-auto">Se acredita el dinero en tu cuenta BestCard</h4>
            </div>
        </div>
    </div>

</section>

<hr>

<section id="requisitos" class="d-block px-3 p-sm-7">
    <div class="container mx-auto">
        <div class="row wow align-items-center my-auto fadeInUp" data-wow-delay="0.25s">
            <!-- DESKTOP -->
            <div class="col-12 mr-auto d-none d-sm-block">
                <h1 class="font-size-400 font-weight-bold text-secondary text-center mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¿Qué necesitás?</h1>
                <h1 class="h2-responsive text-subtitulo text-center">Con estos simples requisitos, podrás acceder al préstamo</h1>
            </div>
            <!-- MOBILE -->
            <div class="col-12 mr-auto d-block d-sm-none">
                <h1 class="font-size-200 font-weight-bold text-secondary text-center mb-4 wow fadeInUp" data-wow-delay="0.25s"><i class="fas fa-caret-right text-primary mx-2"></i>¿Qué necesitás?</h1>
                <h1 class="h2-responsive text-subtitulo text-center">Con estos simples requisitos, podrás acceder al préstamo</h1>
            </div>
        </div>
        <div class="row wow align-items-center my-4 py-4 fadeInUp justify-content-center" id="requisitos_row_bg" data-wow-delay="0.25s">
            <div class="col-sm-6 d-none d-sm-flex position-relative my-2 text-center " style="height: 60vh;">
                <img src="../../assets/home/prestamos_celular.png" id="requisitos_img" class="mx-auto">
            </div>
            <div class="col-12 col-sm-6 position-relative my-2 pl-sm-4 d-block text-center">
                <h1 class="h2-responsive text-subtitulo my-4 py-sm-4 text-left"><i class="fas fa-caret-right text-primary mx-2"></i>Ser mayor de edad</h1>
                <h1 class="h2-responsive text-subtitulo my-4 py-sm-4 text-left"><i class="fas fa-caret-right text-primary mx-2"></i>Tener recibo de sueldo</h1>
                <h1 class="h2-responsive text-subtitulo my-4 pt-sm-4 pb-2 text-left"><i class="fas fa-caret-right text-primary mx-2"></i>Descargar la APP de BestCard</h1>
                <a color="white"  href="https://play.google.com/store/apps/details?id=ar.com.opentags.bestcard" target="_blank" class="d-block waves-light mb-2 text-center" mdbWavesEffect>
                    <img src="../../assets/home/boton_google play.svg">
                </a>
            </div>
            
        </div>
    </div>

</section>


<section id="section-consultas" class="d-flex">
    <div class="container my-auto">
        <div class="row">
            <div class="col-lg-5">
                <h2 class="h2-responsive font-weight-bold text-white mb-4 wow fadeInUp" data-wow-delay="0.25s">
                    Recibí atención personalizada todo el tiempo
                </h2>
                <p class="text-body-lg-1 text-white mb-0 wow fadeInUp" data-wow-delay="0.5s">
                    ¿Tenés alguna duda? No te preocupes, podés hacer la consulta que necesites
                </p>
            </div>
            <div class="col-lg-4 ml-auto">
                <!-- <div class="z-depth-1 bg-white px-4 pt-2 pb-3" style="border-radius: 8px;">
                    <div class="md-form md-outline my-3">
                        <input type="email" id="form1" class="form-control" mdbInput>
                        <label for="form1">Email</label>
                    </div>
                    <div class="md-form md-outline my-3">
                        <textarea type="text" id="form75" class="form-control md-textarea" rows="3" mdbInput></textarea>
                        <label for="form75">Consulta</label>
                    </div>
                    <button mdbBtn color="secondary" type="button" block="true" class="mx-auto d-block waves-light mb-2" mdbWavesEffect>
                        Enviar
                        <i class="fas fa-paper-plane ml-1"></i>
                    </button>
                </div> -->
                <!-- <a mdbBtn color="primary" type="button" class="mx-auto d-block waves-light mt-4" mdbWavesEffect target="_blank" href='https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20quería%20hacerles%20la%20siguiente%20consulta:%20'> -->
                    <a mdbBtn color="primary" id="btn-consulta" type="button" outline="true" class="mx-auto d-block waves-light mt-4" mdbWavesEffect href='https://wa.me/5492644803229' target="_blank">
                        Consultar por Whatsapp
                        <i class="fab fa-whatsapp ml-1 fa-lg"></i>
                    </a>
            </div>
        </div>
    </div>
</section>


<section id="whatsapp" class="d-flex">
    <div class="container my-auto">
        <div class="d-flex">
            <h2 class="h2-responsive text-white">
                <i class="fab fa-whatsapp fa-lg mx-2"></i>
                <b>¿Chateamos?</b>
                Envianos un Whatsapp a +54 9 264 4803229
            </h2>
        </div>
    </div>
</section>

<app-footer-general></app-footer-general>