import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-prestamos-home',
  templateUrl: './prestamos-home.component.html',
  styleUrls: ['./prestamos-home.component.scss']
})
export class PrestamosHomeComponent implements OnInit {

  urlSolicitar: any = ""

  constructor(private activatedRoute: ActivatedRoute, private ds: GlobalService) {
    this.activatedRoute.params.subscribe(params => {

      if(params.hash!=null) this.urlSolicitar = `/prestamos/solicitar/${params.hash}`
      else this.urlSolicitar = `/prestamos/solicitar`


      // ds.loader = true;
      ds.setReferidoPrestamo(params.hash).then(res => {
        // ds.loader = false;
        // this.ds.loader = false;
        let resInfo: any = res;
        // console.log(resInfo);
        if(resInfo.r) {
          if (resInfo.r.c == 0) {
            // this.jsonImportes = res['json']
          } else {
            this.ds.generateErrorAlert(resInfo.r.m);
          }
        } else {
          // this.ds.generateErrorAlert("Ocurrió un error inesperado");
        }
      });

    });
   }

  ngOnInit(): void {
  }

}
