import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { HomeComponent } from './home/home.component';
import { SolicitudprestamoComponent } from './solicitudprestamo/solicitudprestamo.component';
import { ConfirmacionprestamoComponent } from './confirmacionprestamo/confirmacionprestamo.component';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import { ReferidosComponent } from './referidos/referidos.component';
// import { EmpPaso1Component } from './registro-empresa/emp-paso1/emp-paso1.component';
// import { EmpPaso2Component } from './registro-empresa/emp-paso2/emp-paso2.component';
// import { EmpPaso3Component } from './registro-empresa/emp-paso3/emp-paso3.component';
// import { EmpPaso4Component } from './registro-empresa/emp-paso4/emp-paso4.component';
// import { EmpPaso5Component } from './registro-empresa/emp-paso5/emp-paso5.component';
// import { EmpPaso6Component } from './registro-empresa/emp-paso6/emp-paso6.component';
// import { EmpPaso6bComponent } from './registro-empresa/emp-paso6b/emp-paso6b.component';
// import { EmpPaso6cComponent } from './registro-empresa/emp-paso6c/emp-paso6c.component';
// import { EmpFinalComponent } from './registro-empresa/emp-final/emp-final.component';
// import { EmpVerificarComponent } from './registro-empresa/emp-verificar/emp-verificar.component';
import { LinksPagoComponent } from './links-pago/links-pago.component';
import { FormularioPagoComponent } from './formulario-pago/formulario-pago.component';
import { FormularioPago1Component } from './links-pago/formulario-pago1/formulario-pago1.component';
import { FormularioErrorComponent } from './links-pago/formulario-error/formulario-error.component';

import { ReguistroGuard } from './guards/reguistro.guard';
import { PagosComponent } from './pagos/pagos.component';
import { ConsultaWspComponent } from './consulta-wsp/consulta-wsp.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { BlogComponent } from './blog/blog.component';
import { EntradaBlogComponent } from './blog/entrada-blog/entrada-blog.component';
import { BotonArrepentimientoComponent } from './boton-arrepentimiento/boton-arrepentimiento.component';

// PRESTAMOS
import { PrestamosHomeComponent } from './prestamos-home/prestamos-home.component';
import { PrestamosComponent } from './prestamos/prestamos.component';
import { PrestamosSimuladorComponent } from './prestamos-simulador/prestamos-simulador.component';
import { PrestamosConsultasComponent } from './prestamos-consultas/prestamos-consultas.component';
import { InformeComponent as PrestamosConsultasInformeComponent } from './prestamos-consultas/informe/informe.component';

import { FranquiciasComponent } from './modules/franquicias/franquicias.component';

// OTROS
import { SubirDocumentacionComponent as PrestamosFranquiciaSubirDocumentacionComponent } from './franquicia/subir-documentacion/subir-documentacion.component';
import { RegIntroComponent } from './modules/registro/persona/pages/reg-intro/reg-intro.component';
import { TarjetaActivadaComponent } from './tarjeta-activada/tarjeta-activada.component';
import { TarjetaActivarComponent } from './tarjeta-activar/tarjeta-activar.component';
import { FinalizarIdentityComponent } from './pomelo/finalizar-identity/finalizar-identity.component';
import { BilleteraMarcaBlancaComponent } from './factory/billetera-marca-blanca/billetera-marca-blanca.component';
import { TycComponent } from './tyc/tyc.component';
// import { TestComponent } from './registro/test/test.component';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  { 
    path: 'franquicia',
    component: FranquiciasComponent,
    loadChildren: () => import('./modules/franquicias/franquicias.module').then(m => m.FranquiciasModule)
  },
  // { path: '', component: HomeComponent },
  {
    path: 'reg',
    loadChildren: () => import('./modules/registro/persona/persona.module').then(m => m.PersonaModule)
  },
  {
    path: 'emp',
    loadChildren: () => import('./modules/registro/empresa/empresa.module').then(m => m.EmpresaModule)
  },
  {
    path: 'bccg',
    loadChildren: () => import('./modules/form-cobranza/form-cobranza.module').then(m => m.FormCobranzaModule)
  },
  {
    path: 'kyc/validacion-identidad/:token',
    loadChildren: () => import('./modules/kyc/kyc.module').then(m => m.KycModule)
  },
  { path: 'registro', component: RegIntroComponent },
  { path: 'referidos', component: ReferidosComponent },
  { path: 'referidos/:hash', component: ReferidosComponent },
  { path: 'preguntas_frecuentes', component: PreguntasFrecuentesComponent},
  { path: 'prestamos', component: PrestamosHomeComponent },
  { path: 'prestamos/solicitar', component: PrestamosComponent},
  { path: 'prestamos/consulta', component: PrestamosConsultasComponent},
  { path: 'prestamos/consulta/informe/:hash', component: PrestamosConsultasInformeComponent},
  { path: 'prestamos/solicitar/:hash', component: PrestamosComponent},
  { path: 'prestamos/:hash', component: PrestamosHomeComponent },
  { path: 'simular-prestamo', component: PrestamosSimuladorComponent},

  { path: 'pagos', component: PagosComponent },
  { path: 'pago/:data', component: LinksPagoComponent },
  { path: 'pago/:data/:data2', component: LinksPagoComponent },
  
  { path: 'formulario/1/:data', component: FormularioPago1Component },
  { path: 'formulario/1/:data/:data2', component: FormularioPago1Component },
  { path: 'formulario/:id/:data', component: FormularioPago1Component },
  { path: 'formulario/:id/:data/:data2', component: FormularioErrorComponent },
  
  { path: 'consulta', component: ConsultaWspComponent },
  { path: 'baja', component: BotonArrepentimientoComponent },
  { path: 'sucursales', component: SucursalesComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:idPublico', component: EntradaBlogComponent },
  { path: 'franquicia/prestamo/documentos/:token', component: PrestamosFranquiciaSubirDocumentacionComponent },
  { path: 'visa/activar/post/:email/:empresa', component: TarjetaActivadaComponent },
  { path: 'visa/activar/:url', component: TarjetaActivarComponent },
  // { path: 'link-validacion-identidad/:token', component:  },
  
  { 
    path: 'validacion/identidad/finalizar/:idSession',
    component: FinalizarIdentityComponent
  },
  { path: 'tyc', component: TycComponent },
  { 
    path: 'factory/billetera-marca-blanca',
    component: BilleteraMarcaBlancaComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
