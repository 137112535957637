import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-marca-blanca',
  templateUrl: './contact-marca-blanca.component.html',
  styleUrls: ['./contact-marca-blanca.component.scss']
})
export class ContactMarcaBlancaComponent implements OnInit {
  name:any
  message:any
  phone:any
  puesto_seleccionado:any = "otro"
  
  puestos:any = {
    gerencial: false,
    comercial: false,
    tecnico: false,
    otro:true
  }

  data: any
  data_errors:any = {
    name: false,
    phone: false,
    message: false
  }

  constructor() { }

  ngOnInit(): void {}

  handleChangeRadio(value:any){
    this.puesto_seleccionado = value
    this.puestos = {
      gerencial: false,
      comercial: false,
      tecnico: false,
      otro:false,
      [value]: true
    }
  }

  onSubmit(){
    if (this.validateInputs()) {
      this.data = {
        nombre: this.name,
        tel: this.phone,
        puesto: this.puesto_seleccionado,
        mensaje: this.message
      } 
      
      this.name = ""
      this.phone = ""
      this.message = ""
      this.puesto_seleccionado = "otro"
      this.puestosReset()
    }
    
  }

  puestosReset(){
    this.puestos = {
      gerencial: false,
      comercial: false,
      tecnico: false,
      otro:true,
     
    }
  }

  validateInputs(){
    let validate = true
    if (this.name == "" || this.name == null) {
      this.data_errors.name = true
      validate = false
    }
    else{
      this.data_errors.name = false
    }
    if (this.phone == "" || this.phone == null) {
      this.data_errors.phone = true
      validate = false
    }
    else{
      this.data_errors.phone = false
    }
    if (this.message == "" || this.message == null) {
      this.data_errors.message = true
      validate = false
    }
    else{
      this.data_errors.message = false
    }
    return validate
  }

}
