import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechaVencimiento'
})
export class FechaVencimientoPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    
    let f= new Date(value);

    let fecha= f.toLocaleDateString().split('/');
    let hora= f.toLocaleTimeString().split(':');

    let dd= (fecha[0].length == 1)? '0'+fecha[0] : fecha[0];
    let MM= (fecha[1].length == 1)? '0'+fecha[1] : fecha[1];
    let aaaa= fecha[2];

    let hh= (hora[0].length == 1)? '0'+hora[0] : hora[0];
    let mm= (hora[1].length == 1)? '0'+hora[1] : hora[1];

    return `${dd}/${MM}/${aaaa} ${hh}:${mm}`;
  }

}
