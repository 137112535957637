import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { PrestamosConsultasService } from '../../services/prestamos-consultas.service';

@Component({
    selector: 'app-prestamos-cuota',
    templateUrl: './prestamos-cuota.component.html',
    styleUrls: ['./prestamos-cuota.component.scss']
})
export class PrestamosCuotaComponent implements OnInit {

    @Input() cuota: any;
    @Input() prestamo: any;
    @Input() j: any;

    constructor(private pc: PrestamosConsultasService) { }

    ngOnInit(): void {
    }

    abrirModal() {
        let cuota: any = {}
        for (let i = 0; i < this.prestamo.cuotas.length; i++) {
            if (this.prestamo.cuotas[i].numeroCuota == this.prestamo.numeroCuotaVencimiento) {
                cuota = this.prestamo.cuotas[i]; break;
            }
        }

        let detalleModalCuota = {
            title: `Pagando cuota n° ${this.prestamo.numeroCuotaVencimiento} del crédito ${this.prestamo.numeroPrestamo}`,
            hab1: (cuota.medios[1] != null) ? true : false,
            hab2: (cuota.medios[2] != null) ? true : false,
            hab3: (cuota.medios[3] != null) ? true : false,
            importe1: (cuota.medios[1] != null) ? cuota.medios[1].importe : null,
            importe2: (cuota.medios[2] != null) ? cuota.medios[2].importe : null,
            importe3: (cuota.medios[3] != null) ? cuota.medios[3].importe : null,
            recargo1: (cuota.medios[1] != null) ? cuota.medios[1].recargo : null,
            recargo2: (cuota.medios[2] != null) ? cuota.medios[2].recargo : null,
            recargo3: (cuota.medios[3] != null) ? cuota.medios[3].recargo : null,
            medio: null,
            recargo: null,
            importe: null,
            prestamo: this.prestamo
        }

        this.pc.abrirModal(detalleModalCuota);
    }

    showMsgPagarCuotaAnterior()
    {
        Swal.fire({
            title: '¡Atención!',
            html: `No puede realizar el pago de esta cuota porque hay otra cuota vencida o más próxima a vencer. Por favor, realice el pago de esa cuota y vuelva a intentarlo.`,
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#7d0c65',
            backdrop: `rgba(214,176,39,0.4)`,
            reverseButtons: true
        }).then((result) => {
            if (result.value == true) {
            }
        });
    }

}
