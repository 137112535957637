import { Component, OnInit, ViewChild } from '@angular/core';
import {Map,tileLayer,marker, icon} from 'leaflet';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {

  query: any = {};
  arreMarkers: any = [];
  arreDetalles: any = [];
  markerSelected: any;
  map: Map;
  sucursales: any = [];
  @ViewChild('modalMapa') modalFiltro: any;

  constructor(private route: ActivatedRoute, public gs: GlobalService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.query = params
    });
    this.loadMap();
  }

  loadMap(){
    this.map = new Map('mapId').setView([-31.5364942,-68.5314436], 14);
    tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmVzdGNhcmQiLCJhIjoiY2xnM2JnY2xsMGR3eTNrc3R4NXp2cG93byJ9.4BDqu4R0eDC2QLF1OqaSsA', {
          maxZoom: 25,
          attribution: 'Datos del mapa de &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, ' + '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' + 'Imágenes © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: 'mapbox/streets-v11'
      }).addTo(this.map);
    const that = this;
    setTimeout(function() {
      that.getSucursales();
    }, 1000);

  }

  loadingMarker: any = false;

  onClickMarker = (e) => {
    this.markerSelected = marker([e.latlng.lat, e.latlng.lng],{icon: e.sourceTarget.options.icon});
    this.markerSelected.remove();
    this.loadingMarker = true;
    setTimeout(() => {

      // if(sucursal.suc_bc)
      //   { this.arreMarkers.push(marker([sucursal.lat, sucursal.lng],{icon: this.BCIcon}).on('click', this.onClickMarker).addTo(this.map)); }


      this.markerSelected.addTo(this.map).bindPopup(`<div>
        <div class="spinner-grow txt-primary" role="status" *ngIf="loadingMarker">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      `).openPopup();
      let markerIndex = this.sucursales.findIndex(x => parseFloat(x.lat).toString(7) === parseFloat(e.latlng.lat).toString(7) && parseFloat(x.lng).toString(7) === parseFloat(e.latlng.lng).toString(7) );

      if(this.arreDetalles[this.sucursales[markerIndex].id]!= null) {
        this.loadingMarker = false;

        let det = this.arreDetalles[this.sucursales[markerIndex].id];
          this.markerSelected.remove();
          if(det.horario!=null)
          {
            this.markerSelected.addTo(this.map).bindPopup(`<b style="font-weight:bold !important">${det.nombreEmpresa}</b><br>
            <span><i class="fas fa-map mr-1"></i>${det.domicilio}</span><br>
            <span><i class="fas fa-clock mr-1"></i>${det.horario}</span>`).openPopup();

          }
          else
          {
            this.markerSelected.addTo(this.map).bindPopup(`<b style="font-weight:bold !important">${det.nombreEmpresa}</b><br>
            <span><i class="fas fa-map mr-1"></i>${det.domicilio}</span>`).openPopup();
          }
      }
      else
      {
        this.gs.sucursalDetalle(this.sucursales[markerIndex].id).then(res => {
          let resData: any = res;

          if(resData.r && resData.r.c ==0)
          {
            this.markerSelected.remove();
            this.arreDetalles[this.sucursales[markerIndex].id] = resData.detalle;
            setTimeout(() => {
              if(resData.detalle.horario!=null)
              {
                this.markerSelected.addTo(this.map).bindPopup(`<b style="font-weight:bold !important">${resData.detalle.nombreEmpresa}</b><br>
                <span><i class="fas fa-map mr-1"></i>${resData.detalle.domicilio}</span><br>
                <span><i class="fas fa-clock mr-1"></i>${resData.detalle.horario}</span>`).openPopup();

              }
              else
              {
                this.markerSelected.addTo(this.map).bindPopup(`<b style="font-weight:bold !important">${resData.detalle.nombreEmpresa}</b><br>
                <span><i class="fas fa-map mr-1"></i>${resData.detalle.domicilio}</span>`).openPopup();
              }
            }, 1);
            this.loadingMarker = false;
          }
          else if(resData.r && resData.r.c ==1)
          {
            this.markerSelected.remove();
            this.gs.generateErrorAlert('Ocurrió un error al cargar el detalle')
            this.loadingMarker = false;
          }
          else
          {
            this.markerSelected.remove();
            this.gs.generateErrorAlert('Ocurrió un error inesperado al cargar el detalle')
            this.loadingMarker = false;
          }
        });

      }
    }, 1);
  }

  forzarBusqueda()
  {

    this.limpiarMapa().then( () => {
      this.modalFiltro.hide();
        this.getSucursales();
      })
  }

  getSucursales() {
    const filtro = {
      nombre: this.query.nombre,
      tipo: this.query.tipo,
    };

      this.gs.obtenerSucursales(filtro).then(res => {

      let resData: any = res;
      if(resData.r && resData.r.c==0)
      {
        this.sucursales = resData.arraySucursales;
        this.sucursales.forEach(sucursal => {
          // this.arreMarkers.push(marker([sucursal.lat, sucursal.lng]).on('click', this.onClickMarker).addTo(this.map));
          if(sucursal.suc_bc)
            { this.arreMarkers.push(marker([sucursal.lat, sucursal.lng],{icon: this.BCIcon}).on('click', this.onClickMarker).addTo(this.map)); }
            else if(sucursal.retiros)
            { this.arreMarkers.push(marker([sucursal.lat, sucursal.lng],{icon: this.RetIcon}).on('click', this.onClickMarker).addTo(this.map)); }
            else
            { this.arreMarkers.push(marker([sucursal.lat, sucursal.lng],{icon: this.ComerIcon}).on('click', this.onClickMarker).addTo(this.map)); }
        });
        // this.gs.loader = false;
      }
      else if(resData.r && resData.r.c==1)
      {
        this.gs.loader = false;
        this.gs.generateErrorAlert('Ocurrió un error cargando el mapa')
      }
    });
  }

  limpiarMapa()
  {
    return new Promise((resolve,reject) => {
      for(let i=0;i<this.arreMarkers.length;i++)
      {
        this.map.removeLayer(this.arreMarkers[i])
      }
      this.arreMarkers = []

      if(this.markerSelected!=null)
      {
        this.map.removeLayer(this.markerSelected)
        this.markerSelected = null;
      }

      resolve(true)
    })
  }

  BCIcon = icon({
    iconUrl: '/assets/home/ICONO_SUCURSAL_BC.png',
    shadowUrl: '/assets/home/marker-shadow.png',
    iconSize:     [25, 41], // size of the icon
    shadowSize:   [25, 41], // size of the shadow
    iconAnchor:   [12.5, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 2],  // the same for the shadow
    popupAnchor:  [0,5] // point from which the popup should open relative to the iconAnchor
  });

  RetIcon = icon({
    iconUrl: '/assets/home/ICONO_RETIRO_BC.png',
    shadowUrl: '/assets/home/marker-shadow.png',
    iconSize:     [25, 41], // size of the icon
    shadowSize:   [25, 41], // size of the shadow
    iconAnchor:   [12.5, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 2],  // the same for the shadow
    popupAnchor:  [0,5] // point from which the popup should open relative to the iconAnchor
  });

  ComerIcon = icon({
    iconUrl: '/assets/home/ICONO_COMERCIO_BC.png',
    shadowUrl: '/assets/home/marker-shadow.png',
    iconSize:     [25, 41], // size of the icon
    shadowSize:   [25, 41], // size of the shadow
    iconAnchor:   [12.5, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [8, 2],  // the same for the shadow
    popupAnchor:  [0,5] // point from which the popup should open relative to the iconAnchor
  });

  opcionesTipos = [
    {value: 'recargas', label: 'Recargar Saldo'},
    {value: 'retiros', label: 'Retirar Saldo'},
    {value: 'prestamos', label: 'Validar préstamos'},
    {value: '', label: 'Todos'},
  ]
}
