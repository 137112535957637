<div class="row m-0 p-0 align-items-center justify-content-sm-between" *ngIf="cuota.mostrar==true">
    <div class="col-auto my-0 p-0 text-center text-sm-left">
        <span>Cuota n° {{cuota.numeroCuota}}/{{prestamo.cantCuotas}} - VTO: {{cuota.vencimiento | date: "dd/MM/yyyy"}} - ${{cuota.importeRestante | number: ".2"}}</span>
    
        <span *ngIf="cuota.vencida==true" class="px-2 ml-2 bg-danger text-white rounded font-weight-bold">Vencida</span>
        <span *ngIf="cuota.venceHoy==true" class="px-2 ml-2 bg-danger text-white rounded font-weight-bold">Vence hoy</span>
    </div>
    <!-- <div class="col-auto">
        <span *ngIf="cuota.vencida==true" class="px-2 ml-2 bg-danger text-white rounded font-weight-bold">
            Vencida
        </span>
        <span *ngIf="cuota.venceHoy==true" class="px-2 ml-2 bg-danger text-white rounded font-weight-bold">
            Vence hoy
        </span>
    </div> -->
    <div class="col-12 col-sm-4 my-0 py-1 px-0 d-flex justify-content-center justify-content-sm-end" *ngIf="cuota.pagar==true">
        <h5 class="my-auto">
            <a mdbBtn type="button" color="secondary" class="waves-light px-3 py-1 rounded-pill" mdbWavesEffect (click)="abrirModal()">
                <span class="font-weight-bold text-white">Pagar Cuota</span> 
            </a>
        </h5>
    </div>
    
    <div class="col-12 col-sm-4 my-0 py-1 px-0 d-flex text-right justify-content-sm-end justify-content-center" *ngIf="cuota.pagar!=true">
        <h5 class="my-auto">
            <a mdbBtn type="button" color="light" class="waves-light px-3 py-1 rounded-pill" (click)="showMsgPagarCuotaAnterior()">
                <span class="font-weight-bold text-dark">
                    Debe pagar la cuota anterior
                </span> 
            </a>
        </h5>
    </div>
    <!-- <hr class="mb-0 mt-1 w-100"> -->
    <hr class="mb-0 mt-1 w-100" style="border-color: #ced4da !important" *ngIf="prestamo.permPagoParcial==true || j<prestamo.cuotas.length-1">
</div>