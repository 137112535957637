import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
// Allow decimal numbers and negative values
private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]{0,1}){0,1}$/g);
// Allow key codes for special events. Reflect :
// Backspace, tab, end, home
private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

constructor(private el: ElementRef) {
}
//@HostListener('keyup', ['$event'])  //para celular
 @HostListener('keydown', ['$event']) //para navegador

// onKeyDown(event: KeyboardEvent) {
//   // Allow Backspace, tab, end, and home keys
//   if (this.specialKeys.indexOf(event.key) !== -1) {
//     return false;
//   }
//   else if(event.key == "e")
//   {
//     return false;
//   }
//   else return true;
// }

onKeyUp(event: KeyboardEvent) {
  // Allow Backspace, tab, end, and home keys
  if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
  }
  let current: string = this.el.nativeElement.value;
  const position = this.el.nativeElement.selectionStart;

  if (event.key == ".")
  {
      // if(current=="") this.el.nativeElement.value = current.slice(0,current.length-1)
      if (current.includes('.')) this.el.nativeElement.value = current.slice(0,current.length-1)
      else return true;
  }
  else if(event.key == "e")
  {
    this.el.nativeElement.value = current.slice(0,current.length-1)
  }
  else if (current && !String(current).match(this.regex)) {
    
    this.el.nativeElement.value = current.slice(0,current.length-1)
  }
}

// @HostListener('keypress', ['$event'])

// onKeyPress(event: KeyboardEvent) {
//   // Allow Backspace, tab, end, and home keys
//   if (this.specialKeys.indexOf(event.key) !== -1) {
//     return;
//   }
//   let current: string = this.el.nativeElement.value;
//   const position = this.el.nativeElement.selectionStart;
//   // console.log('this.el');
//   // console.log(this.el);
//   // const next: string = [current.slice(0, position), event.key, current.slice(position)].join('');

//   let value
//   if(current=="") value = "0.00"
//   else value = current

//   if(['0','1','2','3','4','5','6','7','8','9'].includes(event.key))
//   {
//     value = value.concat(event.key)
//     let value2 = parseFloat(value)
//     value2 = value2*10;
//     this.el.nativeElement.value = parseFloat(value2.toString(".2"))
//     return false;
//   }
//   else return false;
// }
/*
*
*<input  appTwoDigitDecimaNumber>
*
*/

} 
