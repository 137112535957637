
<div id="contact" class="contact-marca-blanca">
    <div class="container-title-contact container">
        <h1 class="title-contact">CONTÁCTANOS</h1>
        <img src="../../../../assets/marca-blanca/underlined.svg" alt="underlined">
    </div>
    <div class="container-contact container">
        <div class="container-cards-contact">
            <a  href="mailto:comercial@bestcard.com.ar">
                <div class="card">
                    <img src="../../../../assets/marca-blanca/iconos/icon-letter.svg" alt="">
                    <h4 class="link-contact">Email</h4>
                </div>
            </a>
            <a  href="https://goo.gl/maps/Yan1FbjxRNPK7vEL7" target="_blank">
                <div class="card">
                    <img src="../../../../assets/marca-blanca/iconos/icon-marker.svg" alt="">
                    <h4 class="link-contact">Mitre 425 este</h4>
                </div>
            </a>
            <a  href="https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20equipo%20de%20BestCard,%20me%20comunico%20para%20saber%20más%20sobre%20el%20servicio%20de%20Billetera%20Marca%20Blanca" target="_blank">
                <div class="card">
                    <img src="../../../../assets/marca-blanca/iconos/icon-whatsapp.svg" alt="">
                    <h4 class="link-contact">2645800950</h4>
                </div>
            </a>
        </div>
       
        <div class="form" *ngIf="false">
            <div class="container-inputs">
                <label class="label-input" for="">
                    Nombre
                    <input [(ngModel)]="name" type="text" placeholder="María Belén">
                    <span class="input-error" *ngIf="data_errors.name">*Debe completar este campo</span>
                </label>
                <label class="label-input" for="">
                    Teléfono
                    <input [(ngModel)]="phone" type="text" placeholder="2646123456">
                    <span class="input-error" *ngIf="data_errors.phone">*Debe completar este campo</span>
                </label>
            </div>
            <div class="container-puesto">
                <label class="label-input">Puesto</label>
                <div class="container-inputs">
                    <label class="label-puesto active" *ngIf="puestos.gerencial" (click)="handleChangeRadio('gerencial')">Gerencial</label>
                    <label class="label-puesto" *ngIf="!puestos.gerencial" (click)="handleChangeRadio('gerencial')">Gerencial</label>
                    
                    <label class="label-puesto active" *ngIf="puestos.tecnico" (click)="handleChangeRadio('tecnico')">Técnico</label>
                    <label class="label-puesto" *ngIf="!puestos.tecnico" (click)="handleChangeRadio('tecnico')">Técnico</label>
                    
                    <label class="label-puesto active" *ngIf="puestos.comercial" (click)="handleChangeRadio('comercial')">Comercial</label>
                    <label class="label-puesto" *ngIf="!puestos.comercial" (click)="handleChangeRadio('comercial')">Comercial</label>
                    
                    <label class="label-puesto active" *ngIf="puestos.otro" (click)="handleChangeRadio('otro')">Otro</label>
                    <label class="label-puesto" *ngIf="!puestos.otro" (click)="handleChangeRadio('otro')">Otro</label>
                </div>
            </div>
            <label class="label-textarea" for="">
                Mensaje
                <textarea placeholder="Tu mensaje" [(ngModel)]="message"></textarea>
                <span class="input-error" *ngIf="data_errors.message">*Debe completar este campo</span>
            </label>
            <div class="container-btn">
                <app-btn-pink-bg text="Enviar" icon="../../../../assets/marca-blanca/iconos/icon-paper-plane.svg" (click)="onSubmit()"></app-btn-pink-bg>
            </div>
        </div>
        <!-- PLACEHOLDER PARA OCULTAR EL FORM Y QUE NO SE ROMPA EL ESTILO -->
        <div class="placeholder-form" *ngIf="true">
            
        </div>
    </div>
    
    <div class="container-background">
        <img class="left" src="../../../../assets/marca-blanca/background-left-contact.svg">
        <img class="right" src="../../../../assets/marca-blanca/background-right-contact.svg">
    </div>
</div>