import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-btn-white',
  templateUrl: './btn-white.component.html',
  styleUrls: ['./btn-white.component.scss']
})
export class BtnWhiteComponent implements OnInit {

  valor:any

  @Input() id: number
  @Input() text: string;
  @Input() icon: string;
  @Input() active: string

  @Output() eventoHijo = new EventEmitter<object>();

  constructor() { 
  }
  
  ngOnInit(): void {
  }

  enviarPadre() {
    let active:boolean

    if (!this.active) {
      active = true
    }
    else if (this.active == "true") {
      active = false
    }
    else{
      active = true
    }


    this.eventoHijo.emit({id: this.id, active})
  }
}
