<app-navbar></app-navbar>
<div class="layout-local mt-5">
    
    <div class="container row flex-column py-4 px-0 px-sm-2 ">

        <!-- CARD 1 - CONSULTA  -->
        <div class="col-12 col-sm-12 mx-auto" *ngIf="testError==false">
            <div class="m-0 p-0">
                <div class="col-12 mx-auto d-flex flex-column">
                    <div class="col-10 col-sm-6 p-2 titulo m-0 ml-4 d-flex">
                        <span class="d-flex align-items-center text-white text-uppercase ml-2 text-truncate title-label">
                            Consultá el estado de tu cuenta
                        </span>
                        <!-- <img class="ml-auto mr-2 img-fluid" src="../../assets/home/icono_pago_movil.png" alt=""> -->
                        <!-- <i class="fas fa-hand-holding-usd"></i> -->
                    </div>
                </div>

                <!-- PASO 1 -->
                <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2 card-data" *ngIf="paso>=1">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-12 col-sm-3 my-3">
                            <div class="row rounded-pill mx-2" style="background-color:#9899a3; height:3.8rem; padding:8px;">
                                <div class="col-auto d-flex justify-content-center align-items-center"
                                    style="background-color:#d9d9d9; width:2.5rem; background-color:#d9d9d9; border-radius:50%; height:2.5rem;">
                                    <i class="fas fa-id-card"></i>
                                </div>

                                <div class="col-auto w-75">
                                    <div class="md-form whiteness m-0">
                                        <input type="text" id="formDni" class="form-control text-white" [(ngModel)]="dni" autocomplete="off" (ngModelChange)="prestamos=null" mdbInput>
                                        <label for="formDni" class="text-white">DNI*</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 my-3">
                            <div class="row rounded-pill  mx-2" style="background-color:#9899a3; height:3.8rem; padding:8px;">
                                <div class="col-auto d-flex justify-content-center align-items-center"
                                    style="background-color:#d9d9d9; width:2.5rem; background-color:#d9d9d9; border-radius:50%; height:2.5rem;">
                                    <i class="fas fa-venus-mars"></i>
                                </div>

                                <div class="col-auto w-75">
                                    <div class="md-form md-outline2 whiteness m-0">
                                        <mdb-select class="text-white" [options]="opcionesSexo" label="Sexo*" [(ngModel)]="sexo" (ngModelChange)="prestamos=null"></mdb-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 my-3 d-flex justify-content-center">
                            <re-captcha class="m-auto align-content-center my-3" [(ngModel)] ="recaptcha" siteKey="{{recaptcha_KEY}}"></re-captcha>
                        </div>

                        <div class="col-12 col-sm-2 my-3">
                            <button mdbBtn type="button" color="primary" block="true" class="d-block waves-light m-0 rounded-pill" mdbWavesEffect (click)="consultar()">
                                BUSCAR
                            </button>
                        </div>
                    </div>

                    <!-- <div class="row d-flex justify-content-center">
                        <div class="col-12 col-sm-4 my-3 d-flex justify-content-center">
                            <re-captcha class="m-auto align-content-center my-3" [(ngModel)] ="recaptcha" siteKey="{{recaptcha_KEY}}"></re-captcha>
                        </div>
                    </div> -->

                </div>

            </div>
        </div>

        <!-- CARD 2 -->
        <div class="col-12 mx-auto" *ngIf="testError==false">
            
            <!-- PASO 2  -->
            <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2 card-data mt-4" *ngIf="paso==2 && prestamos?.length>0">
                <span *ngIf="prestamos?.length>0">

                    <div class="row d-flex justify-content-center justify-content-sm-start mb-3">
                        <div class="col-10 col-sm-5 rounded-pill mx-sm-5 px-0 d-flex align-items-center" style="height:3.5rem; width:40%; background-color:#9899a3; padding:10px;">
                            <div class="circulo"></div>
                            <span style="font-size:1.2rem; margin-left:2rem;" class="text-white" >Tenés {{prestamos.length}} créditos pendientes</span>
                        </div>
                    </div>
                
                    <div *ngFor="let prestamo of prestamos;index as i">
                        <app-prestamo-detalle class="row mx-2 card-item prestamo-detalle-component d-flex justify-content-around align-items-center py-3 text-white" [prestamo]="prestamo" [i]=i></app-prestamo-detalle>
                    </div>
                </span>
            </div>
        </div>

        <!-- CARD 2 -->
        <div class="col-12 mx-auto" *ngIf="testError==false">
            
            <!-- PASO 2  -->
            <div class="col-12 mx-auto d-flex flex-column z-depth-1 rounded-lg white py-2 card-data mt-4" *ngIf="prestamos?.length==0">

                    <div class="row d-flex justify-content-center justify-content-sm-start mb-0">
                        <div class="col-10 col-sm-5 rounded-pill mx-sm-5 px-0 d-flex align-items-center" style="height:3.5rem; width:40%; background-color:#9899a3; padding:10px;">
                            <div class="circulo"></div>
                            <span style="font-size:1.2rem; margin-left:2rem;" class="text-white" >No tenés créditos pendientes</span>
                        </div>
                    </div>
            </div>
        </div>

        <!-- <div class="row card-data d-block m-3 mt-4 row z-depth-1" *ngIf="prestamos?.length==0">
            <span>
                <h5 class="text-center text-gray py-3 m-0">No tenés créditos pendientes</h5>
            </span>
        </div> -->

        <div class="col-12 col-sm-8 mx-auto" *ngIf="testError==true">
            <h4 class="text-center mb-4 mt-4 py-1 font-weight-bold text-white">Acceso Denegado</h4>
        </div>

        <div class="col-12 col-sm-6 mx-auto mt-4 ">
            <div class="row h-100 m-0 p-0">
                <div class="col-12 d-flex flex-wrap align-items-center">
                    <img src="../../assets/home/logobc.png" width="30%" class="mx-auto">
                </div>
            </div>
        </div>
    </div>
    
</div>

<app-modal-cuota></app-modal-cuota>

<app-footer-general></app-footer-general>
