import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Options } from '@angular-slider/ngx-slider';
import {Title} from "@angular/platform-browser";
import { ModalDirective } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-subir-documentacion',
    templateUrl: './subir-documentacion.component.html',
    styleUrls: ['./subir-documentacion.component.scss']
})
export class SubirDocumentacionComponent implements OnInit {

    idSolicitud: any = null

    urlQr: any = ""
    documentacion: any = null
    msgError: any = null

    itemsDocumentacion = {
        'recibosueldo': { label: "Recibo de sueldo", labelCliente: "Recibo de sueldo", subitems: ['Debe tener al menos 6 meses de antigüedad en el trabajo', 'Verificar el importe bruto', 'Verificar el importe neto'] },
        'dni': { label: "Fotocopia del Frente y Dorso del DNI", labelCliente: "Fotocopia del Frente y Dorso del DNI", subitems: ['El nombre coincide con el del usuario en su totalidad', 'La fecha de nacimiento coincide con la del usuario', 'La foto es del usuario'] },
        'boletaservicio': { label: "Boleta de Servicio a nombre del cliente", labelCliente: "Boleta de Servicio a tu nombre", subitems: ['El domicilio de la boleta coincide con el del usuario'] },
        'pagare': { label: "Pagaré del préstamo", labelCliente: "Pagaré del préstamo", subitems: ['El pagaré ha sido firmado'] }
    }

    jsonDocumentosSubidos = { }

    token: any = null

    constructor(private rutaActiva: ActivatedRoute, public ds: GlobalService) {

        this.token = this.rutaActiva.snapshot.params.token;

        if (this.token == null) {
            this.ds.generateErrorAlert("Ocurrió un error al procesar la solicitud")
        }
        else {
            this.obtenerInformacion();
        }

    }

    
    ngOnInit(): void {
        
    }

    actualizar()
    {
        this.obtenerInformacion()
    }

    obtenerInformacion()
    {
        this.ds.httpPostNoToken("3", "prestamos/franquicia/api/v1/documentacion/obtener/public", {}, { 'x-token-solicitud': this.token }).then((res: any) => {

            if (res && res.r && res.r.c == 0) {

                this.msgError = null
                this.idSolicitud = res.idSolicitud

                let auxDoc = res.docNecesaria
                if(res.docRegistrados!=null)
                {
                    this.jsonDocumentosSubidos= res.docRegistrados
                }
                else
                {
                    for(let i=0;i<auxDoc.length;i++)
                    {
                        this.jsonDocumentosSubidos[auxDoc[i]] = []
                    }
                }
                if(res.url) this.urlQr = res.url
                this.documentacion = auxDoc


            }
            else if (res && res.r && res.r.c == 1) {
                this.msgError = res.r.m
                // this.ds.generateErrorAlert(res.r.m)
            }
            else {
                this.msgError = "Ocurrió un error inesperado"
                // this.ds.generateErrorAlert("Ocurrió un error inesperado")
            }
            
        }).catch(error => { return })
    }
    

    async onFileSelected($event, doc) {
        let fileToRead = []; 
        let fileReader = null;
        if($event.target.files==null || $event.target.files.length==0) return
        fileToRead[0] = $event.target.files[0];

        this.ds.generateWaitingAlert("Subiendo Archivo")
        let subir: any = await this.sendPhoto(doc,fileToRead[0])
        if(subir && subir.r && subir.r.c==0)
        {
            if(subir.docRegistrados!=null) this.jsonDocumentosSubidos=subir.docRegistrados
            this.ds.generateSuccessAlert("El archivo se subió correctamente")
        }
        else if(subir && subir.r && subir.r.c==1) this.ds.generateErrorAlert(subir.r.m)
        else this.ds.generateErrorAlert('Ocurrió un error inesperado al subir el archivo')

    }

    sendPhoto(doc,filepath,)
    {
        const that = this;
        return new Promise(function (resolve, reject) {
            const fd = new FormData();
            fd.append('file', filepath);

            that.ds.http.post(that.ds.dominio3 + `prestamos/franquicia/api/v1/solicitud/${that.idSolicitud}/documentacion/${doc}/registrar/public`, fd, that.ds.getHttpOptionsFile_V2({'x-token-solicitud': that.token}))
                .subscribe(resData => {
                // console.log(resData);
                let res: any = resData;
                resolve(res);
                }, error => {
                console.log('error', error)
                resolve(false);
            });
        });
    }

    clickInput(doc)
    {
        document.getElementById(`input_${doc}`).click()
    }

    eliminarArchivo(doc,i)
    {
        Swal.fire({
            title: '¡Atención!',
            text: '¿Está seguro de que desea eliminar el archivo?',
            icon: 'warning',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#d4a01e',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            backdrop: `rgba(214,176,39,0.4)`,
            reverseButtons: true
          }).then((result) => {
            if(result.value==true)
            {
                this.ds.generateWaitingAlert("Eliminando")
                this.ds.httpPostNoToken('3',`prestamos/franquicia/api/v1/solicitud/${this.idSolicitud}/documentacion/${doc}/elemento/${i}/eliminar/public`,{},{'x-token-solicitud': this.token}).then((res:any) => {
        
                    if(res && res.r && res.r.c==0)
                    {
                        if(res.docRegistrados!=null)
                        {
                            this.jsonDocumentosSubidos=res.docRegistrados
                        }
                        this.ds.generateSuccessAlert("Archivo eliminado")
                    }
                    else if(res && res.r && res.r.c==1) this.ds.generateErrorAlert(res.r.m)
                    else this.ds.generateErrorAlert('Ocurrió un error inesperado al eliminar el archivo')
                })
                
            }
          });
    }

    @ViewChild('modalPreview', {static: false}) modalPreview: ModalDirective; //toma el modal de la plantilla

    imgPreview: any;
    showModalPreview(img){
        this.imgPreview = img
        this.modalPreview.show()
    }

    
    @ViewChild('modalCodigoQR', {static: false}) modalCodigoQR: ModalDirective; //toma el modal de la plantilla
    showModalCodigoQR(){
        this.modalCodigoQR.show()
    }

}