import { Component, OnInit } from '@angular/core';
import { FranquiciaService } from './services/franquicia.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-franquicias',
  templateUrl: './franquicias.component.html',
  styleUrls: ['./franquicias.component.scss']
})
export class FranquiciasComponent implements OnInit {

  infoFranquiciaReady = false;

  constructor() { }

  ngOnInit(): void {
  }

}
