<mdb-carousel [controls]="true" [indicators]="true" [animation]="'fade'">
    <mdb-carousel-item>
      <img
        src="https://mdbootstrap.com/img/Photos/Slides/img%20(15).webp"
        class="d-block w-100"
        alt="..."
      />
      <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img
        src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).webp"
        class="d-block w-100"
        alt="..."
      />
      <div class="carousel-caption d-none d-md-block">
        <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <img
        src="https://mdbootstrap.com/img/Photos/Slides/img%20(23).webp"
        class="d-block w-100"
        alt="..."
      />
      <div class="carousel-caption d-none d-md-block">
        <h5>Third slide label</h5>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
  
  <div class="row d-flex justify-content-center mt-5">
    <div class="col-5">
      <h3>Lorem ipsum dolor sit amet consectetur</h3>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia architecto
        corporis unde provident eaque repellat necessitatibus molestiae, placeat
        sit nam labore itaque natus officiis, hic nisi? Deleniti possimus eos ad.
      </p>
    </div>
  </div>