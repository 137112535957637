import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Title } from "@angular/platform-browser";
import { ModalDirective } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-informe',
    templateUrl: './informe.component.html',
    styleUrls: ['./informe.component.scss']
})
export class InformeComponent implements OnInit {

    hash: any = null
    detallePago: any = null
    detallePrestamo: any = null
    paso: any = 1
    tsBusqueda: any = null
    msgError: any = null
    enProceso: any = false

    constructor(private activatedRoute: ActivatedRoute, public ds: GlobalService, private router: Router) {

        this.hash = this.activatedRoute.snapshot.params.hash

        if (this.hash != null) {
            this.buscarInforme()
        }

    }

    buscarInforme(open: boolean = false) {
        let data = { informe: this.hash }

        this.ds.generateWaitingAlert()
        this.ds.httpPostNoToken_V2(3, "prestamos/publico/api/v1/consulta/pendientes/informe", data).subscribe((res: any) => {
            this.ds.closeWaitingAlert()
            if (res && res['r'] && res['r'].c == 0) {
                this.detallePago = res.detallePago

                if(this.detallePago==null) { 
                    if(res.enProceso==true) {this.msgError = "Tu pago está siendo procesado"; this.enProceso=true }
                    else this.msgError = "El informe que estás buscando no existe" 
                }
                else
                {
                    this.detallePrestamo = res.detallePrestamo
                    this.tsBusqueda = new Date().getTime()
                    if(open) window.open(this.detallePago.comprobante, '_blank').focus();
                }
                this.paso = 2

            }
            else if (res && res['r'] && res['r'].c == 1) {
                this.msgError = res['r'].m
                this.paso = 2
                // this.ds.generateErrorAlert(res['r'].m)
                // this.router.navigateByUrl("/");
            }
            else {
                this.msgError = "Ocurrió un error inesperado. Volvé a intentarlo en unos momentos"
                this.paso = 2
                // this.ds.generateErrorAlert('Ocurrió un error inesperado al obtener la información')
                // this.router.navigateByUrl("/");
            }
        });
    }

    ngOnInit(): void {
    }


    descargarComprobante() {
        if (this.detallePago.estadoComprobante != 2) {
            // SI HAN PASADO 1 MINUTO DESDE QUE CARGÓ LA PANTALLA
            if (this.tsBusqueda + (1000 * 60 * 1) < new Date().getTime()) {
                this.buscarInforme(true)
            }
            else {
                this.ds.generateInfoAlert("El comprobante está siendo generado. Por favor, espere unos momentos")
            }

        }
        else {
            window.open(this.detallePago.comprobante, '_blank').focus();
        }
    }

    realizarOtroPago() {
        this.router.navigateByUrl('/prestamos/consulta')
    }


    @ViewChild('captchaRef') captchaRef; //toma el modal de la plantilla
    recaptcha_KEY = environment.recaptcha_KEY;
    recaptcha_execute = false;

    consultarSoporte()
    {
        let link = `https://api.whatsapp.com/send/?phone=+5492645800950&text=Hola,%20quiero%20consultar%20por%20el%20pago%20pendiente%20de%20mi%20cuota.%20El%20código%20de%20pago%20es%20:%20${this.hash}%20.`;
        window.open(link, "_blank");
    }

}
