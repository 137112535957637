import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { CobroinmediatoService } from '../../services/cobroinmediato.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { FechaVencimientoPipe } from "../../_pipe/fecha-vencimiento.pipe";
import * as htmlToImage from 'html-to-image';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-formulario-pago1',
    templateUrl: './formulario-pago1.component.html',
    styleUrls: ['./formulario-pago1.component.scss']
})
export class FormularioPago1Component implements OnInit {

    vencimientoFormMin = 13;
    fechaVencimiento: Date;
    clock: Subscription;
    urlGoBack: any;
    KR: any = null;
    KR2: any = null;
    loading: any = false;
    subtitulo = "Información de la tarjeta";
    tipo: any = null;
    cod: any = null;
    hash: any = null;
    permitirNuevoPago: any = false
    hashLinkEmpresa: any = null

    krMsgError: any = null;

    @ViewChild('modalAlertaFormulario', { static: false }) modalAlertaFormulario: ModalDirective;

    constructor(private ngZone: NgZone, public ds: GlobalService, private activatedRoute: ActivatedRoute, public router: Router, public ci: CobroinmediatoService) {

        this.activatedRoute.params.subscribe(async (params) => {
            let param = params.data;
            this.hash = param;
            this.cod = param.slice(0, 2);

            if (this.cod == '04') {
                this.tipo = 'link'; this.urlGoBack = params.data2 + '/' + param;
                this.permitirNuevoPago = true;
                this.hashLinkEmpresa = params.data2
            }
            if (this.cod == '03') {
                this.tipo = 'boton'; this.urlGoBack = param;
            }
            if (this.ds.procesador_pago == 1) {
                this.crearTokenFormulario();
            }
            else {
                this.goBack()
            }
        });
    }

    crearTokenFormulario() {

        let data = { hash: this.hash };
        this.ds.generateWaitingAlert()
        // this.ds.loader= true;
        this.modalAlertaFormulario?.hide();
        this.ds.generarTokenFormulario(data).then(res => {

            // Fecha en la que se vence el formulario
            let fecha = new Date();
            fecha.setMinutes(fecha.getMinutes() + this.vencimientoFormMin);
            fecha.setSeconds(0);
            this.fechaVencimiento = fecha;

            // this.ds.loader= false;
            if (res && res['r'] && (res['r'].c == 0)) {
                this.ci.tokenFormulario = res['token'];
                if (this.KR2 != null) {
                    this.KR2.onSubmit(event => { });
                    this.KR2.onError(event => { });
                }

                this.createFormulario();

                this.updateClock();
            } else {
                this.ds.closeWaitingAlert()
                if (res['r'] && (res['r'].c == 1)) {
                    this.ds.generateErrorAlert_V2(res['r'].m, { willClose: this.volverAtras })
                } else {
                    this.ds.generateErrorAlert_V2('Ocurrió un error al generar el fomulario', { willClose: this.volverAtras });

                }
            }
        });
    }

    // ES UNA FUNCION FLECHA PARA QUE HEREDE EL THIS DEL PADRE
    volverAtras = () => {
        this.goBack()
    }

    async createFormulario() {
        const that = this;
        let posiblesTipos = ["boton", "link"];

        if (!posiblesTipos.includes(this.tipo)) {
            this.ds.closeWaitingAlert()
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
        } else {

            // this.ds.loader = true;
            let token;

            switch (this.tipo) {
                case "boton": { token = this.ci.tokenFormulario; break; }
                case "link": { token = this.ci.tokenFormulario; break; }
            }

            this.KR = await this.ci.getKR();

            if (this.KR == null) {
                this.router.navigateByUrl('/');
                this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
                // this.ds.loader= false;
                this.ds.closeWaitingAlert()
            } else if (token == null) {
                this.router.navigateByUrl('/');
                this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
                // this.ds.loader= false;
                this.ds.closeWaitingAlert()
            } else {

                this.KR.removeForms();
                this.KR.setFormConfig({
                    /* set the minimal configuration */
                    formToken: token,

                    "kr-language": "es-ES", /* to update initialization parameter */
                }).then(({ KR }) => {

                    KR.addForm("#myPaymentForm").then(async ({ KR, result }) => {
                        this.KR2 = KR;
                        let a = await KR.showForm(result.formId)
                        // that.ds.loader= false
                        that.ds.closeWaitingAlert()
                        KR.onSubmit(event => {

                            this.krMsgError = null;

                            this.ngZone.run(() => {

                                if (this.clock) this.clock.unsubscribe();
                                switch (this.tipo) {
                                    case "boton": { this.postSuccessBoton(event); break; }
                                    case "link": { this.postSuccessLink(event); break; }
                                }
                            });
                        });

                        KR.onError((event) => {

                            this.ngZone.run(async () => {

                                this.krMsgError = await this.ds.parseErrorCoin(event, this.hash);
                            });



                            if (this.clock) this.clock.unsubscribe();
                            switch (this.tipo) {
                                case "boton": { this.postErrorBoton(event); break; }
                                case "link": { this.postErrorLink(event); break; }
                            }
                        });
                    });
                });
            }
        }
    }

    ngOnInit() {

    }

    // ------------------------------- LINK DE PAGO  ---------------------------------
    email: any;
    numReferencia: any = null;
    hashReferencia: any;
    bandPagoRealizado: Boolean = false;;

    postSuccessLink(event) {
        this.ds.formRecarga = null;
        if (event.clientAnswer.orderStatus === "PAID") {
            this.ds.generateWaitingAlert()
            // this.ds.loader= true;
            this.ds.marcarPagoComoPendiente(event).then((res: any) => {
                this.ds.closeWaitingAlert()
                // this.ds.loader= false;
                if (res['r'] && (res['r'].c == 0)) {
                    // this.ngZone.run( () => {
                    this.hashReferencia = res['hash'];
                    this.numReferencia = res['numReferencia'];
                    this.email = res['email'];
                    this.bandPagoRealizado = true;
                    this.urlRedirect = res.redirect
                    
                    if (this.urlRedirect != null) {
                        this.redirecting = true
                        this.redirectAfertPayment();
                    }
                    // });
                } else {
                    this.ds.generateErrorAlert('Ocurrió un error');
                }
            });
            // this.router.navigate(['/home/inicio']);
        } else {
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error')
        }
    }

    postErrorLink(event) {

    }

    // ------------------------------- BOTON DE PAGO  ---------------------------------
    postSuccessBoton(event) {
        this.ds.formRecarga = null;
        if (event.clientAnswer.orderStatus === "PAID") {
            // this.ds.loader= true;
            this.ds.generateWaitingAlert()
            this.ds.marcarPagoComoPendiente(event).then((res: any) => {
                // this.ds.loader= false;
                this.ds.closeWaitingAlert()
                if (res['r'] && (res['r'].c == 0)) {
                    // this.ngZone.run( () => {
                    this.hashReferencia = res['hash'];
                    this.numReferencia = res['numReferencia'];
                    this.email = res['email'];
                    this.bandPagoRealizado = true;
                    this.urlRedirect = res.redirect

                    if (this.urlRedirect != null) {
                        this.redirecting = true
                        this.redirectAfertPayment();
                    }
                    // });
                } else {
                    this.ds.generateErrorAlert('Ocurrió un error');
                }
            });
            // this.router.navigate(['/home/inicio']);
        } else {
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error')
        }
    }

    postErrorBoton(event) {

    }

    //cuenta regresiva
    source = timer(0, 1000);
    updateClock() {
        this.clock = this.source.subscribe(t => {
            let fechaActual = new Date();
            fechaActual.setSeconds(0);

            if ((fechaActual >= this.fechaVencimiento) && !this.ds.loader) this.modalAlertaFormulario.show();
        })
    }

    goBack() {
        if (this.KR2 != null) {
            this.KR2.onSubmit(event => { });
            this.KR2.onError(event => { });
        }

        this.router.navigateByUrl('/pago/' + this.urlGoBack);
    }

    ngOnDestroy() {

        if (this.KR2 != null) {
            this.KR2.onSubmit(event => { });
            this.KR2.onError(event => { });
        }

        if (this.clock) this.clock.unsubscribe();
        this.bandPagoRealizado = false;
        this.numReferencia = null;
    }

    secondsToRedirect = 10
    urlRedirect: any = null
    redirecting: any = false
    timeoutRedirect: any = null
    redirectAfertPayment() {
        // console.log('redirecting');
        this.timeoutRedirect = setTimeout(() => {
            if (this.secondsToRedirect > 0) {
                this.secondsToRedirect--
                this.redirectAfertPayment()
            }
            else {
                window.location.href = this.urlRedirect
            }
        }, 1000);
    }

    pauseRedirect() {
        clearTimeout(this.timeoutRedirect)
    }

    resumeRedirect() {
        if (this.redirecting == true) this.redirectAfertPayment()
    }

    copy
    copiarPortaPapeles() {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.hashReferencia;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copy = true;
        setTimeout(() => {
            this.copy = false;
        }, 2000)
    }

    nuevoPago() {
        this.router.navigateByUrl(`pago/${this.hashLinkEmpresa}`);
    }

    descargarComprobante() {
        if (this.timeoutRedirect != null) this.pauseRedirect()
        this.ds.generateWaitingAlert("Descargando comprobante...")
        this.ds.httpPostNoToken('2', "link/v2/pago/postpago/generar/comprobante", { idLinkPago: this.hashReferencia }).then((res: any) => {
            this.ds.closeWaitingAlert()
            if (res && res.r && res.r.c == 0) {
                const dataBlob = this.ds.dataURItoBlob(res['wbout'], 'application/octet-stream');

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(dataBlob);
                link.download = 'bc_comprobante_pago_' + this.hashReferencia + '_' + new Date().toLocaleDateString() + '.pdf';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                if (this.redirecting == true) this.resumeRedirect()
                return
            }
            else if (res && res.r && res.r.c == 1) {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert(res.r.m)
            }
            else {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert("Ocurrió un error inesperado")
            }
        })
    }
    
    htmlComprobante = null;
    descargarComprobante2() {
        if (this.timeoutRedirect != null) this.pauseRedirect()
        this.ds.generateWaitingAlert("Descargando comprobante...")
        this.ds.httpPostNoToken('2', "link/v2/pago/postpago/generar/comprobante2", { idLinkPago: this.hashReferencia }).then(async (res: any) => {
            this.ds.closeWaitingAlert()
            if (res && res.r && res.r.c == 0) {

                var newWindow = window.open();
                newWindow.document.write(res['wbout'])
                newWindow.document.write(`
                    <button onclick="window.print();" class="noPrint">
                    Print Me
                    </button>
                `);
                newWindow.document.write(`
                <style>
                    @media print {
                        .noPrint{
                            display:none;
                            }
                        }
                        @page { margin: 0; }
                        body { margin: 1.6cm; }
                        h1{
                            color:#f6f6;
                        }
                  </style>
                `)

                // this.htmlComprobante = res['wbout'];
                // var myDivToConvert = document.getElementById("comprobante");

                // setTimeout(async () => {
                //     this.ds.generateWaitingAlert("Descargando...")
                //     let dataUrl = await htmlToImage.toJpeg(myDivToConvert, { quality: 0.95, backgroundColor: "#FFFFFF", style:{display:"block"} })
                //     const link = document.createElement('a');
                //     link.download = 'bestcard-comprobante-' + new Date().toLocaleDateString() + '.jpg';
                //     link.href = dataUrl;
                //     link.click();
                //     this.ds.closeWaitingAlert()
                //     return
                // }, 2000);

            }
            else if (res && res.r && res.r.c == 1) {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert(res.r.m)
            }
            else {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert("Ocurrió un error inesperado")
            }
        })
    }


    descargarComprobante3() {
        if (this.timeoutRedirect != null) this.pauseRedirect()
        this.ds.generateWaitingAlert("Descargando comprobante...")
        this.ds.httpPostNoToken('2', "link/v2/pago/postpago/generar/comprobante2", { idLinkPago: this.hashReferencia }).then(async (res: any) => {
            this.ds.closeWaitingAlert()
            if (res && res.r && res.r.c == 0) {


                

                Swal.fire({
                    html: res['wbout']+`<button id="btn2" (click)="descargarComprobante3a()">Descargar</button>`,
                    customClass: {container: "sw-compr"},
                    confirmButtonText: "Descargarnt",
                    didOpen: () => {
                        document.getElementById("btn2").addEventListener("click",this.descargarComprobante3a,false)
                    },
                    preConfirm: async () => {
                        try {
                            
                            console.log('11111111111');
                            var myDivToConvert: HTMLElement = document.getElementsByClassName("sw-compr")[0] as HTMLElement;
                            // this.ds.generateWaitingAlert("Descargando...")
                            console.log('myDivToConvert');
                            console.log(myDivToConvert);
                            let dataUrl = await htmlToImage.toJpeg(myDivToConvert, { quality: 0.95, backgroundColor: "#FFFFFF"})
                            const link = document.createElement('a');
                            link.download = 'bestcard-comprobante-' + new Date().toLocaleDateString() + '.jpg';
                            link.href = dataUrl;
                            link.click();
                        } catch (error) {
                            console.log('error');
                            console.log(error);
                        }

                        return false;
                    }
                }).then(async (result) => {
                    if(result)
                    {
                        console.log('22222222222222');
                        var myDivToConvert: HTMLElement = document.getElementsByClassName("sw-compr")[0] as HTMLElement;
                        // this.ds.generateWaitingAlert("Descargando...")
                        let dataUrl = await htmlToImage.toJpeg(myDivToConvert, { quality: 0.95, backgroundColor: "#FFFFFF"})
                        const link = document.createElement('a');
                        link.download = 'bestcard-comprobante-' + new Date().toLocaleDateString() + '.jpg';
                        link.href = dataUrl;
                        link.click();


                    }
                })

                // this.htmlComprobante = res['wbout'];
                // var myDivToConvert = document.getElementById("comprobante");

                // setTimeout(async () => {
                //     this.ds.generateWaitingAlert("Descargando...")
                //     let dataUrl = await htmlToImage.toJpeg(myDivToConvert, { quality: 0.95, backgroundColor: "#FFFFFF", style:{display:"block"} })
                //     const link = document.createElement('a');
                //     link.download = 'bestcard-comprobante-' + new Date().toLocaleDateString() + '.jpg';
                //     link.href = dataUrl;
                //     link.click();
                //     this.ds.closeWaitingAlert()
                //     return
                // }, 2000);

            }
            else if (res && res.r && res.r.c == 1) {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert(res.r.m)
            }
            else {
                if (this.redirecting == true) this.resumeRedirect()
                return this.ds.generateErrorAlert("Ocurrió un error inesperado")
            }
        })
    }

    async descargarComprobante3a()
    {
        var myDivToConvert: HTMLElement = document.getElementsByClassName("sw-compr")[0] as HTMLElement;
        // this.ds.generateWaitingAlert("Descargando...")
        let dataUrl = await htmlToImage.toJpeg(myDivToConvert, { quality: 0.95, backgroundColor: "#FFFFFF"})
        const link = document.createElement('a');
        link.download = 'bestcard-comprobante-' + new Date().toLocaleDateString() + '.jpg';
        link.href = dataUrl;
        link.click();
    }
}
