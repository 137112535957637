import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-white-border',
  templateUrl: './btn-white-border.component.html',
  styleUrls: ['./btn-white-border.component.scss']
})
export class BtnWhiteBorderComponent implements OnInit {

  @Input() text: string;
  @Input() icon: string;
  @Input() border: boolean;
  btn_border: boolean;

  constructor() { 
    
  }
  
  ngOnInit(): void {
    
    if (this.border) {
      this.btn_border = false
    }
    else{
      this.btn_border = true
    }
  }

}
