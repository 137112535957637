<div class="container mt-5 mb-4">
	
	<div class="row">
		<div class="row row-cols-1 row-cols-md-3 g-4">
			<div class="col">
				<div class="card h-100">
					<img
						src="https://mdbcdn.b-cdn.net/img/new/standard/city/044.webp"
						class="card-img-top"
						alt="Skyscrapers"
					/>
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<p class="card-text">
							This is a wider card with supporting text below as a natural lead-in
							to additional content. This content is a little bit longer.
						</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">Last updated 3 mins ago</small>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card h-100">
					<img
						src="https://mdbcdn.b-cdn.net/img/new/standard/city/043.webp"
						class="card-img-top"
						alt="Los Angeles Skyscrapers"
					/>
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<p class="card-text">
							This card has supporting text below as a natural lead-in to
							additional content.
						</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">Last updated 3 mins ago</small>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card h-100">
					<img
						src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp"
						class="card-img-top"
						alt="Palm Springs Road"
					/>
					<div class="card-body">
						<h5 class="card-title">Card title</h5>
						<p class="card-text">
							This is a wider card with supporting text below as a natural lead-in
							to additional content. This card has even longer content than the
							first to show that equal height action.
						</p>
					</div>
					<div class="card-footer">
						<small class="text-muted">Last updated 3 mins ago</small>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>