import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { GlobalService } from 'src/app/services/global.service';
import { PrestamosConsultasService } from '../../services/prestamos-consultas.service';

@Component({
  selector: 'app-modal-cuota',
  templateUrl: './modal-cuota.component.html',
  styleUrls: ['./modal-cuota.component.scss']
})
export class ModalCuotaComponent implements OnInit {

  @ViewChild('modalCuota2', {static: false}) modalCuota: ModalDirective; //toma el modal de la plantilla
  detalleModalCuota: any = {};

  constructor(private pc: PrestamosConsultasService, private ds: GlobalService, public router: Router) { }

  ngOnInit(): void {

    this.pc.$emitter.subscribe((detalleModalCuota:any) => {
      this.detalleModalCuota = detalleModalCuota;
      this.modalCuota.show();
    })
  }

  selectMedioPago(medio) {
    this.detalleModalCuota.medio=medio
    switch(medio) {
      case 1:
      {
        this.detalleModalCuota.recargo = this.detalleModalCuota.recargo1
        this.detalleModalCuota.importe = this.detalleModalCuota.importe1
        break;
      }
      case 2:
      {
        this.detalleModalCuota.recargo = this.detalleModalCuota.recargo2
        this.detalleModalCuota.importe = this.detalleModalCuota.importe2
        break;
      }
      case 3:
      {
        this.detalleModalCuota.recargo = this.detalleModalCuota.recargo3
        this.detalleModalCuota.importe = this.detalleModalCuota.importe3
        break;
      }
    }
  }

  pagar() {
    if(this.detalleModalCuota.medio==null) {
      return this.ds.generateErrorAlert("Elegí el medio de pago")
    }
    if(this.detalleModalCuota.parcial==true && this.detalleModalCuota.importeIngresado==null) {
      return this.ds.generateErrorAlert("Ocurrió un error inesperado")
    }
    let data = {
      idPrestamo: this.detalleModalCuota.prestamo.idPrestamo,
      numCuota: this.detalleModalCuota.prestamo.numeroCuotaVencimiento,
      medioPago: this.detalleModalCuota.medio,
      parcial: (this.detalleModalCuota.parcial==true)? true : null,
      importe: (this.detalleModalCuota.parcial==true)? this.detalleModalCuota.importeIngresado : null
    }

    this.ds.generateWaitingAlert()
    this.ds.httpPostNoToken_V2(3,"prestamos/publico/api/v1/consulta/pendientes/generar/boton",data).subscribe((res:any) => {
      this.ds.closeWaitingAlert()
      this.ds.loader = false;
      if(res && res.r && res.r.c==0) {
        // this.router.navigateByUrl(res.link)
        this.ds.generateWaitingAlert('Redirigiendo...')
        window.location.href = res.link
      } else if (res && res.r && res.r.c==1) {
          this.ds.generateErrorAlert(res.r.m)
      } else {
        this.ds.generateErrorAlert('Ocurrió un error inesperado al obtener la información')
      }
    });
  }

}
