
<app-navbar></app-navbar>
<div class="layout-1">
    <div class="container row">
        <div class="col-sm-7 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">

            <span *ngIf="estado==0">
                <p class="h5-responsive mb-3 text-primary text-center">Estamos activando tu tarjeta</p>
            </span>
            <span *ngIf="estado==1">
                <p class="h5-responsive mb-3 text-primary text-center">¡Felicidades! Tu tarjeta fue activada correctamente</p>
            </span>
            <span *ngIf="estado==2">
                <p *ngIf="msgError!=null" class="h5-responsive mb-3 text-primary text-center">{{msgError}}</p>
                <p *ngIf="msgError==null" class="h5-responsive mb-3 text-primary text-center">Ocurrió un error al activar tu tarjeta</p>
            </span>
        </div>
    </div>
</div>
<app-footer-general></app-footer-general>