import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { CobroinmediatoService } from '../services/cobroinmediato.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { FechaVencimientoPipe } from "../_pipe/fecha-vencimiento.pipe";

@Component({
    selector: 'app-formulario-pago',
    templateUrl: './formulario-pago.component.html',
    styleUrls: ['./formulario-pago.component.scss']
})
export class FormularioPagoComponent implements OnInit {

    vencimientoFormMin = 13;
    fechaVencimiento: Date;
    clock: Subscription;
    urlGoBack: any;
    KR: any = null;
    KR2: any = null;
    loading: any = false;
    subtitulo = "Información de la tarjeta";
    tipo: any = null;
    cod: any = null;
    hash: any = null;
    @ViewChild('modalAlertaFormulario', { static: false }) modalAlertaFormulario: ModalDirective;

    constructor(private ngZone: NgZone, public ds: GlobalService, private activatedRoute: ActivatedRoute, public router: Router, public ci: CobroinmediatoService) {
        this.activatedRoute.params.subscribe(async (params) => {
            let param = params.data;
            this.hash = param;
            this.cod = param.slice(0, 2);

            if (this.cod == '04') { this.tipo = 'link'; this.urlGoBack = params.data2 + '/' + param; }
            if (this.cod == '03') { this.tipo = 'boton'; this.urlGoBack = param; }

            this.crearTokenFormulario();
        });
    }

    crearTokenFormulario() {

        let data = { hash: this.hash };
        this.ds.loader = true;
        this.modalAlertaFormulario?.hide();
        this.ds.generarTokenFormulario(data).then(res => {

            // Fecha en la que se vence el formulario
            let fecha = new Date();
            fecha.setMinutes(fecha.getMinutes() + this.vencimientoFormMin);
            fecha.setSeconds(0);
            this.fechaVencimiento = fecha;
            // 

            this.ds.loader = false;
            if (res['r'] && (res['r'].c == 0)) {
                this.ci.tokenFormulario = res['token'];
                if (this.KR2 != null) {
                    this.KR2.onSubmit(event => { });
                    this.KR2.onError(event => { });
                }

                this.createFormulario();

                this.updateClock();
            } else {
                if (res['r'] && (res['r'].c == 1)) {
                    this.router.navigateByUrl('/');
                    this.ds.generateErrorAlert(res['r'].m);
                } else {
                    this.router.navigateByUrl('/');
                    this.ds.generateErrorAlert('Ocurrió un error al generar el fomulario');
                }
            }
        });
    }

    async createFormulario() {
        const that = this;
        let posiblesTipos = ["boton", "link"];

        if (!posiblesTipos.includes(this.tipo)) {
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
        } else {

            this.ds.loader = true;
            let token;

            switch (this.tipo) {
                case "boton": { token = this.ci.tokenFormulario; break; }
                case "link": { token = this.ci.tokenFormulario; break; }
            }

            this.KR = await this.ci.getKR();

            if (this.KR == null) {
                this.router.navigateByUrl('/');
                this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
                this.ds.loader = false;
            } else if (token == null) {
                this.router.navigateByUrl('/');
                this.ds.generateErrorAlert('Ocurrió un error al generar el formulario');
                this.ds.loader = false;
            } else {

                this.KR.removeForms();
                this.KR.setFormConfig({
                    /* set the minimal configuration */
                    formToken: token,
                    "kr-language": "es-ES", /* to update initialization parameter */
                }).then(({ KR }) => {

                    KR.addForm("#myPaymentForm").then(async ({ KR, result }) => {
                        this.KR2 = KR;
                        // console.log('result');
                        // console.log(result);
                        let a = await KR.showForm(result.formId)
                        that.ds.loader = false
                        KR.onSubmit(event => {
                            this.ngZone.run(() => {

                                if (this.clock) this.clock.unsubscribe();
                                switch (this.tipo) {
                                    case "boton": { this.postSuccessBoton(event); break; }
                                    case "link": { this.postSuccessLink(event); break; }
                                }
                            });
                        });

                        KR.onError(event => {
                            if (this.clock) this.clock.unsubscribe();
                            switch (this.tipo) {
                                case "boton": { this.postErrorBoton(event); break; }
                                case "link": { this.postErrorLink(event); break; }
                            }
                        });
                    });
                });
            }
        }
    }

    ngOnInit() {

    }

    // ------------------------------- LINK DE PAGO  ---------------------------------
    email: any;
    numReferencia: any = null;
    hashReferencia: any;
    bandPagoRealizado: Boolean = false;;

    postSuccessLink(event) {
        this.ds.formRecarga = null;
        if (event.clientAnswer.orderStatus === "PAID") {
            this.ds.loader = true;
            this.ds.marcarPagoComoPendiente(event).then((res:any) => {
                this.ds.loader = false;
                if (res['r'] && (res['r'].c == 0)) {
                    // this.ngZone.run( () => {
                    this.hashReferencia = res['hash'];
                    this.numReferencia = res['numReferencia'];
                    this.email = res['email'];
                    this.bandPagoRealizado = true;
                    this.urlRedirect = res.redirect

                    console.log('this.urlRedirect');
                    console.log(this.urlRedirect);
                    if(this.urlRedirect!=null)
                    {
                        this.redirecting = true
                        this.redirectAfertPayment();
                    }
                    // });
                } else {
                    this.ds.generateErrorAlert('Ocurrió un error');
                }
            });
            // this.router.navigate(['/home/inicio']);
        } else {
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error')
        }
    }

    postErrorLink(event) {

    }

    // ------------------------------- BOTON DE PAGO  ---------------------------------
    postSuccessBoton(event) {
        this.ds.formRecarga = null;
        if (event.clientAnswer.orderStatus === "PAID") {
            this.ds.loader = true;
            this.ds.marcarPagoComoPendiente(event).then((res:any) => {
                this.ds.loader = false;
                if (res['r'] && (res['r'].c == 0)) {
                    // this.ngZone.run( () => {
                    this.hashReferencia = res['hash'];
                    this.numReferencia = res['numReferencia'];
                    this.email = res['email'];
                    this.bandPagoRealizado = true;
                    this.urlRedirect = res.redirect
                    
                    if(this.urlRedirect!=null)
                    {
                        this.redirecting = true
                        this.redirectAfertPayment();
                    }

                    // });
                } else {
                    this.ds.generateErrorAlert('Ocurrió un error');
                }
            });
            // this.router.navigate(['/home/inicio']);
        } else {
            this.router.navigateByUrl('/');
            this.ds.generateErrorAlert('Ocurrió un error')
        }
    }

    postErrorBoton(event) {

    }

    //cuenta regresiva 
    source = timer(0, 1000);
    updateClock() {
        this.clock = this.source.subscribe(t => {
            let fechaActual = new Date();
            fechaActual.setSeconds(0);

            if ((fechaActual >= this.fechaVencimiento) && !this.ds.loader) this.modalAlertaFormulario.show();
        })
    }

    goBack() {
        if (this.KR2 != null) {
            this.KR2.onSubmit(event => { });
            this.KR2.onError(event => { });
        }

        this.router.navigateByUrl('/pago/' + this.urlGoBack);
    }

    ngOnDestroy() {
        console.log('Entre ngOnDestroy')
        if (this.KR2 != null) {
            this.KR2.onSubmit(event => { });
            this.KR2.onError(event => { });
        }

        if (this.clock) this.clock.unsubscribe();
        this.bandPagoRealizado = false;
        this.numReferencia = null;
    }

    secondsToRedirect = 10
    urlRedirect: any = null
    redirecting: any = false
    redirectAfertPayment() {
        console.log('redirecting');
        setTimeout(() => {
            if (this.secondsToRedirect > 0) {
                this.secondsToRedirect--
                this.redirectAfertPayment()
            }
            else {
                window.location.href = this.urlRedirect
            }
        }, 1000);
    }

    copy
    copiarPortaPapeles() {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.hashReferencia;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copy = true;
        setTimeout(() => {
            this.copy = false;
        }, 2000)
    }
}