import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DirectivesModule } from './_directives/directives.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

// import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SolicitudprestamoComponent } from './solicitudprestamo/solicitudprestamo.component';
import { ConfirmacionprestamoComponent } from './confirmacionprestamo/confirmacionprestamo.component';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import { ReferidosComponent } from './referidos/referidos.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { PagosComponent } from './pagos/pagos.component';
import { LinksPagoComponent } from './links-pago/links-pago.component';
import { FormularioPagoComponent } from './formulario-pago/formulario-pago.component';
import { ConsultaWspComponent } from './consulta-wsp/consulta-wsp.component';
import { FechaVencimientoPipe } from './_pipe/fecha-vencimiento.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FormularioPago1Component } from './links-pago/formulario-pago1/formulario-pago1.component';
import { FormularioErrorComponent } from './links-pago/formulario-error/formulario-error.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { BlogComponent } from './blog/blog.component';
import { EntradaBlogComponent } from './blog/entrada-blog/entrada-blog.component';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
registerLocaleData(localeEsAr, 'es-AR')
import { DomseguroPipe } from './_pipe/domseguro.pipe';

// PRESTAMOS
import { PrestamosHomeComponent } from './prestamos-home/prestamos-home.component';
import { PrestamosComponent } from './prestamos/prestamos.component';
import { PrestamosSimuladorComponent } from './prestamos-simulador/prestamos-simulador.component';
import { PrestamosConsultasComponent } from './prestamos-consultas/prestamos-consultas.component';
import { InformeComponent as PrestamosConsultasInformeComponent } from './prestamos-consultas/informe/informe.component';
import { SharedModule } from './shared/shared.module';
import { SubirDocumentacionComponent as PrestamosFranquiciaSubirDocumentacionComponent } from './franquicia/subir-documentacion/subir-documentacion.component';
import { BotonArrepentimientoComponent } from './boton-arrepentimiento/boton-arrepentimiento.component';
import { PrestamoDetalleComponent } from './prestamos-consultas/components/prestamo-detalle/prestamo-detalle.component';
import { PrestamosCuotaComponent } from './prestamos-consultas/components/prestamos-cuota/prestamos-cuota.component';
import { ModalCuotaComponent } from './prestamos-consultas/components/modal-cuota/modal-cuota.component';

import { TarjetaActivadaComponent } from './tarjeta-activada/tarjeta-activada.component';
import { TarjetaActivarComponent } from './tarjeta-activar/tarjeta-activar.component';
import { FinalizarIdentityComponent } from './pomelo/finalizar-identity/finalizar-identity.component';
import { BilleteraMarcaBlancaComponent } from './factory/billetera-marca-blanca/billetera-marca-blanca.component';
import { NavbarComponent } from './factory/components/navbar/navbar.component';
import { BtnWhiteBorderComponent } from './factory/components/btn-white-border/btn-white-border.component';
import { HeroMarcaBlancaComponent } from './factory/components/hero-marca-blanca/hero-marca-blanca.component';
import { BtnPinkBgComponent } from './factory/components/btn-pink-bg/btn-pink-bg.component';
import { FunctionalitiesComponent } from './factory/components/functionalities/functionalities.component';
import { BtnWhiteComponent } from './factory/components/btn-white/btn-white.component';
import { CardInfoComponent } from './factory/components/card-info/card-info.component';
import { PersonalizationComponent } from './factory/components/personalization/personalization.component';
import { CardIconTitleComponent } from './factory/components/card-icon-title/card-icon-title.component';
import { FooterMarcaBlancaComponent } from './factory/components/footer-marca-blanca/footer-marca-blanca.component';
import { TechnologiesComponent } from './factory/components/technologies/technologies.component';
import { ContactMarcaBlancaComponent } from './factory/components/contact-marca-blanca/contact-marca-blanca.component';
import { TycComponent } from './tyc/tyc.component';

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    SolicitudprestamoComponent,
    ConfirmacionprestamoComponent,
    PreguntasFrecuentesComponent,
    ReferidosComponent,
    FormularioPagoComponent,
    PagosComponent,
    LinksPagoComponent,
    FechaVencimientoPipe,
    FormularioPago1Component,
    FormularioErrorComponent,
    SucursalesComponent,
    FooterComponent,
    HeaderComponent,
    // BLOG
    BlogComponent,
    EntradaBlogComponent,
    // CONSULTA
    ConsultaWspComponent,
    // PRÉSTAMOS
    PrestamosHomeComponent,
    PrestamosComponent,
    PrestamosSimuladorComponent,
    PrestamosConsultasComponent,
    PrestamosConsultasInformeComponent,
    // OTROS
    PrestamosFranquiciaSubirDocumentacionComponent,
    BotonArrepentimientoComponent,
    PrestamoDetalleComponent,
    PrestamosCuotaComponent,
    ModalCuotaComponent,
    TarjetaActivadaComponent,
    TarjetaActivarComponent,
    FinalizarIdentityComponent,
    BilleteraMarcaBlancaComponent,
    NavbarComponent,
    BtnWhiteBorderComponent,
    HeroMarcaBlancaComponent,
    BtnPinkBgComponent,
    FunctionalitiesComponent,
    BtnWhiteComponent,
    CardInfoComponent,
    PersonalizationComponent,
    CardIconTitleComponent,
    FooterMarcaBlancaComponent,
    TechnologiesComponent,
    ContactMarcaBlancaComponent,
    TycComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RecaptchaModule,
    RecaptchaFormsModule,
    DirectivesModule,
    NgxQRCodeModule,
    NgxSliderModule,
    SharedModule
  ],
  providers: [
    MDBSpinningPreloader,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'es-AR' }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }


