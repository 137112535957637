import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';
import { IMyOptions } from 'ng-uikit-pro-standard';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-prestamos',
  templateUrl: './prestamos.component.html',
  styleUrls: ['./prestamos.component.scss']
})
export class PrestamosComponent implements OnInit {
  ref: any= null;
  showForm: any = false
  showFormPrestamo: any = false
  showFormContacto: any = false
  formPrestamo= new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    nombre: new FormControl(null, [Validators.required, Validators.minLength(4)]),
    apellido: new FormControl(null, [Validators.required, Validators.minLength(4)]),
    dni: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{7,9}$/) ]),
    codArea: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{3,4}$/) ]),
    numCel: new FormControl(null, [Validators.required, Validators.pattern(/^[0-9]{7,10}$/) ]),
    cuil: new FormControl(null, [Validators.required, this.validate_cuil_cuit.bind(this)]),
    sexo: new FormControl(null, [Validators.required]),
    fechaNac: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(10)]),
    importe: new FormControl(null, [Validators.required, this.validate_importe.bind(this)]),
    terminos: new FormControl(false, []),
    recaptchaReactive:  new  FormControl ( null ,  Validators.required ),
  }, { updateOn: 'change' });
  formSelect= new FormGroup({
    provincia: new FormControl(null, [Validators.required]),
  }, { updateOn: 'change' });


  recaptcha: any = null
  
  paso: any = 1;
  valid: any = false;
  importeMinimo: any = null;
  importeMaximo: any = null;
  cft: any = null;
  tem: any = null;
  provinciasDisponible: any = [];
  emailExistente: any = null;
  error: any = null;

  test: any = true;
  testPass: any = null;
  testError: any = false;

  opcionesProvincia = [
    { value: 'buenosaires', label: 'Buenos Aires' },
    { value: 'catamarca', label: 'Catamarca' },
    { value: 'chaco', label: 'Chaco' },
    { value: 'chubut', label: 'Chubut' },
    { value: 'caba', label: 'Ciudad Autónoma de Buenos Aires' },
    { value: 'cordoba', label: 'Córdoba' },
    { value: 'corrientes', label: 'Corrientes' },
    { value: 'entrerios', label: 'Entre Ríos' },
    { value: 'formosa', label: 'Formosa' },
    { value: 'jujuy', label: 'Jujuy' },
    { value: 'lapampa', label: 'La Pampa' },
    { value: 'larioja', label: 'La Rioja' },
    { value: 'mendoza', label: 'Mendoza' },
    { value: 'misiones', label: 'Neuquén' },
    { value: 'rionegro', label: 'Río Negro' },
    { value: 'salta', label: 'Salta' },
    { value: 'sanjuan', label: 'San Juan' },
    { value: 'sanluis', label: 'San Luis' },
    { value: 'santacruz', label: 'Santa Cruz' },
    { value: 'santafe', label: 'Santa Fe' },
    { value: 'stgodelestero', label: 'Santiago del Estero' },
    { value: 'tierradelfuego', label: 'Tierra del Fuego' },
    { value: 'tucuman', label: 'Tucumán' }
  ];

  opcionesSexo = [
    {value: 2, label: "Femenino"},
    {value: 1, label: "Masculino"}
  ]

  fechaNac: any;
  @ViewChild('datePicker') datePicker: any;

  public myDatePickerOptions: IMyOptions = {
    dayLabelsFull: { su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miércoles', th: 'Jueves', fr: 'Viernes', sa: 'Sábado' },
    dayLabels: { su: 'Do', mo: 'Lu', tu: 'Ma', we: 'Mi', th: 'Ju', fr: 'Vi', sa: 'Sa' },
    monthLabels: { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
    monthLabelsFull: { 1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio', 8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre' },
    dateFormat: 'yyyy-mm-dd',
    todayBtnTxt: 'Hoy',
    firstDayOfWeek: 'mo',
    clearBtnTxt: 'Limpiar',
    closeBtnTxt: 'Hecho',
    showTodayBtn: true,
    showClearDateBtn: true,
    markCurrentDay: true,
    minYear: 1900,
    maxYear: this.ds.datePickerPrestamosLastYear,
    disableSince: this.ds.datePickerPrestamosDisabled,
  };

  openDateTime() {
    this.datePicker.openBtnClicked();
  }
  detectDateChange(val) {
    this.formPrestamo.get('fechaNac').setValue(val);
  }

  constructor(public ds: GlobalService, public router: Router, private activatedRoute: ActivatedRoute, private titleService:Title) {
    this.titleService.setTitle("BestCard Préstamos");
    this.activatedRoute.params.subscribe(params => {
      this.ref = params.hash || null;


      // ds.loader = true;
      // ds.setReferidoPrestamo(this.ref).then(res => {
      //   ds.loader = false;
      //   // this.ds.loader = false;
      //   let resInfo: any = res;
      //   // console.log(resInfo);
      //   if(resInfo.r) {
      //     if (resInfo.r.c == 0) {
      //       // this.jsonImportes = res['json']
      //     } else {
      //       this.ds.generateErrorAlert(resInfo.r.m);
      //     }
      //   } else {
      //     // this.ds.generateErrorAlert("Ocurrió un error inesperado");
      //   }
      // });

    });

    if(this.test==false)
    {
      this.traerInfoBasica();
    }
    else
    {
      this.testPass = window.prompt("Introduzca la contraseña")
      this.traerInfoBasica();
    }
  }

  ngOnInit(): void {
    this.paso=1
    // this.paso=3
    // this.documentacion = [
    //   'item 1dasd ada sda da sdasd ads ad',
    //   'item 2dsadasdadsadasd',
    //   'item 3d aewq eq ewq eqw eqw eq',
    // ]

    
    
  }

  get form_provincia() { return this.formSelect.get('provincia'); }
  get form_email() { return this.formPrestamo.get('email'); }
  get form_codArea() { return this.formPrestamo.get('codArea'); }
  get form_numCel() { return this.formPrestamo.get('numCel'); }
  get form_nombre() { return this.formPrestamo.get('nombre'); }
  get form_apellido() { return this.formPrestamo.get('apellido'); }
  get form_dni() { return this.formPrestamo.get('dni'); }
  get form_cuil() { return this.formPrestamo.get('cuil'); }
  get form_sexo() { return this.formPrestamo.get('sexo'); }
  get form_fechaNac() { return this.formPrestamo.get('fechaNac'); }
  get form_importe() { return this.formPrestamo.get('importe'); }
  get form_terminos() { return this.formPrestamo.get('terminos'); }
  get form_recaptcha() { return this.formPrestamo.get('recaptchaReactive'); }

  traerInfoBasica()
  {
    this.ds.loader = true;
    let data: any = {}
    if(this.test==true) 
    {
      if(this.testPass==null || this.testPass=="") { this.testError = true; this.ds.loader = false;return }
      else data.password = this.testPass;
    }
    
    this.ds.httpPostNoToken(3,"prestamos/ld/v1/consultar/base",data).then(res => {
      this.ds.loader = false;
      if(res && res['r'] && res['r'].c==0)
      {
          this.importeMaximo = res['importeMaximo']
          this.importeMinimo = res['importeMinimo']
          this.cft = res['cft']
          this.tem = res['tem']
          this.provinciasDisponible = res['prov']

          this.formSelect.patchValue({
            provincia: "sanjuan",
          })
          this.selectPronvicia("sanjuan")
      }
      else if (res && res['r'] && res['r'].c==1)
      {
        if(res['r'].m=="Acceso Denegado") this.testError = true
        this.ds.generateErrorAlert(res['r'].m)
        // this.router.navigateByUrl("/");
      }
      else
      {
        this.ds.generateErrorAlert('Ocurrió un error inesperado al obtener la información')
        // this.router.navigateByUrl("/");
      }
    });

  }

  selectPronvicia(e)
  {
    if(e==null)
    {
      this.showForm = false;
      this.showFormPrestamo = false;
      this.showFormContacto = false;
    }
    else
    {
      if(this.provinciasDisponible.includes(e))
      {
        this.showForm = true;
        this.showFormPrestamo = true;
        this.showFormContacto = false;
      }
      else 
      {
        this.showForm = true;
        this.showFormContacto = true;
        this.showFormPrestamo = false;
      }
    }
  }

  @ViewChild('captchaRef') captchaRef; //toma el modal de la plantilla
  recaptcha_KEY = environment.recaptcha_KEY;
  recaptcha_execute = false;


  verifyFormDisponibilidad() {


    if(this.formPrestamo.status != "VALID") { this.ds.generateErrorAlert('Debe completar todos los campos del formulario'); }
    else if(parseFloat(this.form_importe.value) != parseInt(this.form_importe.value)) { this.ds.generateErrorAlert('El monto del préstamo debe ser un número entero'); }
    else {

      

      // VERIFICA LA DISPONIBILIDAD DEL PRÉSTAMO
      if(this.showFormPrestamo==true)
      {
        if (this.form_terminos.value!=true){ this.ds.generateErrorAlert('Debe aceptar los términos y condiciones'); }
        else
        {
          
          let data: any = {
            provincia: this.form_provincia.value,
            email: this.form_email.value,
            codArea: this.form_codArea.value,
            numCel: this.form_numCel.value,
            nombre: this.form_nombre.value,
            apellido: this.form_apellido.value,
            dni: this.form_dni.value,
            cuil: this.form_cuil.value,
            fechaNac: this.form_fechaNac.value,
            importe: this.form_importe.value,
            sexo: this.form_sexo.value,
            terminos: this.form_terminos.value,
            ref: this.ref,
            // ref: this.ref,
          };

          if(this.test==true) 
          {
            if(this.testPass==null || this.testPass=="") { this.testError = true; return }
            else data.password = this.testPass;
          }
    
          this.ds.loader = true;
          this.ds.httpPostNoTokenRecaptcha(3,"prestamos/ld/v1/consultar/disponibilidad",data,this.form_recaptcha.value).then(res => {
            this.ds.loader = false;
            if(res && res['r'] && res['r'].c==0)
            {
    
              this.valid = res['valid'];
              this.error = res['error'];
              this.emailExistente = res['email'];
    
              if(this.valid==true)
              {
                this.importeElegido = data.importe;
                this.simulacionPlan = (res['simulacion'])?  res['simulacion'] : null ;
                this.simulacionConfig = null;
                this.simulacionHash = (res['hash'])?  res['hash'] : null
                this.simulacionEmail = data.email;
                this.simulacionCuil = data.cuil;
                let auxCuotas = [];
                for(let i in this.simulacionPlan)
                {
                  // auxCuotas.push({value: i, label: `${i} cuotas ($${this.simulacionPlan[i].promedioCuota.toFixed(2)} c/u.)`})
                  auxCuotas.push({value: i, label: `${i} ($${this.simulacionPlan[i].promedioCuota.toFixed(2)} c/u.)`})
                }
                this.opcionesCuotas = auxCuotas
              }
              this.paso=2;
            }
            else if(res && res['r'] && res['r'].c==1)
            {
              this.ds.generateErrorAlert(res['r'].m)
              this.formPrestamo.get('recaptchaReactive').setValue('');
            }
            else
            {
              this.ds.generateErrorAlert("Ocurrió un error inesperado al consultar por el préstamo")
              this.formPrestamo.get('recaptchaReactive').setValue('');
            }
    
          })

        }

      }
      // GUARDA LA SOLICITUD DE CONSULTA
      else if(this.showFormContacto==true)
      {
        
        let data: any = {
          provincia: this.form_provincia.value,
          email: this.form_email.value,
          codArea: this.form_codArea.value,
          numCel: this.form_numCel.value,
          nombre: this.form_nombre.value,
          apellido: this.form_apellido.value,
          dni: this.form_dni.value,
          cuil: this.form_cuil.value,
          fechaNac: this.form_fechaNac.value,
          importe: this.form_importe.value,
          sexo: this.form_sexo.value,
          // terminos: this.form_terminos.value,
          // ref: this.ref,
        };

        if(this.test==true) 
        {
          if(this.testPass==null || this.testPass=="") { this.testError = true; return }
          else data.password = this.testPass;
        }

        // console.log('data');
        // console.log(data);
        this.ds.loader = true;
        this.ds.httpPostNoTokenRecaptcha(3,"prestamos/ld/v1/consultar/contacto",data,this.form_recaptcha.value).then(res => {
          this.ds.loader = false;
          if(res && res['r'] && res['r'].c==0)
          {
  
            this.valid = res['valid'];
            this.error = res['error'];
            this.emailExistente = res['email'];
  
            if(this.valid==true)
            {
              this.importeElegido = data.importe;
              this.simulacionPlan = (res['simulacion'])?  res['simulacion'] : null ;
              this.simulacionConfig = null;
              this.simulacionHash = (res['hash'])?  res['hash'] : null
              this.simulacionEmail = data.email;
              this.simulacionCuil = data.cuil;
              let auxCuotas = [];
              for(let i in this.simulacionPlan)
              {
                // auxCuotas.push({value: i, label: `${i} cuotas ($${this.simulacionPlan[i].promedioCuota.toFixed(2)} c/u.)`})
                auxCuotas.push({value: i, label: `${i} ($${this.simulacionPlan[i].promedioCuota.toFixed(2)} c/u.)`})
              }
              this.opcionesCuotas = auxCuotas
            }
            this.paso=10;
          }
          else if(res && res['r'] && res['r'].c==1)
          {
            this.ds.generateErrorAlert(res['r'].m)
            this.formPrestamo.get('recaptchaReactive').setValue('');
          }
          else
          {
            this.ds.generateErrorAlert("Ocurrió un error inesperado al consultar por el préstamo")
            this.formPrestamo.get('recaptchaReactive').setValue('');
          }
  
        })
      }
      else 
      {
        this.ds.generateErrorAlert('Ocurrió un error inesperado. Recargue la página y vuelva a intentarlo');
        this.formPrestamo.get('recaptchaReactive').setValue('');
      }


      
    }
  }

  verifyFormDisponibilidad2() {



      this.formSelect.patchValue({
        provincia: "sanjuan",
      })

      this.formPrestamo.patchValue({
        email: this.form_email.value || "martinmirasolaya@gmail.ttt",
        codArea: this.form_codArea.value || "264",
        numCel: this.form_numCel.value || "5094844",
        nombre: this.form_nombre.value || "Martín",
        apellido: this.form_apellido.value || "Miras",
        dni: this.form_dni.value || "24300100",
        cuil: this.form_cuil.value || '20243001006',
        fechaNac: this.form_fechaNac.value || '1995-03-10',
        importe: this.form_importe.value || "5000",
        sexo: this.form_sexo.value || 1,
        terminos: this.form_terminos.value || true,
      })

      // this.verifyFormDisponibilidad()
      // const data = JSON.stringify({
      //   email: this.form_email.value,
      //   tel: this.form_tel.value,
      //   nombre: this.form_nombre.value,
      //   apellido: this.form_apellido.value,
      //   dni: this.form_dni.value,
      //   cuil: this.form_cuil.value,
      //   // ref: this.ref,
      // });

      // this.ds.httpPostNoToken(3,"prestamos/ld/v1/consultar/disponibilidad",data).then(res => {
      //   this.ds.loader = false;
      //   if(res && res['r'] && res['r'].c==0)
      //   {

      //     this.valid = res['valid'];
      //     this.error = res['error'];
      //     this.emailExistente = res['email'];

      //     if(this.valid==true)
      //     {
      //       this.importeElegido = data.importe;
      //       this.simulacionPlan = (res['simulacion'])?  res['simulacion'] : null ;
      //       this.simulacionConfig = null;
      //       this.simulacionHash = (res['hash'])?  res['hash'] : null
      //       this.simulacionEmail = data.email;
      //       this.simulacionCuil = data.cuil;
      //       let auxCuotas = [];
      //       for(let i in this.simulacionPlan)
      //       {
      //         auxCuotas.push({value: i, label: `${i} cuotas ($${this.simulacionPlan[i].promedioCuota.toFixed(2)} c/u.)`})
      //       }
      //       this.opcionesCuotas = auxCuotas
      //     }
      //     this.paso=2;
      //   }
      //   else if(res && res['r'] && res['r'].c==1) this.ds.generateErrorAlert(res['r'].m)
      //   else this.ds.generateErrorAlert("Ocurrió un error inesperado al consultar por el préstamo")

      // })
      
  }

  importeElegido: any = null;
  simulacionPlan: any = null;
  simulacionConfig: any = null;
  opcionesCuotas: any = [];
  cantCuotas: any = null
  simulacionHash: any = null
  simulacionEmail: any = null
  simulacionCuil: any = null
  documentacion: any = [];
  
  selectCuotas(e)
  {
    this.simulacionConfig = this.simulacionPlan[e];
  }

  verifyFormContacto() {

    if(this.formPrestamo.status != "VALID") { this.ds.generateErrorAlert('Debe completar todos los campos del formulario'); }
    else {
      this.ds.loader = true;
      const data = {
        email: this.form_email.value,
        codArea: this.form_codArea.value,
        numCel: this.form_numCel.value,
        nombre: this.form_nombre.value,
        apellido: this.form_apellido.value,
        dni: this.form_dni.value,
        cuil: this.form_cuil.value,
        sexo: this.form_sexo.value,
        // ref: this.ref,
      };
      // const data = JSON.stringify({
      //   email: this.form_email.value,
      //   tel: this.form_tel.value,
      //   nombre: this.form_nombre.value,
      //   apellido: this.form_apellido.value,
      //   dni: this.form_dni.value,
      //   cuil: this.form_cuil.value,
      //   // ref: this.ref,
      // });

      this.ds.httpPostNoToken(3,"prestamos/ld/v1/consultar/disponibilidad",data).then(res => {
        this.ds.loader = false;
        if(res && res['r'] && res['r'].c==0)
        {
          this.paso=2;
          this.valid = res['valid'];
          this.simulacionPlan = (res['simulacion'])?  res['simulacion'] : null ;



          this.error = res['error'];
          this.emailExistente = res['email'];
        }
        else if(res && res['r'] && res['r'].c==1)
        {
          this.ds.generateErrorAlert(res['r'].m)
        }
        else
        {
          this.ds.generateErrorAlert("Ocurrió un error inesperado al consultar por el préstamo")
        }

      })
      
    }
  }


  confirmarPrestamo()
  {
    let data = {
      hash: this.simulacionHash,
      email: this.simulacionEmail,
      importe: this.importeElegido,
      total: this.simulacionConfig.total,
      cuotas: this.cantCuotas,
      cuil: this.simulacionCuil,
    };

    this.ds.loader = true;
    this.ds.httpPostNoToken(3,"prestamos/ld/v1/confirmar",data).then(res => {
      this.ds.loader = false;
        if(res && res['r'] && res['r'].c==0)
        {
          this.documentacion = res['docu']
          this.paso = 3;

        }
        else if(res && res['r'] && res['r'].c==1)
        {
          this.ds.generateErrorAlert(res['r'].m)
        }
        else
        {
          this.ds.generateErrorAlert("Ocurrió un error inesperado al procesar la solicitud.")
        }
    });

  }



  registro()
  {
    const data = {
      email: this.form_email.value,
      codArea: this.form_codArea.value,
      numCel: this.form_numCel.value,
      nombres: this.form_nombre.value,
      apellidos: this.form_apellido.value,
      nroDoc: this.form_dni.value,
      // cuil: this.form_cuil.value,
      fechaNac: this.form_fechaNac.value,
      sexo: this.form_sexo.value,
      hash: this.simulacionHash,
      provincia: this.form_provincia.value,
    };

    this.ds.precargaDatosRegistro = data;
    this.router.navigateByUrl("/unirse");
  }

  validate_cuil_cuit(control: AbstractControl) {

    if (control.value) {
      if (this.ds.validadCuil(control.value)) return { 'validadCuit': true };
      else return null;
    }

    return { 'errorValidacion': true };
  }

  validate_importe(control: AbstractControl) {

    if (control.value) {
      if(control.value<this.importeMinimo) return { 'min': true };
      else if(control.value>this.importeMaximo) return { 'max': true };
      else return null;
    }
    return { 'errorValidacion': true };
  }




  consultaSoporteConCodigo() {
      
    
    let parseNombre = this.form_nombre.value;
    parseNombre = this.replaceString(parseNombre)
    let parseApellido = this.form_apellido.value;
    parseApellido = this.replaceString(parseApellido)

    let parseEmail = this.form_email.value;
    
    let link = `https://api.whatsapp.com/send/?phone=+5492645800950&text=*Nombre*:%20${parseNombre}.%0A*Apellido*:%20${parseApellido}.%0A*Email*:%20${parseEmail}%0A%0A*Código*:%20${this.error}.%0A*Consulta*:%20No%20puedo%20solicitar%20un%20préstamo%20en%20BestCard`
      
      link+=``


      
      window.open(link, "_blank");
    
  }

  replaceString(str)
  {
    str = str.replaceAll(" ","%20").replaceAll("#","%23").replaceAll("&","%26").replaceAll("?","%3f").replaceAll("<","%3c").replaceAll("=","%3d").replaceAll(">","%3e").replaceAll("+","%2b")

    // .replaceAll("ñ","%f1").replaceAll("Ñ","%d1")

    for (var i = 0; i < str.length; i++) {
      if(str.charCodeAt(i) == 10) str = str.substr(0, i) + "%0A" + str.substr(i+1, str.length);
    }    

    return str;
  }
}
