<app-navbar></app-navbar>
<div class="layout-1">
    <div class="container " style="margin-top: 80px;">
        <div class="row" *ngIf="paso==1">
            <div class="col-sm-8 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
                <h4 class="my-3 text-center">Botón de Arrepentimiento</h4>
              <p class="font-size-085 text-center">Según los términos del Artículo 34 de la Ley N° 24.240 y 1.110 del Código Civil y Comercial de la Nación, la revocación de algún producto de BestCard podrá ser rescindida mediante este medio. Para iniciar tal proceso tendrás que completar y enviar el siguiente formulario. Una vez hecho esto se revisará tu estado actual con BestCard para luego informar el código de identificación de arrepentimiento o revocación.</p>
              <p class="font-size-085 text-center mt-3 mb-1">Completá el siguiente formulario:</p>
                <form [formGroup]="formBoton" class="d-flex flex-column h-100" (ngSubmit)="submitForm()">
                    <div class="row">
                        <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="text" id="formNombre" class="form-control" formControlName="nombre" mdbInput>
                                <label for="formNombre">Nombre y Apellido *</label>
                                <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched && form_nombre.errors?.required">Escribí tu nombre</mdb-error>
                                <mdb-error *ngIf="form_nombre.invalid && form_nombre.touched && form_nombre.errors?.maxlength">Nombre demasiado largo</mdb-error>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="number" id="formDni" class="form-control" formControlName="dni" mdbInput>
                                <label for="formDni">Número de documento *</label>
                                <!-- <mdb-error *ngIf="form_dni.invalid && form_dni.touched && form_dni.errors?.required">Escribí tu apellido</mdb-error>
                                <mdb-error *ngIf="form_dni.invalid && form_dni.touched && form_dni.errors?.maxlength">Apellido demasiado largo</mdb-error> -->
                            </div>
                        </div>
                        
                        <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="text" id="formFechaNac" class="form-control" (click)="openDateTime()" formControlName="fechaNac" mdbInput autocomplete="off">
                                <label for="formFechaNac">Fecha de Nacimiento *</label>
                                <mdb-error *ngIf="form_fechaNac.invalid && form_fechaNac.touched && form_fechaNac.errors?.required">Fecha no válida</mdb-error>
                                <mdb-error *ngIf="form_fechaNac.invalid && form_fechaNac.touched && form_fechaNac.errors?.minlength">Fecha no válida</mdb-error>
                                <mdb-error *ngIf="form_fechaNac.invalid && form_fechaNac.touched && form_fechaNac.errors?.maxlength">Fecha no válida</mdb-error>
                                <mdb-success *ngIf="form_fechaNac.valid && form_fechaNac.touched">Fecha Válida</mdb-success>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="number" id="formCelular" class="form-control" formControlName="celular" mdbInput>
                                <label for="formCelular">Celular *</label>
                                <mdb-error *ngIf="form_celular.invalid && form_celular.touched">Celular no válido</mdb-error>
                                <mdb-success *ngIf="form_celular.valid && form_celular.touched">Celular Válido</mdb-success>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <mdb-select [outline]="true" [options]="opcionesProductos" formControlName="producto" label="Producto que desea revocar o desestimar *"></mdb-select>
                            </div>
                        </div>
                        <div class="col-12 my-1">
                            <p class="font-size-085 text-center">¿Cuales son los motivos para desestimar el producto? *:</p>
                        </div>
                        
                        <div class="col-12 my-1">
                            <div class="form-check">
                                <input
                                  mdbCheckbox
                                  class="form-check-input"
                                  type="checkbox"
                                  id="formChkbox_1"
                                  value="sinUso"
                                  (change)="onCheckboxChange($event)"
                                />
                                <label class="form-check-label ml-2" for="formChkbox_1">No lo uso</label>
                            </div>  
                            <div class="form-check">
                                <input
                                  mdbCheckbox
                                  class="form-check-input"
                                  type="checkbox"
                                  id="formChkbox_2"
                                  value="costos"
                                  (change)="onCheckboxChange($event)"
                                />
                                <label class="form-check-label ml-2" for="formChkbox_2">Los costos son elevados</label>
                            </div>  
                            <div class="form-check">
                                <input
                                  mdbCheckbox
                                  class="form-check-input"
                                  type="checkbox"
                                  id="formChkbox_3"
                                  value="fallecimiento"
                                  (change)="onCheckboxChange($event)"
                                />
                                <label class="form-check-label ml-2" for="formChkbox_3">Fallecimiento del titular</label>
                            </div>  
                            <div class="form-check">
                                <input
                                  mdbCheckbox
                                  class="form-check-input"
                                  type="checkbox"
                                  id="formChkbox_4"
                                  value="disconformidad"
                                  (change)="onCheckboxChange($event)"
                                />
                                <label class="form-check-label ml-2" for="formChkbox_4">Disconformidad con el servicio y/o prodcuto</label>
                            </div>  
                            <div class="form-check">
                                <input
                                  mdbCheckbox
                                  class="form-check-input"
                                  type="checkbox"
                                  id="formChkbox_5"
                                  value="nuncaSolicito"
                                  (change)="onCheckboxChange($event)"
                                />
                                <label class="form-check-label ml-2" for="formChkbox_5">Nunca lo solicité</label>
                            </div>  
                        </div>
                        <div class="col-12 my-1">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="text" id="formOtroMotivo" class="form-control" formControlName="otroMotivo" mdbInput>
                                <label for="formOtroMotivo">Otro motivo</label>
                            </div>
                        </div>
                        <div class="col-12 mb-1 mt-3">
                            <p class="font-size-085 text-center">En una escala del 1 al 5 ¿Cuál es el grado de satisfacción con BestCard? *:</p>
                        </div>
              
                        <!-- <div class="col-12 my-1 text-primary text-center d-flex justify-content-center">
                             <h2 class="mx-2">1</h2>
                            <h2 class="mx-2"><i class="fa-star" [ngClass]="{'fas':form_rating.value>=1,'far':form_rating.value<1,'text-secondary-2':prerating>=1}" (mouseover)="prerating=1" (mouseout)="prerating=0"(click)="rating(1)"></i></h2>
                            <h2 class="mx-2"><i class="fa-star" [ngClass]="{'fas':form_rating.value>=2,'far':form_rating.value<2,'text-secondary-2':prerating>=2}" (mouseover)="prerating=2" (mouseout)="prerating=0"(click)="rating(2)"></i></h2>
                            <h2 class="mx-2"><i class="fa-star" [ngClass]="{'fas':form_rating.value>=3,'far':form_rating.value<3,'text-secondary-2':prerating>=3}" (mouseover)="prerating=3" (mouseout)="prerating=0" (click)="rating(3)"></i></h2>
                            <h2 class="mx-2"><i class="fa-star" [ngClass]="{'fas':form_rating.value>=4,'far':form_rating.value<4,'text-secondary-2':prerating>=4}" (mouseover)="prerating=4" (mouseout)="prerating=0"(click)="rating(4)"></i></h2>
                            <h2 class="mx-2"><i class="fa-star" [ngClass]="{'fas':form_rating.value>=5,'far':form_rating.value<5,'text-secondary-2':prerating>=5}" (mouseover)="prerating=5" (mouseout)="prerating=0" (click)="rating(5)"></i></h2>
                            <h2 class="mx-2">5</h2>
                        </div> -->
                        <div class="col-12 my-1 text-primary text-center justify-content-center">
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2"><h4 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=1,'text-primary':form_rating.value==1}" (click)="rating(1)">1</h4></div>
                                <div class="col-2"><h4 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=2,'text-primary':form_rating.value==2}" (click)="rating(2)">2</h4></div>
                                <div class="col-2"><h4 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=3,'text-primary':form_rating.value==3}" (click)="rating(3)">3</h4></div>
                                <div class="col-2"><h4 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=4,'text-primary':form_rating.value==4}" (click)="rating(4)">4</h4></div>
                                <div class="col-2"><h4 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=5,'text-primary':form_rating.value==5}" (click)="rating(5)">5</h4></div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2"><h2 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=1,'text-primary':form_rating.value==1}" (click)="rating(1)"><i class="fas fa-frown"></i></h2></div>
                                <div class="col-2"><h2 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=2,'text-primary':form_rating.value==2}" (click)="rating(2)"><i class="fas fa-meh"></i></h2></div>
                                <div class="col-2"><h2 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=3,'text-primary':form_rating.value==3}" (click)="rating(3)"><i class="fas fa-smile"></i></h2></div>
                                <div class="col-2"><h2 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=4,'text-primary':form_rating.value==4}" (click)="rating(4)"><i class="fas fa-grin"></i></h2></div>
                                <div class="col-2"><h2 class="font-weight-bold" style="cursor:pointer" [ngClass]="{'text-gray':form_rating.value!=5,'text-primary':form_rating.value==5}" (click)="rating(5)"><i class="fas fa-laugh-beam"></i></h2></div>
                                <div class="col-1"></div>
                            </div>
                            
                        </div>
                        <!-- <div class="col-12 my-3">
                            <div class="md-form md-outline whiteness m-0">
                                <input type="text" id="formCodInv" class="form-control" mdbInput>
                                <label for="formCodInv">Código de Invitación</label>
                            </div>
                        </div> -->
                        <div class="col-12 my-1 text-center d-flex justify-content-center">
                            <p class="font-size-085 text-center mb-0">
                                Al hacer clic en el botón "Enviar", esta Solicitud de Arrepentimiento llegará a nosotros y será analizada.
                            </p>
                        </div>
                      </div>
                    <div class="d-flex justify-content-between mb-2 mt-3">
                        <button mdbBtn type="submit" color="secondary" block="true" class="d-block waves-light mb-0" mdbWavesEffect>
                            Enviar Solicitud
                        </button>
                    </div>
                </form>
                <mdb-date-picker name="mydate" class="invisible-item" #datePicker [options]="myDatePickerOptions" [placeholder]="'Selected date'" [(ngModel)]="fechaNac" (ngModelChange)="detectDateChange($event)"
      required></mdb-date-picker>
            </div>
        </div>
        <div class="row" *ngIf="paso==2">
            <div class="col-sm-8 mx-auto my-3 d-flex flex-column z-depth-1 rounded-lg white py-2">
                <!-- <h4 class="my-3 text-center">Botón de Arrepentimiento</h4> -->
                <!-- <p class="font-size-085 text-center">Gracias por responder! </p>-->
                  <h4 class="my-3 text-center">¡Gracias por responder!</h4>
            
            <p class="font-size-085 text-center">
                Analizaremos tu solicitud en breve.
            </p>
            <p class="font-size-085 text-center">
                Lamentamos tener que decirte adiós, ¡esperamos haber podido hacer posibles tus sueños!
            </p>

            </div>
        </div>
    </div>
</div>
<app-footer-general></app-footer-general>
